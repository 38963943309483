import React from "react";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Fade } from "@mui/material";

const useStyles = makeStyles({
  loadingSpinner: {
    position: "fixed",
    zIndex: 1300,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const LoadingOverlay = (props: { loading: boolean; value?: number; backgroundColor?: string }) => {
  const classes = useStyles(props);
  return (
    <Fade in={props.loading} timeout={300}>
      <div
        className={classes.loadingSpinner}
        style={{ backgroundColor: props.backgroundColor ? props.backgroundColor : "rgba(0,0,0,0.5)" }}
      >
        {props.value ? <CircularProgress variant="determinate" value={props.value} /> : <CircularProgress />}
      </div>
    </Fade>
  );
};

export default LoadingOverlay;
