import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIos";
import { AddressGroupedListItem } from "../../interfaces/models";

export type ItemListProps<T> = {
  getItem: (id: string) => void;
  getList: (filter: string, skip: number, take: number) => void;
  list: AddressGroupedListItem<T>[];
  title: string;
  itemTitle?: string;
};

const GroupedItemsList = (props: ItemListProps<any>) => {
  const getReport = (id: string) => {
    props.getItem(id);
  };

  const [selectedGroupId, setSelectedGroupId] = useState("");

  useEffect(() => {
    props.getList("", 0, 0);
    return () => {
      //
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    {selectedGroupId && (
      <IconButton onClick={() => setSelectedGroupId("")} size="large">
        <ArrowBackIcon fontSize="small" />
      </IconButton>
    )}
    <List dense>
      <ListItem>
        <Typography variant="h5">{selectedGroupId ? props.list &&
        props.list
          .find((x) => x.id === selectedGroupId)?.name : props.title}</Typography>
      </ListItem>
      {props.list &&
        !selectedGroupId &&
        props.list.map((item) => (
          <Fragment key={item.id}>
            <ListItem button onClick={() => setSelectedGroupId(item.id)} selected={item.id === selectedGroupId}>
              <ListItemText
                primary={
                  <>
                    <div>{item.name ? item.name : "Tom adresse"}</div>
                    <div>{item.postcode + " " + item.city}</div>
                  </>
                }
                secondary={"Updateret " + new Date(item.modified || Date.now()).toLocaleString()}
              />
              <ListItemSecondaryAction>
                <ArrowForwardIcon fontSize="small" />
              </ListItemSecondaryAction>
            </ListItem>
          </Fragment>
        ))}
      {props.list &&
        props.list
          .find((x) => x.id === selectedGroupId)
          ?.items?.map((item) => (
            <Fragment key={item.id}>
              <ListItem
                button
                onClick={() => {
                  getReport(item.id);
                }}
              >
                <ListItemText
                  primary={
                    <>
                      <div>
                        {item.reportDate
                          ? new Date(item.reportDate).toLocaleDateString() + " (" + item.caseNumber + ")"
                          : item.count + " opgaver"}
                      </div>
                    </>
                  }
                  secondary={"Oprettet " + new Date(item.modified || Date.now()).toLocaleString()}
                />
              </ListItem>
            </Fragment>
          ))}
    </List>
  </>;
};

export default GroupedItemsList;
