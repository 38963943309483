import { Button, ClickAwayListener, Grid, Grow, Link, MenuItem, MenuList, Paper, Popper, styled } from "@mui/material";
import React from "react";
import MenuBlocks from "../pages/components/MenuBlocks";

const MenuButton = styled(Button)(({ theme }) => ({
  height: 45,
  borderRadius: 0,
  padding: "0 20px",
  boxSizing: "border-box",
  backgroundColor: theme.palette.secondary.main,
  flexWrap: "nowrap",
  fontSize: 10,
  color: "#fff",
  fontFamily: "'Montserrat', sans-serif",
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.9,
  },
  "@media (min-width: 600px)": {
    width: 168,
    fontSize: 12,
  },
}));

const GreyButton = styled(Button)({
  height: 45,
  borderRadius: 0,
  padding: "0 20px",
  boxSizing: "border-box",
  background: "#f7f7f6",
  color: "rgba(0, 0, 0, 0.87)",
  fontSize: 10,
  fontWeight: 600,
  fontFamily: "'Montserrat', sans-serif",
  "&:hover": {
    backgroundColor: "#f7f7f6",
  },
  "@media (min-width: 600px)": {
    width: 168,
    fontSize: 12,
  },
});

const PortalLoginButtons = () => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid
        item
        container
        justifyContent="flex-end"
        spacing={3}
        style={{ margin: 0, width: "100%", flexWrap: "nowrap" }}
      >
        <MenuButton variant="contained" onClick={(event) => setAnchorEl(event.currentTarget)}>
          Partner login
        </MenuButton>
        <GreyButton href={process.env.REACT_APP_MYHOUSE_URL as string}>
          Boligejer login
        </GreyButton>
      </Grid>
      <Popper open={!!anchorEl} anchorEl={anchorEl} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList autoFocusItem={!!anchorEl} id="menu-list-grow">
                  {MenuBlocks["login"].map((route) => (
                    <MenuItem component="a" key={route.id} href={route.path}>
                      {route.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default PortalLoginButtons;
