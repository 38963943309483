import React from "react";
import { View, Image, Text } from "@react-pdf/renderer";
import styles from "../../Vedligeholdelserapport/pdfDocumentStyles";
import PdfCustomerData from "./PdfCustomerData";
import { format } from "date-fns";
import daLocale from "date-fns/locale/da";
import { FeatureMetadata } from "../../../../../interfaces/models";

const PdfDocHeader = (props: FeatureMetadata & { reportDate: Date; creationDate: Date }) => {
  
  const creationDate = format(new Date(props.creationDate || Date.now()), "dd. MMM yyyy", { locale: daLocale });
  const reportDate = format(new Date(props.reportDate || Date.now()), "dd. MMM yyyy", { locale: daLocale });

  return (
    <View style={{ ...styles.column, ...styles.marginBottom }}>
      <View style={{ ...styles.row }}>
        <View style={styles.half}>
          <PdfCustomerData {...props} creationDate={props.creationDate} />
          <View style={{ ...styles.row, ...styles.rowSpace }}>
            <View style={{ ...styles.half }}>
              <Text style={{ ...styles.pageText }}>Inspektionsdato:</Text>
            </View>
            <View style={{ ...styles.half }}>
              <Text style={{ ...styles.pageText }}>{creationDate}</Text>
            </View>
          </View>
        </View>
        <View style={styles.half}>
          <View style={{ ...styles.headerTop, ...styles.column }}>
            <Image style={styles.imageHeader} src={require("../img/Nyt-byggesagkyndig-RGB-logo-large.png")} />
            <Text style={styles.pageText}>Dato: {reportDate}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PdfDocHeader;
