import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

type ParameterEditDialogProps = {
  open: boolean;
  onClose: (value?: string) => void;
  title: string;
};

const ParameterEditDialog = (props: ParameterEditDialogProps) => {
  const { title, onClose, open } = props;
  const [name, setName] = useState(title);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog open={open} fullScreen={isXs} scroll={"paper"} onClose={() => onClose()}>
      <DialogTitle id="form-dialog-title">Rediger punktet titel</DialogTitle>
      <DialogContent dividers={true}>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Bygningens navn"
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => onClose(name)} color="primary">
          Gem
        </Button>
        <Button variant="outlined" onClick={() => onClose()} color="primary">
          Annuller
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ParameterEditDialog;
