import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import styles from "../styles";
import { FeatureMetadata } from "../../../../../interfaces/models";

function PdfHouseData(props: FeatureMetadata) {
  return (
    <>
      <Image style={styles.image} src={require("../../../../../assets/img/myhouseLogo.png")} />

      <Text style={{ ...styles.mainTitle, ...styles.fontFamily }}>Serviceeftersyn</Text>
      <Text style={{ ...styles.secondaryTitle, ...styles.fontFamily }}>Status på din ejendoms tilstand</Text>
      {props.picture && <Image style={styles.imageWide} src={props.picture} />}
      <View style={{ ...styles.row, ...styles.rowSpace, ...styles.marginTop }}>
        <Text style={{ ...styles.boldText, ...styles.fontFamily }}>Ejere</Text>
        <Text style={{ ...styles.boldText, ...styles.fontFamily }}>{`${props.owner} ${props.ownerLastName}`}</Text>
      </View>
      <View style={{ ...styles.row, ...styles.rowSpace }}>
        <Text style={{ ...styles.boldText, ...styles.fontFamily }}>Ejerens tlf. nr.</Text>
        <Text style={{ ...styles.boldText, ...styles.fontFamily }}>{props.ownerPhone}</Text>
      </View>
      <View style={{ ...styles.row, ...styles.rowSpace }}>
        <Text style={{ ...styles.boldText, ...styles.fontFamily }}>Adresse</Text>
        <Text style={{ ...styles.boldText, ...styles.fontFamily }}>{props.address}</Text>
      </View>
      <View style={{ ...styles.row, ...styles.rowSpace }}>
        <Text style={{ ...styles.boldText, ...styles.fontFamily }}>Postnr.</Text>
        <Text style={{ ...styles.boldText, ...styles.fontFamily }}>{props.postcode}</Text>
      </View>
      <View style={{ ...styles.row, ...styles.rowSpace }}>
        <Text style={{ ...styles.boldText, ...styles.fontFamily }}>By</Text>
        <Text style={{ ...styles.boldText, ...styles.fontFamily }}>{props.city}</Text>
      </View>
      <View style={{ ...styles.row, ...styles.rowSpace }}>
        <Text style={{ ...styles.boldText, ...styles.fontFamily }}>Dato</Text>
        <Text style={{ ...styles.boldText, ...styles.fontFamily }}>
          {new Date(props.reportDate || Date.now()).toLocaleDateString()}
        </Text>
      </View>
      <View style={{ ...styles.borderTop, ...styles.column }}>
        <View style={{ ...styles.row, ...styles.rowSpace }}>
          <Text style={{ ...styles.boldText, ...styles.fontFamily }}>Kommunenr./Ejendomsnr.</Text>
          <Text style={{ ...styles.boldText, ...styles.fontFamily }}>{props.municipality}</Text>
        </View>
        <View style={{ ...styles.row, ...styles.rowSpace }}>
          <Text style={{ ...styles.boldText, ...styles.fontFamily }}>Matrikel/Ejerlav</Text>
          <Text style={{ ...styles.boldText, ...styles.fontFamily }}>{props.cadastral}</Text>
        </View>
        <View style={{ ...styles.border, ...styles.row, ...styles.rowSpace }}>
          <Text style={{ ...styles.boldText, ...styles.fontFamily }}>Internt sagsnummer</Text>
          <Text style={{ ...styles.boldText, ...styles.fontFamily }}>{props.caseNumber}</Text>
        </View>
      </View>
    </>
  );
}

export default PdfHouseData;
