import { Divider, Grid, ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { InspectorReportParameter, TemplateItem, TimeToFinish } from "../../interfaces/models";
import { useReport } from "../reportActions";
import AddNewParamPanel from "./AddNewParamPanel";
import ReportItem from "./ReportItem";
import { NIL, v4 as uuidv4 } from "uuid";
import { ChangeContext } from "../CreateReportPage";
import HiddenItemsMenu from "./HiddenItemsMenu";
import ParameterEditDialog from "../dialogs/ParameterEditDialog";
import { DeleteForever, Edit } from "@mui/icons-material";
import GeneralDialog from "../../shared/GeneralDialog";

const ReportItemGroup = (props: TemplateItem & { parameter: InspectorReportParameter, isReadOnly?:boolean}) => {
  const [showAddPanel, setShowAddPanel] = useState(false);
  const [showEditTitleDialog, setShowEditTitleDialog] = useState(false);
  const [showRemoveGroupDialog, setShowRemoveGroupDialog] = useState(false);

  const [report, actions] = useReport();

  const changeCtx = useContext(ChangeContext);
  const name = props.parameter.name != props.name ? props.parameter.name : props.name;

  const editTitle = (value?: string) => {
    setShowEditTitleDialog(false);
    if (value) {
      actions.changeValue(props.parameter.id, "name", value);
    }
  };

  const addNewParameter = (newName: string) => {
    changeCtx.current++;

    const newParam = {
      hidden: false,
      id: uuidv4(),
      //TODO: find out how is better: add it to the template or leave empty because it is it is under the group
      itemId: NIL,
      label: props.label,
      objectId: props.parameter.objectId,
      name: newName,
      timeEstimate: TimeToFinish.None,
      priceEstimate: 0,
      value: "",
      description: "",
      craftsmen: [],
      note: "",
      pictures: [],
      descriptions: [],
      groupItemId: props.id,
    };

    actions.addNewParameter(newParam);

    setShowAddPanel(false);
  };

  const removeItem = (confirm?: boolean) => {
    if (confirm) {
      actions.removeParameter(props.parameter.id);
    }
    setShowRemoveGroupDialog(false);
  };
  return (
    <>
      <Grid
        item
        container
        alignItems="center"
        sx={{
          border: "2px solid grey",
          borderRadius: "4px",
          marginBottom: "20px",
          padding: "10px",
          boxSizing: "border-box",
        }}
      >
        <Grid item container alignItems="center" justifyContent={"space-between"}>
          <Grid item>
            <Typography variant="h5">
              {props.label} {name}
            </Typography>
          </Grid>
          <Grid item>
            <HiddenItemsMenu
              items={report.parameters.filter(
                (x) => x.objectId === props.parameter.objectId && x.groupItemId === props.id && x.hidden
              )}
              disabled={report.approved || props.isReadOnly}              
            >
              <MenuItem onClick={() => setShowEditTitleDialog(true)}>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText>Rediger titel</ListItemText>
              </MenuItem>
              <MenuItem divider />
              <MenuItem onClick={() => setShowRemoveGroupDialog(true)}>
                <ListItemIcon>
                  <DeleteForever />
                </ListItemIcon>
                <ListItemText>Slet</ListItemText>
              </MenuItem>
            </HiddenItemsMenu>
          </Grid>
        </Grid>
        <Divider variant="fullWidth" sx={{ width: "100%", marginTop: 1, marginBottom: 1 }} />
        {report.parameters
          .filter((x) => x.objectId === props.parameter.objectId && x.groupItemId === props.id && !x.hidden)
          .map((x, idx) => (
            <ReportItem
              key={x.id}
              showAddItem={false}
              sectionId={props.parentId}
              {...x}
              variant={
                idx <
                report.parameters.filter((x) => x.objectId === props.parameter.objectId && x.groupItemId === props.id)
                  .length -
                  1
                  ? "divider"
                  : "default"
              }
              isReadOnly={props.isReadOnly}
            />
          ))}
        <AddNewParamPanel
          show={showAddPanel}
          addNewParameter={addNewParameter}
          cancelAdditem={() => setShowAddPanel(false)}
          onShowClick={() => setShowAddPanel(true)}
          isReadOnly={props.isReadOnly}
        />
      </Grid>
      <ParameterEditDialog open={showEditTitleDialog} onClose={editTitle} title={name} />
      <GeneralDialog
        open={showRemoveGroupDialog}
        dialogTitle={"Ønske du at slette dette punkt og alle underpunkterne?"}
        handleClose={removeItem}
      />
    </>
  );
};

export default ReportItemGroup;
