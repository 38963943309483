import React, { useState } from "react";
import { InspectorReportParameter } from "../../interfaces/models";
import { Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { useReport } from "../reportActions";
import TooltipForDisabled from "../../shared/Tooltips";

type HiddenItemsMenuProps = {
  items: InspectorReportParameter[];
  children?: React.ReactNode;
  disabled?: boolean;
};

const HiddenItemsMenu = (props: HiddenItemsMenuProps) => {
  const [_, actions] = useReport();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const showItem = (itemId: string) => {
    actions.changeValue(itemId, "hidden", false);
    setAnchorEl(null);
  };

  return (
    <>
      <Grid item>
        <TooltipForDisabled title="Godkendt rapport kan ikke ændres">
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} disabled={props.disabled}>
            <MoreVert />
          </IconButton>
        </TooltipForDisabled>
      </Grid>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
        {props.children
          ? React.Children.map(props.children, (child: React.ReactNode) => {
              return React.cloneElement(child as React.ReactElement, {
                onClick: () => {
                  setAnchorEl(null);
                  if ((child as React.ReactElement).props.onClick) {
                    (child as React.ReactElement).props.onClick();
                  }
                },
              });
            })
          : null}
        {props.children ? <MenuItem divider /> : null}
        <MenuItem disabled dense>
          <Typography variant="overline">Skjulte punkter</Typography>
        </MenuItem>
        {props.items.length ? (
          props.items.map((x) => (
            <MenuItem key={x.id} onClick={() => showItem(x.id)}>
              {x.label + " " + x.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>Ikke skjulte punkter</MenuItem>
        )}
      </Menu>
    </>
  );
};

export default HiddenItemsMenu;
