import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, styled } from "@mui/material";
import PlaceholderHouse from "./../assets/img/placeholderHouse.jpg";


const Image = styled("img", { shouldForwardProp: (prop) => prop !== "mainPicture" })<{ mainPicture?: boolean }>(
  ({ mainPicture }) => ({
    height: "100%",
    width: "100%",
    objectFit: mainPicture ? "inherit" : "cover",
  })
);

export interface PictureProps {
  id: string;
  source: string | undefined;
  width: number;
  height?: number;
  mainPicture?: boolean;
}

const Picture = (props: PictureProps) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  //const classes = useStyles({ ...props });

  useEffect(() => {
    if (error) {
      setError(false);
    }
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.source]);

  const imageLoaded = (event: any) => {
    setLoaded(true);
    setError(false);
  };

  const handleError = () => {
    setError(true);
  };

  return (
    <>
      {props.source && !error ? (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
          <Image src={props.source} alt="" onLoad={imageLoaded} onError={handleError} mainPicture={props.mainPicture} />
          {!loaded && <CircularProgress style={{ position: "absolute" }} />}
        </Grid>
      ) : (
        <Image src={PlaceholderHouse} alt="" />
      )}
    </>
  );
};

export default Picture;
