import React from "react";
import styles from "../styles";
import { View, Text, Image } from "@react-pdf/renderer";
import { CraftsmanType, InspectorReportParameter } from "../../../../../interfaces/models";
import { CraftsmanTypes, TimeEstimate } from "../../../../../constants/appConstants";

const PdfReportItem = (props: InspectorReportParameter &  { showLabel: boolean} ) => {
  return (
    <View style={styles.column}>
      <View style={styles.row}>
        <View style={{ ...styles.parameter, ...styles.row }}>
          <Text style={{ ...styles.paddingRight, ...styles.fontFamily }}>{props.showLabel && props.label}</Text>
          <Text style={{ ...styles.fontFamily, ...styles.wrapText }}>{props.name}</Text>
        </View>
        <View style={{ ...styles.value }}>
          {props.value && <Text style={{ ...styles.fontFamily }}>{props.value}</Text>}
        </View>
      </View>
      <View style={{ ...styles.row, ...styles.wrap }}>
        {props.pictures && props.pictures.map((x) => <Image key={x.id} style={styles.imageInspected} src={x.url! || x.data!} />)}
      </View>
      <View style={styles.column}>
        <View style={styles.description}>
          {!!props.value && !!props.description && (
            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.row, ...styles.title6, ...styles.fontFamily }}>Problemstillingen:</Text>
              <Text style={{ ...styles.fontFamily, ...styles.text, ...styles.borderLeft }}>
                {props.description}
              </Text>
            </View>
          )}
        </View>
      </View>
      <View style={{ ...styles.column }}>
        <View style={styles.description}>
          {!!props.value && !!props.note && (
            <View style={{ ...styles.column }}>
              <Text style={{ ...styles.row, ...styles.title6, ...styles.fontFamily }}>Note/uddybende kommentarer:</Text>
              <Text style={{ ...styles.fontFamily, ...styles.text, ...styles.borderLeft }}>{props.note}</Text>
            </View>
          )}
        </View>
      </View>

      {!!props.value && !!props.craftsmen && (
        <View style={{ ...styles.column, ...styles.lightGrey }}>
          <View style={{ ...styles.column }}>
            <View>
              <View style={{ ...styles.row, ...styles.tableTr }}>
                <View style={styles.value}>
                  <Text style={{ ...styles.title6, ...styles.fontFamily }}>Håndværkere</Text>
                </View>
                <View style={styles.value}>
                  <Text style={{ ...styles.title6, ...styles.fontFamily, ...styles.center }}>Udbedring:</Text>
                </View>
                <View style={styles.value}>
                  <Text style={{ ...styles.title6, ...styles.fontFamily, ...styles.center }}>
                    Pris overslag inkl. moms:
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{ ...styles.value }}>
              <View style={{ ...styles.column }}>
                <Text style={{ ...styles.fontFamily, ...styles.text }}>
                  {props.craftsmen
                    .map((x) => { return  x === CraftsmanType.None ? "" : CraftsmanTypes[x]})
                    .reduce((p, c) => (p ? (c ? p + ", " + c : p) : c), "")}
                </Text>
              </View>
            </View>
            <View style={{ ...styles.value, ...styles.center }}>
              {props.value && (
                <Text style={{ ...styles.fontFamily }}>
                  {TimeEstimate.find((x) => x.id === props.timeEstimate)?.value || ""}
                </Text>
              )}
            </View>
            <View style={{ ...styles.value, ...styles.center }}>
              {props.value && (
                <Text style={{ ...styles.fontFamily }}>
                  {(props.priceEstimate).toLocaleString("da")} kr.
                </Text>
              )}
            </View>
          </View>
        </View>
      )}

      <View style={styles.column}></View>
    </View>
  );
};

export default PdfReportItem;
