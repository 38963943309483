import React from "react";
import { View, Text } from "@react-pdf/renderer";
import styles from "../pdfDocumentStyles";
import {
  InspectorReportParameter,
  TemplateItemType,
  TemplateOption,
  TemplateSection,
} from "../../../../../interfaces/models";
import PdfDocReportItemGroup from "./PdfDocReportItemGroup";
import PdfDocReportItem from "./PdfDocReportItem";

const PdfDocReportSection = (
  props: TemplateSection & {
    parameters: InspectorReportParameter[];
    options: TemplateOption[];
    makeBreak: boolean;
  }
) => {
  const groupedItems: { [id: string]: InspectorReportParameter[] } = {};

  let needRender = false;
  const groups = props.parameters.filter((g) =>
    props.items.some((i) => i.type === TemplateItemType.Group && g.itemId === i.id)
  );
  groups.forEach((g) => {
    const groupItems = props.parameters.filter((p) => !!p.groupItemId && p.groupItemId === g.itemId);

    groupItems.forEach((gi) => {
      const item = props.items.find((i) => i.id === gi.itemId);
      const options = item?.options.length ? item.options : props.options;
      //Filters out all the IB. Corresponding to https://dev.azure.com/cdmua/Klimaplan-myHouse/_workitems/edit/695 needs to be removed
      const hasValue = options.some((o) => gi.value === o.value);
      if (hasValue && gi.groupItemId) {
        if (groupedItems[gi.groupItemId]) {
          groupedItems[gi.groupItemId].push(gi);
        } else {
          groupedItems[gi.groupItemId] = [gi];
        }
        needRender = true;
      }
    });
  });

  const params = props.parameters.filter((p) =>
    props.items.filter((i) => i.type === TemplateItemType.Item && p.itemId === i.id)
  );
  params.forEach((p) => {
    const item = props.items.find((i) => i.id === p.itemId);
    const hasValue = (item?.options.length ? item.options : props.options)
      //.filter((o) => o.worth)
      .some((o) => p.value === o.value);
    if (hasValue) needRender = true;
  });

  return needRender ? (
    <>
      <View style={{ ...styles.row }} break={props.makeBreak}>
        <Text style={{ ...styles.title }}>{props.label} </Text>
        <Text style={styles.title}>{props.name}</Text>
      </View>
      {props.parameters
        .filter((x) => !x.groupItemId && props.items?.some((i) => i.id === x.itemId))
        .sort((a, b) => (parseInt(a.label.split(".")[1]) > parseInt(b.label.split(".")[1]) ? 1 : -1))
        .map((x, index) => {
          const item = props.items.find((i) => i.id === x.itemId);

          if (item?.type === TemplateItemType.Group) {
            const nested = groupedItems[x.itemId] || [];
            return nested.length ? (
              <PdfDocReportItemGroup needBreak={index > 0} key={x.id} {...x} parameters={nested} />
            ) : null;
          }

          const hasValue = (item?.options.length ? item.options : props.options)
            // .filter((o) => o.worth)
            .some((o) => x.value === o.value);

          return hasValue ? <PdfDocReportItem break={index > 0} key={x?.id} {...x} /> : null;
        })}
    </>
  ) : null;
};

export default PdfDocReportSection;
