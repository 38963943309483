import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import MainContainer from "../shared/MainContainer";
import { Card, CardContent, Grid, Link, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextFieldConnector } from "../shared/FormConnectors";
import { useUserActions } from "../user/userActions";
import LoadingOverlay from "../shared/LoadingOverlay";
import { LoadingButton } from "@mui/lab";

type PasswordFormData = {
  token: string;
  newPassword: string;
  confirmedPassword: string;
  email: string;
};

enum Step {
  Validate = 1,
  NotValid = 2,
  UserInteraction = 3,
  Success = 4,
}

const ResetPasswordPage = () => {
  const { token } = useParams<{ token: string }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email") || "";

  const [step, setStep] = useState<Step>(Step.Validate);
  const [loading, setLoading] = useState(false);
  const actions = useUserActions();

  const formSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("Kodeord er påkrævet")
      .min(8, "Kodeordet skal være mindst 8 tegn langt")
      .matches(/[A-Z]/, "Kodeordet skal indeholde mindst ét stort bogstav")
      .matches(/[a-z]/, "Kodeordet skal indeholde mindst ét lille bogstav")
      .matches(/\d/, "Kodeordet skal indeholde mindst ét tal")
      .matches(/[\W_]/, "Kodeordet skal indeholde mindst ét ikke-alfanumerisk tegn"),

    confirmedPassword: Yup.string()
      .required("Kodeordene stemmer ikke overens")
      .oneOf([Yup.ref("newPassword")], "Kodeordene stemmer ikke overens"),

    token: Yup.string().required(),
    email: Yup.string().required().email("Email er påkrævet"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<PasswordFormData>({
    defaultValues: {
      email: "",
      newPassword: "",
      confirmedPassword: "",
    },
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    const validateToken = async () => {
      try {
        if (token && email && (await actions.validateToken(email, token))) {
          setStep(Step.UserInteraction);
          setValue("token", token);
          setValue("email", email);
        }
      } catch (error) {
        setStep(Step.NotValid);
      }
    };

    validateToken();
    return () => {
      setStep(Step.Validate);
    };
  }, [token, email]);

  useEffect(() => {
    return () => {
      reset({ email: "", newPassword: "", confirmedPassword: "", token: "" });
    };
  }, []);

  const sendRequest = async (data: PasswordFormData) => {
    setLoading(true);
    try {
      await actions.resetPassword(data.token, data.newPassword, data.email);
      setStep(Step.Success);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case Step.Validate:
        return <LoadingOverlay loading={true} />;
      case Step.NotValid:
        return (
          <Card elevation={5} sx={{ width: "100%" }}>
            <CardContent>
              <Typography paragraph variant="h6">
                Ugyldig eller udløbet token
              </Typography>
              <Grid item style={{ alignSelf: "center" }}>
                <Link href="/" color="primary">
                  Tilbage
                </Link>
              </Grid>
            </CardContent>
          </Card>
        );
      case Step.UserInteraction:
        return (
          <Card elevation={5} sx={{ width: "100%" }}>
            <CardContent>
              <form onSubmit={handleSubmit(sendRequest)}>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldConnector register={register("newPassword", { required: true })}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        autoComplete="new-password"
                        margin="dense"
                        id="newPassword"
                        label="Nyt kodeord"
                        type="password"
                        error={!!errors.newPassword}
                        helperText={errors.newPassword?.message}
                      />
                    </TextFieldConnector>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldConnector register={register("confirmedPassword", { required: true })}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        autoComplete="new-password"
                        margin="dense"
                        id="confirmedPassword"
                        label="Bekræft kodeord"
                        type="password"
                        error={!!errors.confirmedPassword}
                        helperText={errors.confirmedPassword?.message}
                      />
                    </TextFieldConnector>
                  </Grid>
                  <Grid item sx={{ alignSelf: "center" }}>
                    <LoadingButton variant="contained" color="primary" type="submit" loading={loading}>
                      Nulstil kodeord
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        );
      case Step.Success:
        return (
          <Card elevation={5} sx={{ width: "100%" }}>
            <CardContent>
              <Typography paragraph variant="h6">
                Kodeordet er nulstillet
              </Typography>
              <Grid item style={{ alignSelf: "center" }}>
                <Link href="/" color="primary">
                  Tilbage
                </Link>
              </Grid>
            </CardContent>
          </Card>
        );
    }
  };

  return (
    <MainContainer title="Partner login">
      <Grid item container alignItems="flex-start" justifyContent="center" sx={{ marginTop: "10%" }}>
        <Grid item container xs={11} sm={8} md={6} lg={5} direction="column" alignContent="center" alignItems="center">
          <Grid item>
            <Typography paragraph variant="h6">
              Nullstil kodeord
            </Typography>
          </Grid>
          <Grid item container>
            {renderStep()}
          </Grid>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default ResetPasswordPage;
