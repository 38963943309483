import React, { useState } from "react";
import { TextField, Typography, Grid, Card, CardContent, Button, Link } from "@mui/material";
import { useUserActions } from "../user/userActions";
import MainContainer from "../shared/MainContainer";
import { useUser } from "../common/hooks";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";

type ForgotPasswordFormData = {
  email: string;
};

const ForgotPasswordPage = () => {
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const methods = useForm<ForgotPasswordFormData>({
    defaultValues: {
      email: undefined,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const user = useUser();
  const actions = useUserActions();

  const sendRequest = async (data: ForgotPasswordFormData) => {
    setLoading(true);
    try {
      await actions.sendForgotPasswordRequest(data.email);
      setSent(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainContainer title="Partner login">
      <Grid item container alignItems="flex-start" justifyContent="center" sx={{ marginTop: "10%" }}>
        <Grid item container xs={11} sm={8} md={6} lg={5} direction="column" alignContent="center" alignItems="center">
          <Grid item>
            <Typography paragraph variant="h6">
              Nullstil kodeord
            </Typography>
          </Grid>
          <Grid item container>
            <Card elevation={5} sx={{ width: "100%" }}>
              <CardContent>
                {sent ? (
                  <>
                    <Grid item xs={12}>
                      <Typography paragraph variant="body1">
                        Der er sendt en email til dig med instruktioner til at nulstille dit kodeord.
                      </Typography>
                    </Grid>
                    <Grid item style={{ alignSelf: "center" }}>
                      <Link href="/"  color="primary">
                        Tilbage
                      </Link>
                    </Grid>
                  </>
                ) : (
                  <form onSubmit={handleSubmit(sendRequest)}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item xs={12}>
                        <Typography paragraph variant="body1">
                          Indtast din email for at nulstille kodeord.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name="email"
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              variant="outlined"
                              error={!!errors.email}
                              id="email-input"
                              label="Indtast din email her"
                              type="email"
                              margin="dense"
                              autoComplete="email"
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item style={{ alignSelf: "center" }}>
                        <LoadingButton variant="contained" color="primary" type="submit" loading={loading}>
                          Nulstil kodeord
                        </LoadingButton>
                      </Grid>
                      {user.error && (
                        <Grid item>
                          <Typography variant="body1" color="error">
                            Kunne ikke nullstille kodeord. Prøv igen senere.
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </form>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default ForgotPasswordPage;
