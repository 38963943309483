import { StyleSheet, Font} from "@react-pdf/renderer";

Font.register({ family: 'Roboto', fonts: [
  { src: require("../../../../assets/fonts/Roboto-Regular.ttf")} , // font-style: normal, font-weight: normal
  { src: require("../../../../assets/fonts/Roboto-Bold.ttf"), fontStyle: 'bold'},
  { src: require("../../../../assets/fonts/Roboto-Medium.ttf"), fontWeight: 500},
 ]});

const styles = StyleSheet.create({
  page: {
    paddingBottom: 100
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  rowSpace: {
    justifyContent: "space-between"
  },
  column: {
    display: "flex",
    flexDirection: "column"
  },
  fullWidth: {
    width: "100%"
  },
  narrowPage: {
    justifyContent: "center"
  },
  narrowCenter: {
    paddingTop: 30,
    width: 250,
    alignItems: "baseline"
  },
  mainTitle: {
    fontSize: 30,
    textAlign: "center",
    fontWeight: "bold",
    width: "100%",
    paddingBottom: 10
  },

  secondaryTitle: {
    fontSize: 14,
    textAlign: "center",
    fontWeight: "bold",
    width: "100%",
    paddingTop: 5,
    paddingBottom: 5,
    marginBottom: 15
  },
  boldText: {
    fontWeight: "bold",
    fontSize: 10,
    lineHeight: 2,
    width: "50%"
  },
  normalText: {
    fontSize: 10,
    lineHeight: 1.2,
    paddingTop: 8,
    paddingBottom: 2,
    borderBottom: "1",
    width: "100%",
    alignContent: "center",
    justifyContent: "space-between"
  },
  block: {
    marginBottom: 10
  },
  borderTop: {
    width: "100%",
    borderTop: "1",
    paddingTop: 5
  },

  marginTop: {
    marginTop: 20
  },
  border: {
    borderBottom: "1",
    width: "100%"
  },
  left: {
    maxWidth: "80%"
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  wrapper: {
    margin: 20,
    marginBottom: 0,
    marginTop: 0,
    flexGrow: 1
  },
  title3: {
    fontSize: 18,
    fontWeight: "bold",
    borderBottom: "1",
    paddingBottom: 5,
    width: "50%"
  },

  smallText: {
    fontSize: 10,
    lineHeight: 2
  },

  table: {
    borderBottom: "1",
    padding: 10,
    paddingLeft: 0
  },
  tableTr: {
    width: "100%"
  },
  name: {
    width: "65%"
  },
  caracter: {
    width: "35%"
  },
  title4: {
    fontSize: 16
  },
  title5: {
    fontSize: 13,
    marginTop: 10
  },
  title6: {
    fontSize: 11
  },
  lightGrey: {
  backgroundColor: "#f6f6f6",
  padding: "5px 5px 0 5px",
  marginBottom: 5
  },
  value: {
    width: "35%"
  },
  parameter: {
    width: "65%"
  },
  wrap: {
    flexWrap: "wrap"
  },
  imageInspected: {
    width: "auto",
    height: 250,

    padding: 10,
  },
  wrapText: {
    width: "85%"
  },
  description: {
    width: "90%"
  },
  paddingRight: {
    paddingRight: 10
  },
  section: {
    paddingTop: 10,
    backgroundColor: "#444",
    color: "white",
    paddingLeft: 15,
  },
  footerWrapper: {
    position: "absolute",
    bottom: 5,
    left: 0,
    right: 0
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 2,
    fontSize: 8,
    margin: 10,
    borderTopWidth: 2,
    borderBottomWidth: 1
  },
  text: {
    marginRight: 5,
    lineHeight: 2
  },
  oneThird: {
    width: "30%",
  },
  center: {
    textAlign: "center",
  },
  right:{
    textAlign: "right",
  },
  image: {
    width: "150",
    alignSelf: "center",
  },
  borderLeft: {
    borderLeft: 1,
    borderLeftColor: "#777",
    borderLeftStyle: "solid",
    paddingLeft: 10,
    lineHeight: 1.2,
    marginBottom: 10,
  },
  imageWide: {
    maxHeight: "200px",
    alignSelf: "center",
  },
  headerTop: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "flex-start",
    alignItems: "center",
    paddingBottom: 3,
    margin: 20,
    marginBottom: 20,
    borderBottomWidth: 1
  },

  titleTop: {
    paddingTop: 5,
    fontSize: 16,
    fontWeight: "bold"
  },

  imageHeader: {
    height: 40
  },
  header: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "flex-start",
    paddingTop: 5,
    paddingBottom: 3,
    fontSize: 10,
    margin: 20,
    marginTop: 0,
    borderTopWidth: 2,
    borderBottomWidth: 1
  },
  title: {
    fontSize: 26,
    fontWeight: "bold"
  },
  fontFamily: {
     fontFamily: 'Roboto', 
  },
});

export default styles;
