import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { AppError } from "../interfaces/frontend";

type ErrorDialogProps = {
  open: boolean;
  handleClose: () => void;
  error?: AppError;
};

export default function ErrorDialog(props: ErrorDialogProps) {
  return (
    <Dialog open={props.open} onClose={() => props.handleClose()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {props.error?.title ? props.error.title : "Fejl"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.error?.message ?? props.error?.statusText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
