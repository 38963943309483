import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { v4 as uuidv4 } from "uuid";
import { Template, User } from "../../../constants/actionTypes";
import {
  TemplateItem,
  TemplateOption,
  TemplateSection,
} from "../../../interfaces/models";
import { AppState } from "../../../store/store";

export const useTemplate = () =>
  useSelector((state: AppState) => state.template);

export interface TemplateState {
  id: string;
  name: string;
  sections: TemplateSection[];
  items: TemplateItem[];
  options: TemplateOption[];
  default: boolean;
  loading: boolean;
  todoList?: boolean;
  error: any;
  created: Date;
  modified: Date;
}

const initialState: TemplateState = {
  id: uuidv4(),
  name: "New template",
  sections: [],
  items: [],
  options: [],
  default: false,
  loading: false,
  error: undefined,
  created: new Date(),
  modified: new Date(),
};

const templateReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case Template.GET_TEMPLATE:
      return { ...state, loading: true };
    case Template.TEMPLATE_REQUEST_SUCCEEDED:
      return { ...state, ...action.payload, loading: false };
    case Template.TEMPLATE_REQUEST_FAILED:
      return { ...state, error: action.payload, loading: false };
    case Template.CHANGE_TEMPLATE_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case Template.ADD_SECTION: {
      const sections = [
        ...state.sections.map((x) => {
          if (x.label >= action.payload.label) {
            x.label++;
          }
          return x;
        }),
        action.payload as TemplateSection,
      ].sort((a, b) => a.label - b.label);
      return {
        ...state,
        sections,
      };
    }
    case Template.REMOVE_SECTION: {
      const toRemove = state.sections.filter((x) => x.id === action.payload)[0];
      const label = toRemove.label;
      const sections = state.sections
        .filter((x) => x.id !== action.payload)
        .map((x) => {
          if (x.label >= label) {
            x.label--;
          }
          return x;
        });

      return {
        ...state,
        sections,
      };
    }
    case Template.ADD_ITEM: {
      const position = action.payload.position + 1;
      const parentSection = state.sections.find(
        (x) => x.id === action.payload.item.parentId
      );
      const sectionItems = [
        ...state.items
          .filter((x) => x.parentId === action.payload.item.parentId)
          .map((x) => {
            if (x.groupId) return x;

            const labelVals = x.label!.split(".");
            let pos = parseInt(labelVals[1]);
            if (pos >= position) {
              x.label = labelVals[0] + "." + ++pos;
            }
            return x;
          }),
        {
          ...action.payload.item,
          label: parentSection?.label + "." + position,
        },
      ].sort((a, b) => a.label - b.label);

      return {
        ...state,
        items: [
          ...state.items.filter(
            (x) => x.parentId !== action.payload.item.parentId
          ),
          ...sectionItems,
        ],
      };
    }
    case Template.ADD_GROUP_ITEM:
      return { ...state, items: [...state.items, action.payload] };

    case Template.REMOVE_ITEM: {
      const toRemove = state.items.find((x) => x.id === action.payload);
      if (!toRemove) {
        return state;
      }
      //remove when it is a group item: no need to recalc the label value
      //all the group items are removed as well
      if (toRemove.groupId) {
        const items = state.items.filter((x) => x.id !== toRemove.id);
        return { ...state, items };
      }

      //remove if the label recalculation is needed
      const position = parseInt(toRemove!.label!.split(".")[1]);

      const sectionItems = state.items
        .filter(
          (x) =>
            x.parentId === toRemove.parentId &&
            x.id !== toRemove.id &&
            x.groupId !== toRemove.id
        )
        .map((x) => {
          if (x.groupId) return x;

          const labelVals = x.label!.split(".");
          let pos = parseInt(labelVals[1]);
          if (pos >= position) {
            x.label = labelVals[0] + "." + --pos;
          }
          return x;
        });

      return {
        ...state,
        items: [
          ...state.items.filter((x) => x.parentId !== toRemove.parentId),
          ...sectionItems,
        ],
      };
    }
    case Template.CHANGE_SECTION: {
      return {
        ...state,
        sections: state.sections.map((section) => {
          if (section.id === action.payload.id) {
            const updated: TemplateSection & { [key: string]: any } = {
              ...section,
            };
            updated[action.payload.key] = action.payload.value;
            return updated;
          }
          return section;
        }),
      };
    }
    case Template.CHANGE_ITEM_VALUE: {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === action.payload.id) {
            const updated: TemplateItem & { [key: string]: any } = { ...item };
            updated[action.payload.key] = action.payload.value;
            return updated;
          }
          return item;
        }),
      };
    }
    case Template.ADD_OPTION:
      return {
        ...state,
        options: [...state.options, action.payload],
      };
    case Template.REMOVE_OPTION:
      return {
        ...state,
        options: state.options.filter((x) => x.id !== action.payload),
      };

    case Template.CHANGE_OPTION:
      return {
        ...state,
        options: state.options.map((option) => {
          if (option.id === action.payload.id) {
            const newOpt: TemplateOption & { [key: string]: any } = { ...option };
            newOpt[action.payload.key] = action.payload.value;
            return newOpt;
          }
          return option;
        }),
      };
    case Template.ADD_ITEM_OPTION:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === action.payload.itemId) {
            const updated = {
              ...item,
              options: item.options?.length
                ? [...item.options, action.payload.option]
                : [action.payload.option],
            };
            return updated;
          }
          return item;
        }),
      };
    case Template.REMOVE_ITEM_OPTION:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === action.payload.itemId) {
            const updated = {
              ...item,
              options: item.options?.filter((x) => x.id !== action.payload.id),
            };
            return updated;
          }
          return item;
        }),
      };

    case Template.CHANGE_ITEM_OPTION:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === action.payload.itemId) {
            const updated = {
              ...item,
              options: item.options?.map((option) => {
                if (option.id === action.payload.id) {
                  const newOpt: TemplateOption & { [key: string]: any } = {
                    ...option,
                  };
                  newOpt[action.payload.key] = action.payload.value;
                  return newOpt;
                }
                return option;
              }),
            };
            return updated;
          }
          return item;
        }),
      };
    case User.LOGOUT:
    case Template.CLEAR_TEMPLATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default templateReducer;
