import { v4 as uuidv4 } from "uuid";
import { Common, Template } from "../../../constants/actionTypes";
import {
  flattenTemplateTree,
  combineTemplateTree,
  updateLocalData,
  getLocalData,
} from "../../../common/utils";
import { LocalStorageItems } from "../../../constants/appConstants";
import { handleError } from "../../../common/commonActions";
import TemplateService from "../../../services/TemplateService";
import {
  TemplateItem,
  TemplateItemType,
  TemplateModel,
  TemplateOption,
  TemplateSection,
} from "../../../interfaces/models";
import { AppError } from "../../../interfaces/frontend";
import { AnyAction, Dispatch } from "redux";
import { AppState } from "../../../store/store";
import { useAppDispatch } from "../../../common/hooks";
import { ThunkDispatch } from "redux-thunk";

export const useTemplateActions = () => {
  const getTemplate =
    (templateId: string) => async (dispatch: Dispatch<AnyAction>) => {
      dispatch({ type: Template.GET_TEMPLATE });
      try {
        const template = await TemplateService.getTemplate(templateId);
        const state = flattenTemplateTree(template);

        dispatch({ type: Template.TEMPLATE_REQUEST_SUCCEEDED, payload: state });
      } catch (error) {
        dispatch({
          type: Template.TEMPLATE_REQUEST_FAILED,
          payload: error,
          error: true,
        });
      }
    };

  const getLocalTemplate =
    () => async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      const localData = await getLocalData<TemplateModel>(
        LocalStorageItems.Template
      );
      if (localData) {
        const localState = flattenTemplateTree(localData);
        await applyLocalChanges(dispatch, getState, {
          type: Template.TEMPLATE_REQUEST_SUCCEEDED,
          payload: localState,
        });
      }
    };

  const uploadTemplate =
    () => async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      dispatch({ type: Template.UPLOAD_TEMPLATE });
      const template = combineTemplateTree(getState().template);
      try {
        await TemplateService.uploadTemplate(template);
        dispatch({ type: Template.TEMPLATE_REQUEST_SUCCEEDED });
      } catch (error: unknown) {
        handleError(error as string | AppError, dispatch, {
          type: Template.TEMPLATE_REQUEST_FAILED,
          payload: error,
          error: true,
        });
      }
    };

  const setDefault =
    () => async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      dispatch({ type: Template.SET_DEFAULT_TEMPLATE });
      try {
        const templateId = getState().template.id;
        await TemplateService.setDefaultTemplate(templateId);
        dispatch({ type: Template.TEMPLATE_REQUEST_SUCCEEDED });
      } catch (error) {
        handleError(error as string | AppError, dispatch, {
          type: Template.TEMPLATE_REQUEST_FAILED,
          payload: error,
          error: true,
        });
      }
    };

  const getDefaultTemplate = () => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: Template.GET_TEMPLATE });
    try {
      const template = await TemplateService.getDefaultTemplate();
      const state = flattenTemplateTree(template);

      dispatch({ type: Template.TEMPLATE_REQUEST_SUCCEEDED, payload: state });
    } catch (error) {
      handleError(error as string | AppError, dispatch, {
        type: Template.TEMPLATE_REQUEST_FAILED,
        payload: error,
        error: true,
      });
    }
  };

  const clearTemplate = () => (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: Template.CLEAR_TEMPLATE });
    localStorage.removeItem(LocalStorageItems.Template);
  };

  const removeTemplate =
    (templateId: string) => async (dispatch: Dispatch<AnyAction>) => {
      await TemplateService.removeTemplate(templateId);
      dispatch({ type: Template.CLEAR_TEMPLATE });
      localStorage.removeItem(LocalStorageItems.Template);
    };

  const changeTemplateName =
    (name: string) => (dispatch: Dispatch<AnyAction>) => {
      dispatch({ type: Template.CHANGE_TEMPLATE_NAME, payload: name });
    };

  const addSection =
    (position?: number) =>
    async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      const section: TemplateSection = {
        id: uuidv4(),
        name: "",
        label: position ? position : 1,
        hidden: false,
        items: [],
      };

      await applyLocalChanges(dispatch, getState, {
        type: Template.ADD_SECTION,
        payload: section,
      });
    };

  const addItem =
    (parentId: string, position: number, itemType: number) =>
    async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      const item: TemplateItem = {
        id: uuidv4(),
        parentId: parentId,
        name: "",
        options: [],
        label: "",
        type: itemType,
        autoHideItems: false,
        groupId: ""
      };
      await applyLocalChanges(dispatch, getState, {
        type: Template.ADD_ITEM,
        payload: { item, position },
      });
    };

  const addGroupItem =
    (groupId: string) =>
    async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      const group = getState().template.items.find((x) => x.id === groupId);
      if (group) {
        const item: TemplateItem = {
          id: uuidv4(),
          parentId: group.parentId,
          name: "",
          options: [],
          label: "",
          groupId: group.id,
          type: TemplateItemType.Item,
          autoHideItems: false
        };
        await applyLocalChanges(dispatch, getState, {
          type: Template.ADD_GROUP_ITEM,
          payload: item,
        });
      }
    };

  const removeSection =
    (id: string) =>
    async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      await applyLocalChanges(dispatch, getState, {
        type: Template.REMOVE_SECTION,
        payload: id,
      });
    };

  const removeItem =
    (id: string) =>
    async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      await applyLocalChanges(dispatch, getState, {
        type: Template.REMOVE_ITEM,
        payload: id,
      });
    };

  const changeSection =
    (id: string, key: string, value: string | number | boolean) =>
    async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      await applyLocalChanges(dispatch, getState, {
        type: Template.CHANGE_SECTION,
        payload: { id, key, value },
      });
    };

  const changeItemValue =
    (id: string, key: string, value: string | number) =>
    async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      await applyLocalChanges(dispatch, getState, {
        type: Template.CHANGE_ITEM_VALUE,
        payload: { id, key, value },
      });
    };

  const addOption =
    () => async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      const option: TemplateOption = {
        id: uuidv4(),
        value: "",
        description: "",
        worth: false,
        uncorrectable: false
      };
      await applyLocalChanges(dispatch, getState, {
        type: Template.ADD_OPTION,
        payload: option,
      });
    };

  const removeOption =
    (id: string) =>
    async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      await applyLocalChanges(dispatch, getState, {
        type: Template.REMOVE_OPTION,
        payload: id,
      });
    };

  const changeOption =
    (id: string, key: string, value: string | boolean) =>
    async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      await applyLocalChanges(dispatch, getState, {
        type: Template.CHANGE_OPTION,
        payload: { id, key, value },
      });
    };

  const applyLocalChanges = async (
    dispatch: Dispatch<AnyAction>,
    getState: () => AppState,
    action: AnyAction
  ) => {
    dispatch(action);
    updateLocalData(
      LocalStorageItems.Template,
      combineTemplateTree(getState().template)
    );
  };

  const addItemOption =
    (itemId: string) =>
    async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      const option: TemplateOption = {
        id: uuidv4(),
        value: "",
        description: "",
        worth: false,
        uncorrectable: false
      };
      await applyLocalChanges(dispatch, getState, {
        type: Template.ADD_ITEM_OPTION,
        payload: { itemId, option },
      });
    };

  const removeItemOption =
    (itemId: string, id: string) =>
    async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      await applyLocalChanges(dispatch, getState, {
        type: Template.REMOVE_ITEM_OPTION,
        payload: { itemId, id },
      });
    };

  const changeItemOption =
    (itemId: string, id: string, key: string, value: string | boolean) => 
    async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      await applyLocalChanges(dispatch, getState, {
        type: Template.CHANGE_ITEM_OPTION,
        payload: { itemId, id, key, value },
      });
    };

  const getTemplateList =
    () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
      dispatch({ type: Common.GET_LIST });
      try {
        const templates = await TemplateService.getTemplates();
        dispatch({
          type: Common.GET_TEMPLATE_LIST_SUCCEEDED,
          payload: templates,
        });
      } catch (error: unknown) {
        handleError(error as string | AppError, dispatch, {
          type: Common.GET_TEMPLATE_LIST_FAILED,
          payload: error,
          error: true,
        });
      }
    };

  const dispatch = useAppDispatch();
  return {
    getTemplate: (templateId: string) => dispatch(getTemplate(templateId)),
    getLocalTemplate: () => dispatch(getLocalTemplate()),
    uploadTemplate: () => dispatch(uploadTemplate()),
    clearTemplate: () => dispatch(clearTemplate()),
    removeTemplate: (templateId: string) =>
      dispatch(removeTemplate(templateId)),
    setDefault: () => dispatch(setDefault()),
    changeTemplateName: (name: string) => dispatch(changeTemplateName(name)),
    removeItem: (id: string) => dispatch(removeItem(id)),
    changeItemValue: (
      id: string,
      key: keyof TemplateItem,
      value: string | number
    ) => dispatch(changeItemValue(id, key, value)),
    addItem: (parentId: string, position: number, itemType: TemplateItemType) =>
      dispatch(addItem(parentId, position, itemType)),
    addGroupItem: (groupId: string) => dispatch(addGroupItem(groupId)),
    addSection: (position?: number) => dispatch(addSection(position)),
    removeSection: (id: string) => dispatch(removeSection(id)),
    changeSection: (
      id: string,
      key: string,
      value: string | number | boolean
    ) => dispatch(changeSection(id, key, value)),
    addOption: () => dispatch(addOption()),
    removeOption: (id: string) => dispatch(removeOption(id)),
    changeOption: (id: string, key: string, value: string | boolean) =>
      dispatch(changeOption(id, key, value)),
    addItemOption: (itemId: string) => dispatch(addItemOption(itemId)),
    removeItemOption: (itemId: string, id: string) =>
      dispatch(removeItemOption(itemId, id)),
    changeItemOption: (
      itemId: string,
      id: string,
      key: string,
      value: string | boolean
    ) => dispatch(changeItemOption(itemId, id, key, value)),
    getDefaultTemplate: () => dispatch(getDefaultTemplate()),
    getTemplateList: () => dispatch(getTemplateList()),
  };
};
