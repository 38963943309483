import PdfDoc from "./default/PdfDoc";
import { PdfReport } from "./PdfGenerator";
import PdfDocPage from "./Vedligeholdelserapport/PdfDocPage";

//this is the kostyl which is made for chosing the correct pdf report template
//it is better to create a pdf report builder if there are plenty of pdf report templates
const PdfDocumentLayout = (props: {
  report: PdfReport;
}) => {
  switch (props.report.template.name) {
    case "Vedligeholdelserapport":
      //TODO: needs to be replaced with new doc design
      return (
        <PdfDocPage
          {...props.report}
        />
      );
    default:
      return <PdfDoc {...props.report} />;
  }
};

export default PdfDocumentLayout;
