import React, { useEffect, Suspense } from "react";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline, createTheme, styled } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import ErrorDialog from "./shared/ErrorDialog";
import "./App.scss";
import { useCommon } from "./common/commonActions";
import LoginPage from "./pages/LoginPage";
import { useUserActions } from "./user/userActions";
import LoadingOverlay from "./shared/LoadingOverlay";
import OverviewPage from "./pages/OverviewPage";
import { AddressFilter, UserRole } from "./interfaces/models";
import ReportListPage from "./pages/ReportListPage";
import RatingPage from "./pages/RatingPage";
import { useUser } from "./common/hooks";
import { DeafultRedirect, ProtectedRoutes } from "./helpers/RouterExtensions";
import InspectorProfilePage from "./pages/InspectorProfilePage";
import { daDK } from "@mui/material/locale";
import { ViewerProps } from "./pages/CreateReportPage";
import InspectorAdminPage from "./pages/InspectorAdminPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const AdminPanelLazy = React.lazy(() => import("./admin/Admin"));
const CreateReportPageLazy = React.lazy(() => import("./pages/CreateReportPage"));
const TemplateEditorPageLazy = React.lazy(() => import("./pages/TemplateEditorPage"));
const CustomerWishListPageLazy = React.lazy(() => import("./pages/CustomerWishListPage"));

const CustomerWishListPage = () => (
  <Suspense fallback={<LoadingOverlay loading={true} />}>
    <CustomerWishListPageLazy />
  </Suspense>
);

const AdminPanel = () => (
  <Suspense fallback={<LoadingOverlay loading={true} />}>
    <AdminPanelLazy />
  </Suspense>
);

const CreateReportPage = (props: ViewerProps) => (
  <Suspense fallback={<LoadingOverlay loading={true} />}>
    <CreateReportPageLazy {...props} />
  </Suspense>
);

const TemplateEditorPage = () => (
  <Suspense fallback={<LoadingOverlay loading={true} />}>
    <TemplateEditorPageLazy />
  </Suspense>
);

const App = () => {
  const user = useUser();
  const userActions = useUserActions();
  const [common, commonActions] = useCommon();

  useEffect(() => {
    const setup = async () => {
      await commonActions.setup();
    };
    setup();
    return () => {
      //
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const authLocal = async () => {
      if (user.localUser && !user.authorized) {
        await userActions.authenticateLocal();
      }
    };
    authLocal();
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.authorized, user.role]);

  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={AppTheme}>
          <CssBaseline />
          {user.authorized ? (
            <>
              {(user.role === UserRole.Administrator || user.role === UserRole.Root) && (
                <Routes>
                  <Route element={<ProtectedRoutes redirectTo={"/"} />}>
                    <Route path="/admin/*" element={<AdminPanel />} />
                    <Route path="*" element={<DeafultRedirect default="/admin" />} />
                  </Route>
                </Routes>
              )}
              {user.role === UserRole.Inspector && (
                <WebkitOverflowScrollingDiv>
                  <Routes>
                    <Route element={<ProtectedRoutes redirectTo={"/inspector/login"} />}>
                      <Route path="/inspector" element={<OverviewPage blockKey={UserRole.Inspector} />} />
                      <Route
                        path="/inspector/report/create"
                        element={
                          <CreateReportPage
                            addressFilter={AddressFilter.NoAddress}
                            noHistory={false}
                            newReportDialog={true}
                          />
                        }
                      />
                      <Route path="/inspector/report/view" element={<ReportListPage />} />
                      <Route path="/inspector/report/view/:id" element={<ReportListPage />} />
                      <Route
                        path="/inspector/report/edit"
                        element={
                          <CreateReportPage
                            addressFilter={AddressFilter.WithAddress}
                            noHistory={false}
                            newReportDialog={false}
                          />
                        }
                      />
                      <Route
                        path="/inspector/report/edit/:id"
                        element={
                          <CreateReportPage
                            addressFilter={AddressFilter.WithAddress}
                            noHistory={false}
                            newReportDialog={false}
                          />
                        }
                      />
                      <Route path="/inspector/rating" element={<RatingPage />} />
                      <Route path="/inspector/profile" element={<InspectorProfilePage />} />
                      <Route path="/inspector/templates" element={<TemplateEditorPage />} />
                      <Route path="/inspector/admin" element={<InspectorAdminPage />} />
                      <Route path="*" element={<DeafultRedirect default="/inspector" />} />
                    </Route>
                  </Routes>
                </WebkitOverflowScrollingDiv>
              )}
            </>
          ) : (
            <Routes>
              <Route path="/s/:id" element={<CustomerWishListPage />} />
              <Route path="/forgotpwd" element={<ForgotPasswordPage />} />
              <Route path="/resetpwd/:token" element={<ResetPasswordPage />} />
              <Route path="*" element={<LoginPage />} />
            </Routes>
          )}
          <ErrorDialog open={common.showError} error={common.error} handleClose={commonActions.clearError} />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

export default App;

const WebkitOverflowScrollingDiv = styled("div")({
  WebkitOverflowScrolling: "touch",
  overflowY: "hidden",
});

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#32C111",
        contrastText: "#fff",
      },
      divider: "#E4E4E7",
      secondary: {
        main: "#1F2041",
        contrastText: "#fff",
      },
      error: {
        main: "#FF7C39",
        light: "#FFD588",
      },
      info: {
        main: "#8000FF",
        light: "#00C0FF",
      },
    },
    typography: {
      fontFamily: "'Quicksand', sans-serif",
      h2: {
        fontSize: 16,
      },
      h3: {
        fontSize: 26,
      },
      h4: {
        fontSize: 24,
      },
      h5: {
        fontSize: 18,
      },
      h6: {
        fontSize: 20,
        fontWeight: 300,
      },
      subtitle1: {
        fontSize: 16,
        fontWeight: 300,
      },
      subtitle2: {
        fontSize: 15,
        fontWeight: 800,
      },
      body1: {
        fontSize: 15,
      },
      body2: {
        fontSize: 14,
      },
    },
  },
  daDK
);

const AppTheme = createTheme(
  theme,
  {
    components: {
      MuiTypography: {
        colorTextSecondary: {
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "standard",
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: "standard",
        },
      },

      MuiButton: {
        props: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            borderRadius: 20,
            fontSize: 14,
            minWidth: 80,
            textTransform: "none",
          },
          contained: {
            fontWeight: 600,
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
          },
          outlined: {
            fontWeight: 600,
          },
        },
        label: {
          textTransform: "none",
        },
      },
    },
  },
  daDK
);
