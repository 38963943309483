import { applyMiddleware, createStore, combineReducers, compose, Middleware } from "redux";
import thunkMiddleware from "redux-thunk";
import reportReducer, { ReportState } from "../pages/reportReducer";
import commonReducer, { CommonState } from "../common/commonReducer";
import userReducer, { UserState } from "../user/userReducer";
import adminReducer, { AdminState } from "../admin/adminReducer";
//import { signalrMiddleware } from "./signalrMiddleware";
import templateReducer, { TemplateState } from "../shared/modules/templates/templateReducer";
import { cloneDeep } from "lodash";
import solutionReducer, { SolutionState } from "../admin/solutionsReducer";

export type AppState = {
  report: ReportState;
  common: CommonState;
  user: UserState;
  admin: AdminState;
  template: TemplateState;
  solution: SolutionState;
};

const rootReducer = combineReducers<AppState>({
  report: reportReducer,
  common: commonReducer,
  user: userReducer,
  admin: adminReducer,
  template: templateReducer,
  solution: solutionReducer,
});

const actionLogger: Middleware<NonNullable<unknown>, AppState> = (store) => (next) => async (action) => {
  console.info(new Date().toLocaleString(), "action", action.type);
  console.info("prev state", cloneDeep(store.getState()));
  console.info("action", action);
  const result = next(action);
  console.info("next state", cloneDeep(store.getState()));
  return result;
};

//put here any additional middleware
const middlewares: Middleware<NonNullable<unknown>, AppState>[] = [
  thunkMiddleware,
  //  , signalrMiddleware
];

//adding logger for the dev build
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  middlewares.push(actionLogger);
}

const store = createStore(rootReducer, compose(applyMiddleware(...middlewares)));
export default store;
