import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  InputAdornment,
  IconButton,
  Link
} from "@mui/material";
import { useUserActions } from "../user/userActions";
import MainContainer from "../shared/MainContainer";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import MenuBlocks from "./components/MenuBlocks";
import { useUser } from "../common/hooks";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";

type LoginData = {
  email: string;
  password: string;
};

const LoginPage = () => {
  const [visibility, setVisibility] = useState(false);
  const toggleVisibility = () => setVisibility(!visibility);

  const methods = useForm<LoginData>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = methods;

  const user = useUser();
  const actions = useUserActions();

  const location = useLocation();

  const route = MenuBlocks["login"].find((x) => x.path === location.pathname);

  const login = async () => {
    try {
      await actions.login(getValues().email, getValues().password);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MainContainer title="Partner login">
      <Grid item container alignItems="flex-start" justifyContent="center" sx={{ marginTop: "10%" }}>
        <Grid item container xs={11} sm={8} md={6} lg={5} direction="column" alignContent="center" alignItems="center">
          <Grid item>
            <Typography paragraph variant="h6">
              {route ? route.text : "Log ind"}
            </Typography>
          </Grid>
          <Grid item container>
            <Card elevation={5} sx={{ width: "100%" }}>
              <CardContent>
                <form onSubmit={handleSubmit(login)}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="email"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            variant="outlined"
                            error={!!errors.email}
                            id="email-input"
                            label="Indtast din email her"
                            type="email"
                            margin="dense"
                            autoComplete="email"
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        name="password"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            error={!!errors.password}
                            id="password-input"
                            label="Kodeord"
                            type={visibility ? "text" : "password"}
                            autoComplete="current-password"
                            {...field}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={toggleVisibility} edge="end" style={{ padding: 5 }} size="large">
                                    {visibility ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item style={{ alignSelf: "center" }}>
                      <LoadingButton variant="contained" color="primary" type="submit" loading={user.loading}>
                        Log ind
                      </LoadingButton>
                    </Grid>
                    <Grid item style={{ alignSelf: "center" }}>
                      <Link href="/forgotpwd" underline="none">
                        <Typography variant="body1" color="primary">
                          Glemt kodeord?
                        </Typography>
                      </Link>
                      </Grid>
                    {user.error && (
                      <Grid item>
                        <Typography variant="body1" color="error">
                          Login mislykkedes
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default LoginPage;
