import React, { useEffect, useReducer } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { InspectorReportObject } from "../../interfaces/models";

interface EditBuildingsDialogState {
  objects: InspectorReportObject[];
}

type ActionType = { type: "init" | "add" | "change" | "remove"; payload: any };

const reducer = (state: EditBuildingsDialogState, action: ActionType): EditBuildingsDialogState => {
  switch (action.type) {
    case "init":
      return { ...state, objects: action.payload as InspectorReportObject[] };
    case "add":
      return { ...state, objects: [...state.objects, action.payload as InspectorReportObject] };
    //return state;
    case "change":
      return {
        ...state,
        objects: state.objects.map((x) => {
          if (x.id !== action.payload.id) return x;
          return { ...x, name: action.payload.value };
        }),
      };
    case "remove":
      return { ...state, objects: state.objects.filter((x) => x.id !== (action.payload as string)) };
    default:
      return state;
  }
};

const useStyles = makeStyles((theme) => ({
    input: {
      minWidth: 300,
      [theme.breakpoints.down("sm")]: {
        minWidth: 200
      }
    }
}));

const initialState: EditBuildingsDialogState = {
  objects: [],
};

export default function EditBuildingsDialog(props: {
  objects: InspectorReportObject[];
  open: boolean;
  handleClose: (objects?: InspectorReportObject[]) => void;
}) {
  const classes = useStyles();

  const { objects } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "init", payload: objects });
    return () => {
      //
    };
  }, [objects]);

  return (
    <Dialog open={props.open} scroll={"paper"} onClose={() => props.handleClose()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Rediger bygninger</DialogTitle>
      <DialogContent dividers={true}>
        {state.objects.map((item, idx) => (
          <div
            key={item.id}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <TextField
              className={classes.input}
              autoFocus
              margin="dense"
              id="name"
              label="Bygningens navn"
              fullWidth
              value={item.name}
              onChange={(event) =>
                dispatch({
                  type: "change",
                  payload: {
                    id: item.id,
                    value: event.target.value,
                  },
                })
              }
            />
            {idx > 0 && (
              <Button onClick={() => dispatch({ type: "remove", payload: item.id })}>
                Slet
                {/* <ClearIcon /> */}
              </Button>
            )}
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">
          Annuller
        </Button>
        <Button onClick={() => props.handleClose(state.objects)} color="primary">
          Gem
        </Button>
      </DialogActions>
    </Dialog>
  );
}
