import React, { useEffect, useReducer } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Select,
  MenuItem,
  Grid,
  LinearProgress,
  Typography,
  useTheme,
  useMediaQuery,
  SelectChangeEvent,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { KeyboardArrowLeft, KeyboardArrowRight, Check } from "@mui/icons-material";
import { useDebouncedValue } from "../common/hooks";
import { StateAction } from "../interfaces/frontend";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

type ColumnType<T> = {
  label: string;
  key: keyof T;
  sortKey?: keyof T;
  align?: "left" | "right" | "inherit" | "center" | "justify";
  transform?: (item: any, rowId: string) => string | React.ReactNode | undefined;
  hidden?: boolean;
  handleCellClick?: (id: string) => void;
};
export type DataTableProps<T> = {
  data: any[];
  getData: (
    query: string,
    filter: any,
    skip: number,
    take: number,
    sortField: string,
    sortDescending: boolean
  ) => Promise<any>;
  onRowClick?: (rowId: string) => void;
  textFilter?: boolean;
  dropdownFilterValues?: any[];
  dropdownFilterTitle?: string;
  initialFilter?: string;
  count?: number;
  itemsPerPage?: number;
  multiselect?: boolean;
  loading?: boolean;
  columns: ColumnType<T>[];
  cellActionComponent?: React.ReactElement;
  createButtonClick?: () => void;
  refetch?: boolean;
};

const defaultItemsPerPage = 10;
const useStyles = makeStyles({
  filter: {
    margin: 10,
  },
  tableIcon: {
    position: "absolute",
  },
});

type DataTableState = {
  page: number;
  filter: string;
  query: string;
  sortField: string;
  sortDescending: boolean;
  selected: string[] | null;
  pageCount: number;
};

const reducer = (state: DataTableState, action: StateAction<DataTableState>) => {
  return { ...state, [action.type]: action.payload };
};

const DataTable = <T,>(props: DataTableProps<T>) => {
  const { itemsPerPage = defaultItemsPerPage, count, getData, dropdownFilterValues, initialFilter } = props;

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    filter: initialFilter || "",
    query: "",
    sortField: "",
    sortDescending: true,
    selected: null,
    pageCount: 0,
  });

  const classes = useStyles();
  const debouncedQuery = useDebouncedValue(state.query, 500);
  const theme = useTheme();

  useEffect(() => {
    if (props.refetch) {
      getData(debouncedQuery, state.filter, state.page * itemsPerPage, itemsPerPage, state.sortField, state.sortDescending);
      return () => {
        //
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refetch]);

  useEffect(() => {
    getData(debouncedQuery, state.filter, 0, itemsPerPage, state.sortField, state.sortDescending);
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, debouncedQuery, state.sortField, state.sortDescending]);

  useEffect(() => {
    if (dropdownFilterValues && dropdownFilterValues.length && !state.filter) {
      dispatch({ type: "filter", payload: dropdownFilterValues[0].id });
    }
  }, [dropdownFilterValues, state.filter]);

  useEffect(() => {
    dispatch({ type: "pageCount", payload: Math.floor(((count || 0) + itemsPerPage - 1) / itemsPerPage) });
    return () => dispatch({ type: "pageCount", payload: 0 });
  }, [state.pageCount, count, itemsPerPage]);

  const handlePreviousPage = () => {
    getData(
      state.query,
      state.filter,
      (state.page - 1) * itemsPerPage,
      itemsPerPage,
      state.sortField,
      state.sortDescending
    );
    dispatch({ type: "pageCount", payload: 0 });
    dispatch({ type: "page", payload: state.page - 1 });
  };

  const handleNextPage = async () => {
    await getData(
      state.query,
      state.filter,
      (state.page + 1) * itemsPerPage,
      itemsPerPage,
      state.sortField,
      state.sortDescending
    );
    dispatch({ type: "page", payload: state.page + 1 });
  };

  const handleFilterChange = async (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    dispatch({ type: "filter", payload: event.target.value });
    dispatch({ type: "page", payload: 0 });
    await getData(state.query, event.target.value, 0, itemsPerPage, state.sortField, state.sortDescending);
  };

  const onRowClick = (itemId: string) => {
    if (props.multiselect) {
      if (state.selected) {
        if (state.selected.filter((x) => x === itemId).length) {
          dispatch({ type: "selected", payload: state.selected.filter((x) => x !== itemId) });
        } else {
          dispatch({ type: "selected", payload: [...state.selected, itemId] });
        }
      } else {
        dispatch({ type: "selected", payload: [itemId] });
      }
    } else {
      dispatch({ type: "selected", payload: itemId });
    }
    if (props.onRowClick) {
      props.onRowClick(itemId);
    }
  };

  // const getPageCount = () => {
  //   return Math.floor((props.count + itemsPerPage - 1) / itemsPerPage);
  // };

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch({ type: "query", payload: event.target.value });
    dispatch({ type: "page", payload: 0 });
  };

  const changeSortField = (fieldname: keyof T) => {
    if (fieldname !== state.sortField) {
      dispatch({ type: "sortField", payload: fieldname });
    } else {
      dispatch({ type: "sortDescending", payload: !state.sortDescending });
    }
  };
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container>
      <Paper style={{ width: "100%" }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={11} sm={5}>
            {props.textFilter && (
              <TextField
                id="filter-input"
                label="Søg"
                type="text"
                variant="outlined"
                margin="dense"
                name="filter"
                fullWidth={isXs}
                value={state.query}
                onChange={handleQueryChange}
                className={classes.filter}
              />
            )}
          </Grid>
          {dropdownFilterValues && dropdownFilterValues.length && (
            <Grid item xs={12} sm={7} alignItems="center" container justifyContent={isXs ? "flex-start" : "flex-end"}>
              <Grid item>
                <Typography style={{ padding: "5px 5px 0 10px" }}>{props.dropdownFilterTitle}</Typography>
              </Grid>
              <Grid item>
                <Select
                  variant="standard"
                  className={classes.filter}
                  value={state.filter}
                  onChange={handleFilterChange}
                  inputProps={{
                    id: "dropdown-filter",
                  }}
                >
                  {dropdownFilterValues.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              {props.multiselect && <StyledTableCell key={"select-item"} padding={"checkbox"}></StyledTableCell>}
              {props.columns.map((headCell, index) =>
                !headCell.hidden ? (
                  <StyledTableCell
                    padding="normal"
                    key={index}
                    align={headCell.align}
                    size="small"
                    onClick={() => changeSortField(headCell.sortKey || headCell.key)}
                  >
                    {headCell.label}
                    {(headCell.sortKey || headCell.key) === state.sortField &&
                      (state.sortDescending ? (
                        <ArrowDropDownIcon className={classes.tableIcon} />
                      ) : (
                        <ArrowDropUpIcon className={classes.tableIcon} />
                      ))}
                  </StyledTableCell>
                ) : null
              )}
              {!!props.cellActionComponent && (
                <StyledTableCell key={"action"} padding="normal" align="right">
                  {""}
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((item, index) => {
              const rowData: any[] = [];
              for (let i = 0; i < props.columns.length; i++) {
                if (props.columns[i].hidden) continue;
                const column = props.columns[i];
                if (column.transform) {
                  rowData.push(
                    <StyledTableCell
                      key={item.id + (column.key as string)}
                      onClick={() => column.handleCellClick && column.handleCellClick(item.id)}
                    >
                      {column.transform(item[column.key], item.id)}
                    </StyledTableCell>
                  );
                } else {
                  rowData.push(
                    <StyledTableCell
                      key={item.id + (column.key as string)}
                      onClick={() => column.handleCellClick && column.handleCellClick(item.id)}
                    >
                      {item[column.key]}
                    </StyledTableCell>
                  );
                }
              }
              return (
                <TableRow
                  onClick={() => props.onRowClick && onRowClick(item.id)}
                  hover
                  key={index}
                  selected={
                    !!state.selected &&
                    (props.multiselect
                      ? !!state.selected.filter((x) => x === item.id).length
                      : item.id === state.selected)
                  }
                  style={{ fontWeight: item.highligted ? 600 : 200 }}
                >
                  {props.multiselect && (
                    <StyledTableCell key={"select-item"} padding={"checkbox"}>
                      {state.selected && !!state.selected.filter((x) => x === item.id).length && <Check />}
                    </StyledTableCell>
                  )}
                  {rowData}
                  {!!props.cellActionComponent && (
                    <StyledTableCell key={item.id} align="right" padding="checkbox">
                      {React.cloneElement(props.cellActionComponent, { item })}
                    </StyledTableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        {((!!count && state.pageCount > 1) || !props.count) && (
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item>
              {!!props.count && (
                <div>
                  {state.page + 1}/{state.pageCount}
                </div>
              )}
            </Grid>
            <Grid item>
              <IconButton onClick={handlePreviousPage} disabled={state.page === 0} size="large">
                <KeyboardArrowLeft />
              </IconButton>
              <IconButton
                onClick={handleNextPage}
                disabled={props.count ? state.pageCount <= state.page + 1 : itemsPerPage > props.data.length}
                size="large"
              >
                <KeyboardArrowRight />
              </IconButton>
            </Grid>
          </Grid>
        )}
        {props.loading && <LinearProgress />}
        <Grid item>
          {props.createButtonClick && (
            <Button variant="contained" onClick={props.createButtonClick} sx={{ m: 1 }}>
              Create new
            </Button>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default DataTable;

const StyledTableCell = withStyles({
  root: {
    height: 50,
    padding: "5px 8px",
    cursor: "pointer",
    fontSize: 14,
    fontWeight: 400,
  },
  head: {
    fontSize: 14,
    fontWeight: 600,
    background: "rgba(0, 0, 0, 0.08)",
  },
})(TableCell);
