import { Button, Typography, Grid } from "@mui/material";
import React from "react";

class ErrorBoundary extends React.Component<{ errorMessage: string; children: React.ReactNode }, { hasError: boolean }> {
  constructor(props: { errorMessage: string; children: React.ReactNode } | Readonly<{ errorMessage: string; children: React.ReactNode }>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
}

  reset = () => {
    this.setState({ hasError: false });
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Grid style={{height: "100%", color: "#fff"}} item justifyContent="center" alignItems="center" container direction="column" spacing={3}>
          <Grid item>
          <Typography variant="h3">{this.props.errorMessage}</Typography>
          </Grid>
          <Grid item>
          <Button variant="outlined" color="secondary"  style={{color: "#fff", borderColor: "#fff"}} onClick={this.reset}>Refresh</Button>
          </Grid>
        </Grid>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
