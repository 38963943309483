import React, { useState, Fragment } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListSubheader,
  TextField,
  Collapse,
  ListItemSecondaryAction,
  IconButton,
  ListItemButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { drawerWidth } from "../../constants/appConstants";
import HistoryIcon from "@mui/icons-material/History";
import { ReportListItem } from "../../interfaces/models";
import SyncIcon from "@mui/icons-material/Sync";
import { NIL } from "uuid";

type SidebarContentProps = {
  applyFilter?: (filter: string) => void;
  className: string;
  title: string;
  onItemClick: (itemId: string) => void;
  emptyName: string;
  staticData?: { id: string; title: string; name: string; modified: string };
  items: ReportListItem[];
};

interface SidebaProps extends SidebarContentProps {
  open: boolean;
  handleDrawerToggle: () => void;
  permanent?: boolean;
}

const useStyles = makeStyles((theme) => ({
  drawer: (props: any) => ({
    [theme.breakpoints.up("sm")]: {
      width: props.width ? props.width : drawerWidth,
      flexShrink: 0,
    },
  }),
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    minWidth: 240,
  },
}));

const useContentStyles = makeStyles(() => ({
  filterContainer: {
    padding: 10,
    width: "100%",
  },
  nested: {
    paddingLeft: 50,
  },
}));

const SidebarContent = (props: SidebarContentProps) => {
  const classes = useContentStyles();
  const [history, setHistory] = useState<string | null>(null);
  const [filter, setFilter] = useState<string>("");

  const applyFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    //if (event.target.value.length > 3) {
    setFilter(event.target.value);
    if (props.applyFilter) {
      props.applyFilter(event.target.value);
    }
    //}
  };

  const toggleHistory = (id: string) => {
    if (history === id) setHistory(null);
    else setHistory(id);
  };

  const refresh = () => {
    if (props.applyFilter) {
      props.applyFilter(filter);
    }
  };

  return (
    <>
      <div className={props.className} />
      {!!props.applyFilter && (
        <div className={classes.filterContainer}>
          <TextField
            variant="outlined"
            onChange={applyFilter}
            value={filter}
            fullWidth
            placeholder="Indtast addresse eller postnummer"
          />
        </div>
      )}
      {/* <Grid item xs={12} sx={{ padding: 1, justifyContent: "space-between" }} justifyContent="space-between"> */}
        
        <ListSubheader>{props.title}
        <IconButton onClick={() => refresh()}>
          <SyncIcon />
        </IconButton>
        </ListSubheader>
        
        
      {/* </Grid> */}
      <List>
        {props.items?.length
          ? props.items
              .filter((x) => x.originalReportId === NIL || !x.originalReportId)
              .map((item) => (
                <Fragment key={item.id}>
                  <ListItem button onClick={() => props.onItemClick(item.id)}>
                    <ListItemText
                      primary={
                        <>
                          <div>{item.name ? item.name : props.emptyName}</div>
                          <div>
                            {item.reportDate
                              ? new Date(item.reportDate).toLocaleDateString() + " (" + item.caseNumber + ")"
                              : ""}
                          </div>
                        </>
                      }
                      secondary={"Gemt " + new Date(item.modified || Date.now()).toLocaleString()}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="history"
                        onClick={() => toggleHistory(item.id)}
                        disabled={!props.items.find((x) => x.originalReportId === item.id)}
                        size="large"
                      >
                        <HistoryIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Collapse key={"collapse" + item.id} in={history === item.id} timeout="auto" unmountOnExit>
                    <List disablePadding>
                      {props.items?.length &&
                        props.items
                          .filter((x) => x.originalReportId === history)
                          .sort((x, y) => new Date(x.modified) < new Date(y.modified) ? 1 : -1)
                          .map((x) => (
                            <ListItemButton
                              className={classes.nested}
                              key={x.id}
                              onClick={() => props.onItemClick(x.id)}
                            >
                              <ListItemText
                                primary={"Gemt " + new Date(x.originalModified || Date.now()).toLocaleString()}
                              />
                            </ListItemButton>
                          ))}
                    </List>
                  </Collapse>
                </Fragment>
              ))
          : null}
      </List>
      <Divider />
      {!!props.staticData && (
        <>
          <ListSubheader>{props.staticData.title}</ListSubheader>
          <List>
            <ListItem button key={props.staticData.id} disabled>
              <ListItemText primary={props.staticData.name} secondary={props.staticData.modified} />
            </ListItem>
          </List>
        </>
      )}
    </>
  );
};

/**
 *
 * @param {{open : boolean, onClose : function, title:  string, permanent: boolean, staticData: { id: string, title: string, name: string }}} props
 */

const Sidebar = (props: SidebaProps) => {
  const classes = useStyles(props);

  return (
    <nav className={classes.drawer} aria-label="list items">
      <Drawer
        variant={props.permanent ? "permanent" : "temporary"}
        classes={{
          paper: classes.drawerPaper,
        }}
        open={props.open}
        onClose={props.handleDrawerToggle}
      >
        <SidebarContent {...props} className={classes.toolbar} />
      </Drawer>
    </nav>
  );
};

export default Sidebar;
