import React from "react";
import { Grid, Typography} from "@mui/material";
import MenuBlockList from "./components/MenuBlockList";
import MainContainer from "../shared/MainContainer";

const OverviewPage = (props: { blockKey: string | number }) => {
  return (
    <MainContainer title="Min oversigt">
      <Grid item container direction="column" alignItems="center" justifyContent="center" spacing={2} flex={1}>
        <Grid item container justifyContent="center">
            <Grid item container justifyContent="center" xs={11}> 
            <Grid item>
              <Typography variant="h5" paragraph>
                Velkommen til din oversigt på myhouse
              </Typography>
              <Typography paragraph variant="body1">
                Her kan du nemt få adgang til data som vedrører dine kunder og din profil. God fornøjelse!
              </Typography>
              </Grid>
          </Grid> 
        </Grid>
        <Grid item container justifyContent="center" >
          <Grid item container justifyContent="center" xs={11} >
            <MenuBlockList blockKey={props.blockKey} />
          </Grid>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default OverviewPage;
