import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";

export default function NewObjectDialog(props: {
  handleClose: (name?: string) => void;
  open: boolean;
}) {
  const [value, setValue] = useState("");

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(event.target.value);
  };

  const handleClose = (value?: string) => {
    props.handleClose(value);
    setValue("");
  };

  return (
    <div>
      <Dialog
        open={props.open || false}
        onClose={() => handleClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Tilføje en bygning</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Indtast navn"
            fullWidth
            value={value}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Annuller
          </Button>
          <Button onClick={() => handleClose(value)} color="primary">
            Tilføj
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
