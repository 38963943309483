import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import styles from "../styles";
import PdfReportSection from "./PdfReportSection";
import { InspectorReportObject, PictureRef, TemplateModel } from "../../../../../interfaces/models";
import { NIL } from "uuid";

const PdfReportObject = (
  props: InspectorReportObject & {
    template: TemplateModel;
    pictures: PictureRef[];
  }
) => {
  return (
    <View style={{ ...styles.smallText, ...styles.column, ...styles.fullWidth }}>
      <View style={{ ...styles.section, ...styles.row, ...styles.title5 }}>
        <Text style={{ ...styles.paddingRight, ...styles.fontFamily }}>{props.label}</Text>
        <Text style={styles.fontFamily}>{props.name}</Text>
      </View>
      <View style={{ ...styles.row, ...styles.wrap }}>
        {props.pictures &&
          props.pictures
            .filter((x) => !x.itemId || x.itemId === NIL)
            .map((x) => (
              <Image key={"image" + x.id + x.itemId} style={styles.imageInspected} src={x.url! || x.data!} />
            ))}
      </View>
      {!!props.description && (
        <View>
          <Text style={{ ...styles.row, ...styles.title5, ...styles.fontFamily }}>Bygning beskrivelse:</Text>
          <Text style={{ ...styles.fontFamily, ...styles.text, ...styles.borderLeft }}>{props.description}</Text>
        </View>
      )}
      {props.template.sections &&
        props.template.sections
          .filter((x) => !x.hidden)
          .map((section) => (
            <PdfReportSection
              key={props.id + section.id}
              objectId={props.id}
              pictures={props.pictures}
              parameters={props.parameters?.filter((x) => x.objectId === props.id) || []}
              {...section}
            />
          ))}
    </View>
  );
};

export default PdfReportObject;
