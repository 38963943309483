import React from "react";
import ImageListItem from "@mui/material/ImageListItem";
import Picture, { PictureProps } from "./Picture";

interface PictureListItemProps  extends PictureProps {
   onClick: (pictureId: string) => void;
}

const PictureListItem = (props: PictureListItemProps) => {
  return (
    <ImageListItem cols={2} style={{ height: 80, width: 100, margin: 3 }} onClick={() => props.onClick(props.id)}>
      <Picture {...props} />
    </ImageListItem>
  );
};

export default PictureListItem;
