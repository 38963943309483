import React from "react";
import { View, Text } from "@react-pdf/renderer";
import styles from "../pdfDocumentStyles";
import { WishListParameter  } from "../../../../../interfaces/models";

const PdfDocWishList = (props: { wishList: WishListParameter[] }) => {
  return (
    <View break>
      <Text style={{ ...styles.pageTextTitleBold, ...styles.fontFamily }}>Ønskede forbedringer anslået</Text>
      <View>
        <View style={{ ...styles.row, ...styles.backgroundGrey }}>
          <View
            style={{
              flex: 1,
              flexBasis: "20%",
            }}
          >
            <View style={styles.padding}>
              <Text style={{ ...styles.pageText, ...styles.boldTextOnly, ...styles.fontFamily }}>Forbedringer</Text>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
            }}
          >
            <View style={styles.padding}>
              <Text style={{ ...styles.pageText, ...styles.boldTextOnly, ...styles.fontFamily }}>Antal</Text>
            </View>
          </View>
        </View>
        {props.wishList.map((x) => (
          <View style={{ ...styles.row, ...styles.padding }} key={x.id}>
            <View
              style={{
                flex: 1,
                flexBasis: "20%",
              }}
            >
              <Text style={{ ...styles.pageText, ...styles.fontFamily }}>{x.label}</Text>
            </View>

            <View
              style={{
                flex: 1,
                alignItems: "flex-end",
              }}
            >
              <Text style={{ ...styles.pageText }}>
                {x.priceEstimate.toLocaleString("da-DK", {
                  currency: "DKK",
                  style: "currency",
                })}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default PdfDocWishList;
