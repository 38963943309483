import { Path, Polygon, Svg, Text, View } from "@react-pdf/renderer";
import React from "react";
import styles from "./default/styles";
import { calculateDegrees } from "../../../common/utils";

const PdfBarometer = (props: { evaluation: number }) => {
  const { evaluation } = props;

  const rotation = evaluation === 0 ? null : calculateDegrees(evaluation);
  const currentRotation = rotation === null ? null : (170 * rotation) / 100 - 85; // '-85' cause arrow have wrong default position

  const setRotationLabel = (deg: number | null) => {
    if (deg === null) {
      return "Ikke angivet";
    } else if (-90 <= deg && deg < -60) {
      return "Perfekt";
    } else if (deg >= -60 && deg <= -30) {
      return "God";
    } else if (deg > -30 && deg <= 0) {
      return "Middel";
    } else if (deg > 0 && deg <= 30) {
      return "Dårlig";
    } else if (deg > 30 && deg <= 60) {
      return "Meget dårlig";
    } else if (deg > 60 && deg <= 90) {
      return "Ringe stand";
    } else {
      return "Ikke angivet";
    }
  };

  return (
    <>
      <View style={{ ...styles.column }}>
        <View style={{ width: 150 }}>
          <Svg viewBox="0 0 220 120">
            <Path d=" M 10 110 A 100 100 0 0 1 39 39" stroke="green" fill="none" strokeWidth="18" />
            <Path d=" M 39 39 A 100 100 0 0 1 110 10" stroke="yellow" fill="none" strokeWidth="18" />
            <Path d=" M 110 10 A 100 100 0 0 1 181 39" stroke="orange" fill="none" strokeWidth="18" />
            <Path d=" M 181 39 A 100 100 0 0 1 210 110" stroke="red" fill="none" strokeWidth="18" />
            <Path d=" M 19 109 A 9 9 0 0 1 1 109" fill="green" />
            <Path d=" M 219 109 A 9 9 0 0 1 201 109" fill="red" />
          </Svg>
          {currentRotation ? (
            <Svg
              viewBox="0 0 220 120"
              style={{
                position: "absolute",
                transformOrigin: "bottom center",
                minWidth: 130,
                transform: `rotate(${currentRotation}deg)`,
              }}
            >
              <Polygon points="110 40,105 115,115 115" fill="#979797" />
              <Path d=" M 115 114 A 5 5 0 0 1 105 114" fill="#979797" />
            </Svg>
          ) : null}
        </View>
        <View style={{ ...styles.block }}>
          <Text style={{ ...styles.normalText }}>{setRotationLabel(currentRotation)}</Text>
        </View>
        {props.evaluation ? (
          <View style={{ ...styles.block }}>
            <Text style={{ ...styles.boldText }}>Gennemsnits værdi {evaluation.toFixed(1)}</Text>
          </View>
        ) : null}
      </View>
    </>
  );
};

export default PdfBarometer;
