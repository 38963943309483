import React, { useContext, useEffect } from "react";
import { useReducer } from "react";
import { Grid, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ImageList from "@mui/material/ImageList";
import { PictureRef } from "../../interfaces/models";
import { StateAction } from "../../interfaces/frontend";
import ChangePictureButton from "../../shared/ChangePictureButton";
import PictureListItem from "../../shared/PictureListItem";
import ImageModal from "../../shared/ImageModal";
import { useReportActions, useReportState } from "../reportActions";
import { ChangeContext } from "../CreateReportPage";
import { debounce } from "lodash";
import { NIL } from "uuid";

interface ObjectDescriptionState {
  objectId: string | null;
  description: string;
  pictures: PictureRef[];
  activePic: PictureRef;
  picShown: boolean;
}

const useStyles = makeStyles(() => ({
  imageList: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
}));

const initialState: ObjectDescriptionState = {
  objectId: null,
  description: "",
  pictures: [],
  activePic: {
    featureId: "",
    id: "",
    itemId: "",
    objectId: "",
    url: "",
  },
  picShown: false,
};

const reducer = (state: ObjectDescriptionState, action: StateAction<ObjectDescriptionState>) => {
  return { ...state, [action.type]: action.payload };
};

export const ObjectDescription = (props: { objectId?: string; description?: string; disabled: boolean }) => {
  const report = useReportState();
  const reportActions = useReportActions();

  const { description } = props;
  const classes = useStyles();

  const [state, dispatch] = useReducer(reducer, initialState);

  const changeCtx = useContext(ChangeContext);

  useEffect(() => {
    dispatch({ type: "description", payload: description });
    return () => {
      //
    };
  }, [description]);

  const getPicture = (image: PictureRef) => {
    changeCtx.current++;
    reportActions.addPicture(image);
  };

  const showHousePic = (pic: PictureRef) => {
    dispatch({ type: "picShown", payload: true });
    dispatch({ type: "activePic", payload: pic });
  };

  const closePic = () => {
    dispatch({ type: "picShown", payload: false });
  };

  const removePic = () => {
    changeCtx.current++;
    dispatch({ type: "picShown", payload: false });
    reportActions.removePicture(state.activePic);
  };

  const changeStoreDescription = debounce((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (props.objectId) reportActions.changeObject(props.objectId, "description", event.target.value);
  }, 100);

  const updateDescription = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    changeCtx.current++;
    dispatch({ type: "description", payload: event.target.value });
    changeStoreDescription(event);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label="Bygning beskrivelse"
            value={state.description || ""}
            onChange={updateDescription}
            onBlur={updateDescription}
            margin="dense"
            multiline
            fullWidth
            variant={"outlined"}
            disabled={props.disabled}
          />
        </Grid>
      </Grid>
      <Grid justifyContent="flex-end" container spacing={1} alignItems="center" style={{ margin: "10px 0" }}>
        <ImageList className={classes.imageList}>
          {report.pictures
            .filter((x) => x.objectId === props.objectId && (!x.itemId || x.itemId === NIL))
            .map((pic) => (
              <PictureListItem
                key={pic.id}
                id={pic.id}
                source={pic.url || pic.data}
                width={100}
                height={100}
                onClick={() => showHousePic(pic)}
              />
            ))}
          {!props.disabled && (
            <ChangePictureButton getPicture={getPicture} objectId={props.objectId} loading={report.loadingPicture} />
          )}
        </ImageList>
      </Grid>
      <ImageModal
        shown={state.picShown}
        remove={!props.disabled && !report.loading ? removePic : undefined}
        handleClose={closePic}
        source={state.activePic ? state.activePic.url || state.activePic.data : ""}
      />
    </>
  );
};
