import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { FeatureMetadata } from "../../../../../interfaces/models";
import styles from "../pdfDocumentStyles";

const PdfCustomerData = (
  props: FeatureMetadata & {
    creationDate: Date;
  }
) => {
  return (
    <>
      <View style={{ ...styles.row, ...styles.rowSpace }}>
        <View style={{ ...styles.half }}>
          <Text style={{ ...styles.pageText }}>Kunde:</Text>
        </View>
        <View style={{ ...styles.half }}>
          <Text style={{ ...styles.pageText }}>{props.owner} {props.ownerLastName}</Text>
        </View>
      </View>
      <View style={{ ...styles.row, ...styles.rowSpace }}>
        <View style={{ ...styles.half }}>
          <Text style={{ ...styles.pageText }}>Adresse:</Text>
        </View>
        <View style={{ ...styles.half }}>
          <Text style={{ ...styles.pageText }}>{props?.address}</Text>
          <Text style={{ ...styles.pageText }}>
            {props.postcode} {props.city}
          </Text>
        </View>
      </View>
      <View style={{ ...styles.row, ...styles.rowSpace }}>
        <View style={{ ...styles.half }}>
          <Text style={{ ...styles.pageText }}>Telefonnummer:</Text>
        </View>
        <View style={{ ...styles.half }}>
          <Text style={{ ...styles.pageText }}>{props?.ownerPhone}</Text>
        </View>
      </View>
      <View style={{ ...styles.row, ...styles.rowSpace }}>
        <View style={{ ...styles.half }}>
          <Text style={{ ...styles.pageText }}>Email Adresse:</Text>
        </View>
        <View style={{ ...styles.half }}>
          <Text style={{ ...styles.pageText }}>{props?.ownerEmail}</Text>
        </View>
      </View>
    </>
  );
};

export default PdfCustomerData;
