import { useEffect, useReducer } from "react";
import { Button, Grid, TextField, styled } from "@mui/material";
import { NumericTextField } from "../../shared/FormattedFields";
import { WishListParameter } from "../../interfaces/models";

type ReportWishListItemProps = {
  item: WishListParameter;
  disabled?: boolean;
  newItem?: boolean;
  addOrUpdate: (param: { id: string; label: string; priceEstimate: number }) => void;
  remove: (id: string) => void;
};

type ReportWishListItemState = {
  name: string;
  priceNoVat: number;
  priceVat: number;
  edit: boolean;
};

const initialState: ReportWishListItemState = {
  name: "",
  priceNoVat: 0,
  priceVat: 0,
  edit: false,
};

const reducer = (state: ReportWishListItemState, action: { type: keyof ReportWishListItemState; payload: any }) => {
  return { ...state, [action.type]: action.payload };
};

const ReportWishListItem = (props: ReportWishListItemProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const changeValue = (key: keyof ReportWishListItemState, value: any, local?: boolean) => {
    dispatch({ type: key, payload: value });
  };

  const changePrice = (value: any) => {
    changeValue("priceVat", value);
    dispatch({ type: "priceNoVat", payload: value - (value - value / 1.25) });
  };

  const changePriceNoVat = (value: any) => {
    dispatch({ type: "priceNoVat", payload: value });
    changeValue("priceVat", value + value * 0.25);
  };

  useEffect(() => {
    if (state.priceVat) {
      dispatch({
        type: "priceNoVat",
        payload: state.priceVat - (state.priceVat - state.priceVat / 1.25),
      });
    }
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.priceVat]);

  useEffect(() => {
    if (props.newItem) {
      dispatch({ type: "edit", payload: true });
    }
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.newItem]);

  useEffect(() => {
    if (props.item.id) {
      dispatch({ type: "name", payload: props.item.label });
      dispatch({ type: "priceVat", payload: props.item.priceEstimate });
    }
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item]);

  const toggleEdit = () => {
    dispatch({ type: "edit", payload: !state.edit });
  };

  const applyChanges = () => {
    props.addOrUpdate({
      id: props.item.id,
      label: state.name,
      priceEstimate: state.priceVat,
    });
    dispatch({ type: "edit", payload: false });
  };

  const clearChanges = () => {
    if (!props.newItem) {
      dispatch({ type: "name", payload: props.item.label });
      dispatch({ type: "priceVat", payload: props.item.priceEstimate });
    }
    dispatch({ type: "edit", payload: false });
    if (props.newItem) {
      props.remove("");
    }
  };

  return (
    <Grid item container spacing={1}>
      <Grid item md={5} xs={12}>
        <TextField
          label="Forbedringer"
          value={state.name || props.item.label}
          onChange={(event) => changeValue("name", event.target.value)}
          fullWidth
          disabled={props.disabled || !state.edit}
        />
      </Grid>
      <Grid item sx={{ flex: 1 }} md={2}>
        <NumericTextField
          suffix=" Kr"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fullWidth
          label="Pris overslag ekskl. moms"
          value={state.priceNoVat > 0 ? state.priceNoVat : ""}
          onValueChange={(values) => {
            changePriceNoVat(values.floatValue);
          }}
        />
      </Grid>
      <Grid item sx={{ flex: 1 }} md={2}>
        <NumericTextField
          suffix=" Kr"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fullWidth
          label="Pris overslag inkl. moms"
          value={state.priceVat > 0 ? state.priceVat : ""}
          onValueChange={(values) => {
            changePrice(values.floatValue);
          }}
          disabled={props.disabled || !state.edit}
        />
      </Grid>
      {!props.disabled && (
        <Grid
          sx={{ flex: 1, flexWrap: "nowrap" }}
          item
          container
          justifyContent="flex-end"
          alignItems="center"
          md={3}
          sm={4}
        >
          {state.edit ? (
            <>
              <StyledButton color="secondary" variant="outlined" sx={{ marginRight: 1 }} onClick={applyChanges}>
                Gem
              </StyledButton>
              <StyledButton color="secondary" variant="outlined" onClick={clearChanges}>
                Nulstil
              </StyledButton>
            </>
          ) : (
            <>
              <StyledButton color="secondary" variant="outlined" sx={{ marginRight: 1 }} onClick={toggleEdit}>
                Rediger
              </StyledButton>
              <StyledButton color="secondary" variant="outlined" onClick={() => props.remove(props.item.id)}>
                Slet{" "}
              </StyledButton>
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ReportWishListItem;

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    minWidth: 75,
    maxWidth: 75,
  },
  maxWidth: 80,
}));
