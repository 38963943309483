import React from "react";
import { AppBar, Toolbar } from "@mui/material";

const InspectorBottomBar = (props: { children: React.ReactNode }) => {
  return (
    <AppBar position="fixed" color="default" sx={{ top: "auto", bottom: 0, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar disableGutters>{props.children}</Toolbar>
    </AppBar>
  );
};

export default InspectorBottomBar;
