import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Container,
} from "@mui/material";
import { Rating } from '@mui/material';
import { useUser } from "../common/hooks";
import AppToolbar from "../shared/AppToolbar";
import { RatingValue } from "../interfaces/models";
import { useUserActions } from "../user/userActions";
import StarIcon from "@mui/icons-material/Star";
import PersonIcon from "@mui/icons-material/Person";
import { formatRelative } from "date-fns";
import daLocale from "date-fns/locale/da";
import BorderLinearProgress from "../shared/BorderLinearProgress";


const TAKE = 10;

const RatingPage = () => {
  const user = useUser();
  const actions = useUserActions();
  const [skip, setSkip] = useState(0);

  const getVotePercentage = (group: string) => {
    if (user && user.rating && user.rating.valueGroups) {
      const vote = user?.rating?.valueGroups[group];

      if (!vote) return 0;
      return user?.rating.totalCount ? Math.floor((vote * 100) / user?.rating.totalCount) : 0;
    }
    return 0;
  };

  useEffect(() => {
    if (!user.rating) {
      actions.getRating();
      actions.getRatingValues(skip, TAKE);
    }
    return () => {
      //
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.rating]);

  useEffect(() => {
    setSkip(user.ratingValues.length);
    return () => {
      setSkip(0);
    };
  }, [user.ratingValues.length]);

  const loadMore = () => {
    actions.getRatingValues(skip, TAKE);
  };

  return (
    <Container>
      <Grid
        container
        item
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <AppToolbar />
        <Grid item xs={12} container justifyContent="space-around">
          <Typography variant="h3">Din rating </Typography>
          <Grid item>
            <Typography variant="h2">{user.rating?.totalRating}</Typography>
            <Typography variant="body1">({user?.rating?.totalCount || 0} stemmer)</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <RatingBar
              category="5"
              value={user.rating?.valueGroups ? user.rating.valueGroups["5"] : 0}
              percentage={getVotePercentage("5")}
            />
            <RatingBar
              category="4"
              value={user.rating?.valueGroups ? user.rating.valueGroups["4"] : 0}
              percentage={getVotePercentage("4")}
            />
            <RatingBar
              category="3"
              value={user.rating?.valueGroups ? user.rating.valueGroups["3"] : 0}
              percentage={getVotePercentage("3")}
            />
            <RatingBar
              category="2"
              value={user.rating?.valueGroups ? user.rating.valueGroups["2"] : 0}
              percentage={getVotePercentage("2")}
            />
            <RatingBar
              category="1"
              value={user.rating?.valueGroups ? user.rating.valueGroups["1"] : 0}
              percentage={getVotePercentage("1")}
            />
          </Grid>
          <Grid item xs={12}>
            {user.ratingValues.map((x: RatingValue) => (
              <Card key={x.id} square>
                <CardContent>
                  <Grid container justifyContent="space-between">
                    <Typography variant="caption" paragraph>
                      {x.voterName} ({x.voterEmail})
                    </Typography>
                    <Typography variant="caption" paragraph>
                      {formatRelative(new Date(x.created), new Date(), { locale: daLocale })}
                    </Typography>
                  </Grid>
                  <Typography variant="body1" paragraph>
                    {x.comment}
                  </Typography>
                  <Rating name="read-only" value={x.value} readOnly />
                </CardContent>
              </Card>
            ))}
            {!!user.ratingValues.length && <Button onClick={loadMore}>Læs mere</Button>}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RatingPage;

const RatingBar = (props: { category: string; value: number; percentage: number }) => (
  <>
    <Grid item xs={12} container direction="column">
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item>
          <span>
            {props.category}
            <StarIcon fontSize="small" color="primary" />
          </span>
        </Grid>
        <Grid item>
          <span>
            {props.value}
            <PersonIcon fontSize="small" color="primary" />
          </span>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <BorderLinearProgress variant="determinate" value={props.percentage || 0} />
    </Grid>
  </>
);
