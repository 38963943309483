import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import styles from "../pdfDocumentStyles";

const PdfDocHeader = () => {
  return (
    <>
      <View fixed style={styles.footerWrapper}>
        <View style={styles.footer}>
          <View
            style={{
              flex: 1,
              flexBasis: "7%",
              marginLeft: 5
            }}
          >
            <Text style={{...styles.footerTextBold, ...styles.fontFamily}}>Kontakt info</Text>
            <Text style={styles.footerText}>kontakt@byggesagkyndig.nu</Text>
            <Text style={styles.footerText}>www.byggesagkyndig.nu</Text>
          </View>

          <View
            style={{
              flex: 1,
              flexBasis: "2%",
            }}
          >
            <Text style={{...styles.footerTextBold, ...styles.fontFamily}}>Åbningstider</Text>
            <Text style={styles.footerText}>Mandag til fredag</Text>
            <Text style={styles.footerText}>Kl. 08:00 - 16:00</Text>
          </View>

          <View
            style={{
              flex: 1,
            }}
          >
            <Text style={{...styles.footerTextBold, ...styles.fontFamily}}>Telefon</Text>
            <Text style={styles.footerText}>70 202 102</Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text style={{...styles.footerTextBold, ...styles.fontFamily}}>Hovedkontor</Text>
            <Text style={styles.footerText}>Lægårdsvej 14</Text>
            <Text style={styles.footerText}>8520 Lystrup</Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text style={{...styles.footerTextBold, ...styles.fontFamily}}>Kontor Sjælland</Text>
            <Text style={styles.footerText}>Lyskær 8</Text>
            <Text style={styles.footerText}>2730 Herlev</Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text style={{...styles.footerTextBold, ...styles.fontFamily}}> Kontor Fyn</Text>
            <Text style={styles.footerText}>Sivmosevænget 2L</Text>
            <Text style={styles.footerText}>5260 Odense</Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text style={{...styles.footerTextBold, ...styles.fontFamily}}>Kontor Kolding</Text>
            <Text style={styles.footerText}>Olaf Ryes Gade 7R</Text>
            <Text style={styles.footerText}>6000 Kolding</Text>
          </View>
        </View>

        <View style={styles.footerPageNumber}>
          <Text render={({ pageNumber, totalPages }) => `Side ${pageNumber} af ${totalPages}`} fixed></Text>
        </View>
      </View>

      <View fixed={true} style={styles.footerImageWrapper}>
        <Image style={styles.footerImage} src={require("../img/pdfFooter.png")} />
      </View>
    </>
  );
};

export default PdfDocHeader;
