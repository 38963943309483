import React from "react";
import { View, Text } from "@react-pdf/renderer";
import styles from "../pdfDocumentStyles";
import { TimeToFinish } from "../../../../../interfaces/models";
import { MaintenanceInfo } from "../../../../../interfaces/frontend";

type PdfDocReportMaintenanceInfoProps = {
  maintenanceInfoList: MaintenanceInfo[];
};

const PdfDocReportMaintenanceInfo = (props: PdfDocReportMaintenanceInfoProps) => {
  const sumByTimeToFinish = {
    [TimeToFinish.Now]: props.maintenanceInfoList.reduce((sum, item) => {
      return sum + item[1];
    }, 0),
    [TimeToFinish.OneToThreeYears]: props.maintenanceInfoList.reduce((sum, item) => {
      return sum + item[2];
    }, 0),
    [TimeToFinish.ThreeToFiveYears]: props.maintenanceInfoList.reduce((sum, item) => {
      return sum + item[3];
    }, 0),
  };

  return (
    <View>
      <Text style={{ ...styles.pageTextTitleBold, ...styles.fontFamily }}>Opsummering budget:</Text>
      <View>
        {/* PAGE BUDGET--TITLE PART */}
        <View style={{ ...styles.row, ...styles.backgroundGrey }}>
          <View
            style={{
              flex: 1,
              flexBasis: "40%",
            }}
          >
            <View style={styles.padding}>
              <Text style={{ ...styles.pageText, ...styles.boldTextOnly, ...styles.fontFamily }}>Område</Text>
              <Text style={{ ...styles.pageTextFirstLineBold }}></Text>
              <Text style={{ ...styles.pageText }}></Text>
            </View>
          </View>

          <View
            style={{
              flex: 1,
              flexBasis: "60%",
              alignItems: "center",
            }}
          >
            <Text style={{ ...styles.pageText, ...styles.boldTextOnly, ...styles.fontFamily }}>
              Vurderet udbedringstidspunkt
            </Text>

            <View style={styles.row}>
              <View
                style={{
                  flexBasis: "33%",
                  alignItems: "flex-end",
                }}
              >
                <Text style={{ ...styles.pageText }}>Nu</Text>
              </View>

              <View
                style={{
                  flexBasis: "33%",
                  alignItems: "flex-end",
                }}
              >
                <Text style={{ ...styles.pageText }}> 1-3 år</Text>
              </View>

              <View
                style={{
                  flexBasis: "33%",
                  alignItems: "flex-end",
                }}
              >
                <Text style={{ ...styles.pageText }}>3-5 år</Text>
              </View>
            </View>
          </View>
        </View>

        {/*DATA PART */}

        {props.maintenanceInfoList?.length
          ? props.maintenanceInfoList.map((x, i, array) => {
              return (
                <View key={x.sectionId + i} style={{ ...styles.padding }}>
                  {/* Shows name of object if report has them more than one */}
                  {array.length > 1 && x.objectId !== (i > 0 ? array[i - 1].objectId : "") && (
                    <View>
                      <Text style={{ ...styles.pageText, ...styles.boldTextOnly, ...styles.fontFamily }}>
                        {x.objectName}
                      </Text>
                    </View>
                  )}

                  <View style={{ ...styles.row }}>
                    <View
                      style={{
                        flex: 1,
                        flexBasis: "40%",
                      }}
                    >
                      <Text style={{ ...styles.pageText }}>{x.sectionName}</Text>
                    </View>

                    <View
                      style={{
                        flex: 1,
                        flexBasis: "60%",
                        alignItems: "center",
                      }}
                    >
                      <View style={styles.row}>
                        <View
                          style={{
                            flexBasis: "33%",
                            alignItems: "flex-end",
                          }}
                        >
                          <Text style={{ ...styles.pageText }}>{x[1].toLocaleString("da")} kr.</Text>
                        </View>

                        <View
                          style={{
                            flexBasis: "33%",
                            alignItems: "flex-end",
                          }}
                        >
                          <Text style={{ ...styles.pageText }}>{x[2].toLocaleString("da")} kr.</Text>
                        </View>

                        <View
                          style={{
                            flexBasis: "33%",
                            alignItems: "flex-end",
                          }}
                        >
                          <Text style={{ ...styles.pageText }}>{x[3].toLocaleString("da")} kr.</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              );
            })
          : null}
      </View>

      {/* SUMMING PART */}

      <View style={{ ...styles.borderTop, ...styles.boldTextOnly }}>
        <View style={{ ...styles.row, ...styles.padding }}>
          <View
            style={{
              flex: 1,
              flexBasis: "40%",
            }}
          >
            <Text style={{ ...styles.pageText, ...styles.boldTextOnly, ...styles.fontFamily }}>Prisoverslag i alt</Text>
          </View>

          <View
            style={{
              flex: 1,
              flexBasis: "60%",
              alignItems: "center",
            }}
          >
            <View style={styles.row}>
              <View
                style={{
                  flexBasis: "33%",
                  alignItems: "flex-end",
                }}
              >
                <Text style={{ ...styles.pageText }}>{sumByTimeToFinish[1].toLocaleString("da")} kr.</Text>
              </View>

              <View
                style={{
                  flexBasis: "33%",
                  alignItems: "flex-end",
                }}
              >
                <Text style={{ ...styles.pageText }}>{sumByTimeToFinish[2].toLocaleString("da")} kr.</Text>
              </View>

              <View
                style={{
                  flexBasis: "33%",
                  alignItems: "flex-end",
                }}
              >
                <Text style={{ ...styles.pageText }}>{sumByTimeToFinish[3].toLocaleString("da")} kr.</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PdfDocReportMaintenanceInfo;
