import { Dictionary } from "lodash";
import { getLocalData } from "../common/utils";
import { LocalStorageItems } from "../constants/appConstants";
import { UserState } from "../user/userReducer";

const API_URL = process.env.REACT_APP_API_URL;

const headers = async (overrideHeaders?: Dictionary<string> | undefined) => {
  const headers = new Headers([["Content-Type", "application/json"]]);

  const token = await getLocalAuthToken();
  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }
  headers.append("ClientName", process.env.REACT_APP_NAME || "");
  headers.append("ClientVersion", process.env.REACT_APP_VERSION + "." + process.env.REACT_APP_BUILD_NUMBER);

  if (overrideHeaders) {
    Object.keys(overrideHeaders).forEach((key) => {
      headers.append(key, overrideHeaders[key]);
    });
  }
  return headers;
};

const parseUrl = (url: string) => {
  if (url.search(/^http[s]?:\/\//) !== -1) {
    return url;
  }

  if (url.search(/^\//) !== -1) {
    return API_URL + url;
  }
  return API_URL + "/" + url;
};

const ApiService = {
  post: async <T, P = any>(
    url: string,
    item?: P | FormData,
    overrideHeaders?: Dictionary<string> | undefined,
    signal?: AbortSignal | null
  ): Promise<T> => {
    const request: RequestInit = {
      method: "POST",
      mode: "cors",
      headers: await headers(overrideHeaders),
      body: item ? JSON.stringify(item) : undefined,
      signal,
    };

    return await apiRequest(parseUrl(url), request);
  },

  postFormData: async <T>(url: string, item: FormData, signal?: AbortSignal | null): Promise<T> => {
    const token = await getLocalAuthToken();
    const headers = new Headers([["Authorization", `Bearer ${token}`]]);

    const request: RequestInit = {
      method: "POST",
      mode: "cors",
      headers: headers,
      body: item,
      signal,
    };
    return await apiRequest(API_URL + url, request);
  },

  put: async <T, P = any>(
    url: string,
    item: P,
    overrideHeaders?: Dictionary<string> | undefined,
    signal?: AbortSignal | null
  ): Promise<T> => {
    return await apiRequest(parseUrl(url), {
      method: "PUT",
      mode: "cors",
      headers: await headers(overrideHeaders),
      body: JSON.stringify(item),
      signal,
    });
  },

  get: async <T>(
    url: string,
    overrideHeaders?: Dictionary<string> | undefined,
    signal?: AbortSignal | null
  ): Promise<T> => {
    return await apiRequest(parseUrl(url), {
      method: "GET",
      mode: "cors",
      headers: await headers(overrideHeaders),
      signal,
    });
  },

  getBlobResources: async (
    url: string,
    overrideHeaders?: Dictionary<string> | undefined,
    signal?: AbortSignal | null
  ): Promise<Blob | null> => {
    try {
      const response = await fetch(parseUrl(url), {
        method: "GET",
        mode: "cors",
        headers: await headers(overrideHeaders),
        signal,
      });

      if (!response.ok || response.status === 204) {
        const message = response.ok ? "File not found" : `Failed to fetch resource: ${response.statusText}`;
        throw new Error(`Failed to fetch resource: ${message}`);
      }
      return response.blob();
    } catch (error) {
      console.warn("Error fetching Blob data:", error);
      return null;
    }
  },

  delete: async <T, P = any>(
    url: string,
    item?: P,
    overrideHeaders?: Dictionary<string> | undefined,
    signal?: AbortSignal | null
  ): Promise<T> => {
    return await apiRequest(parseUrl(url), {
      method: "DELETE",
      mode: "cors",
      headers: await headers(overrideHeaders),
      body: JSON.stringify(item),
      signal,
    });
  },
};

const apiRequest = async <T>(url: string, config: RequestInit): Promise<T> => {
  const response = await fetch(parseUrl(url), config);
  if (response.status !== 200 && response.status !== 204) {
    let statusText = "";
    try {
      statusText = await response.text();
    } catch {
      //do not throw an exception
    }

    const error = { status: response.status, statusText: statusText };
    throw error;
  }
  if (response.status === 204) return Promise.resolve({} as T);
  const result = await response.json();
  return result;
};

const getLocalAuthToken = async () => {
  //let localUser = localStorage.getItem("ServiceBook#user");
  const localUser = await getLocalData<UserState>(LocalStorageItems.User);
  if (localUser) {
    //let user = JSON.parse(localUser);
    //return user.accessToken;
    return localUser.accessToken;
  }
  return "";
};

export default ApiService;
