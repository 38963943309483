import React from "react";
import { Snackbar, SnackbarContent, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: "green",
  },
  warning: {
    backgroundColor: "yellow",
  },
  error: {
    backgroundColor: "red",
  },
  info: {},
}));

export type NotificationType = "success" | "warning" | "error";

const NotificationBar = (props: {
  variant: NotificationType;
  open: boolean;
  message?: string;
  handleClose: () => void;
}) => {
  const { open, message, handleClose } = props;
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      key={`bottom-center`}
      autoHideDuration={5000}
      open={open}
      onClose={handleClose}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
    >
      <SnackbarContent
        className={classes[props.variant] || classes.info}
        message={<Typography variant="body1" id="message-id">{message}</Typography>}
      />
    </Snackbar>
  );
};

export default NotificationBar;