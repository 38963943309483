import { Grid, TextField, ImageList, useTheme, styled, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ParameterDescription, PictureRef } from "../../interfaces/models";
import ChangePictureButton from "../../shared/ChangePictureButton";
import PictureListItem from "../../shared/PictureListItem";
import { ChangeContext } from "../CreateReportPage";
import { useReportState } from "../reportActions";

interface ReportItemDescriptionProps extends ParameterDescription {
  disabled: boolean;
  showPicture: (pic: PictureRef) => void;
  addPicture: (pic: PictureRef) => void;
  changeDescription: (value: string) => void;
  objectId: string;
  itemId: string;
}

const ReportItemDescription = (props: ReportItemDescriptionProps) => {
  const [description, setDescription] = useState("");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const changeCtx = useContext(ChangeContext);
  const report = useReportState();

  useEffect(() => {
    setDescription(props.description || "");

    return () => {
      setDescription("");
    };
  }, [props.description]);

  const changeDescription = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    changeCtx.current++;
    setDescription(event.currentTarget.value);
    props.changeDescription(event.currentTarget.value);
  };

  const addPicture = (picture: PictureRef) => {
    changeCtx.current++;
    props.addPicture(picture);
  };

  return (
    <Grid container item spacing={1} style={{ marginTop: 0 }}>
      <Grid container item style={{ flex: 1 }}>
        <TextField
          label="Beskriv problemstillingen (foto)"
          value={description}
          onChange={changeDescription}
          margin="dense"
          multiline
          minRows={2}
          fullWidth
          variant={"outlined"}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={!!isXs && 12}>
        <StyledImageList>
          {!!props.pictures?.length &&
            props.pictures.map((pic) => (
              <PictureListItem
                key={pic.id}
                id={pic.id}
                source={pic.url || pic.data}
                width={100}
                height={100}
                onClick={() => props.showPicture(pic)}
              />
            ))}
          {!(props.disabled || props.pictures?.length) && (
            <ChangePictureButton
              getPicture={addPicture}
              objectId={props.objectId}
              itemId={props.itemId}
              loading={report.loadingPicture}
            />
          )}
        </StyledImageList>
      </Grid>
    </Grid>
  );
};

const StyledImageList = styled(ImageList)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 5px 0;
`;

export default ReportItemDescription;
