import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import ReportSection from "./ReportSection";
import { ObjectDescription } from "./ObjectDescription";
import { InspectorReportObject, TemplateItem, TemplateSection } from "../../interfaces/models";
import { useReportState } from "../reportActions";
import { getPropertyTypeDisplayString } from "../../constants/appConstants";

export interface ReportSectionType extends TemplateSection {
  items: TemplateItem[];
}

const ReportObject = (
  props: InspectorReportObject & {
    refs: { [key: string]: { [key: string]: HTMLDivElement | null } };
    showInfo: boolean;
    isReadOnly?: boolean;
  }
) => {
  const report = useReportState();

  const sections = props.template ? props.template.sections : report.template.sections;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid>
        <Typography variant="h6">
          {props.label}.&nbsp;{props.name}
        </Typography>
      </Grid>
      {report.data.address && props.showInfo && (
        <Grid
          container
          sx={{
            border: "2px solid grey",
            borderRadius: "4px",
            marginBottom: "20px",
            marginTop: "20px",
            padding: "10px",
            boxSizing: "border-box",
          }}
        >
          {report.data.unitType && (
            <Grid item xs={3}>
              <Typography variant="body1" align="center">
                Type
              </Typography>
              <Typography variant="subtitle1" align="center">
                {getPropertyTypeDisplayString(report.data.propertyType)}
              </Typography>
            </Grid>
          )}
          {!!report.data.rooms && (
            <Grid item xs={3}>
              <Typography variant="body1" align="center">
                Rum
              </Typography>
              <Typography variant="subtitle1" align="center">
                {report.data.rooms}
              </Typography>
            </Grid>
          )}
          {!!report.data.area && (
            <Grid item xs={3}>
              <Typography variant="body1" align="center">
                Areal
              </Typography>
              <Typography variant="subtitle1" align="center">
                {report.data.area}
              </Typography>
            </Grid>
          )}
          {!!report.data.buildYear && (
            <Grid item xs={3}>
              <Typography variant="body1" align="center">
                Byggeår
              </Typography>
              <Typography variant="subtitle1" align="center">
                {report.data.buildYear}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
      <ObjectDescription objectId={props.id} description={props.description} disabled={!!props.isReadOnly} />
      {sections?.length &&
        sections.map((item) => {
          return (
            <ReportSection
              key={props.id + item.id}
              ref={(ref) =>
                props.refs
                  ? props.refs[item.id]
                    ? (props.refs[item.id][props.id] = ref)
                    : (props.refs[item.id] = { [props.id]: ref })
                  : null
              }
              objectId={props.id}
              {...item}
              evaluation={Object.hasOwn(props.sectionEvaluations, item.id) ? props.sectionEvaluations[item.id] : 0}
              isReadOnly={props.isReadOnly}
            />
          );
        })}
    </Grid>
  );
};

export default React.memo(ReportObject);
