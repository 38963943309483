import React from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    info: {
      marginTop: 30,
     backgroundColor: theme.palette.divider,
      padding: "30px 0",
      "& p": {
        textAlign: "center"
      },
      [theme.breakpoints.down('sm')]: {
        padding: "25px 0",
      },
    },
  })
);


const Footer = () => {
const classes = useStyles();

  return (
    <Grid item container className={classes.info} justifyContent="center">
      <Grid container item justifyContent="center" direction="column" xs={10}>
        <Typography>myhouse</Typography>
        <Typography>Fredensborg Kongevej 56, 2980 Kokkedal</Typography>
        <Typography>cvr. 35056823</Typography>
        <Typography>e-mail: info@myhouse.dk</Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
