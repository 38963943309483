import { Common, Report, User } from "../constants/actionTypes";
import { LocalStorageItems } from "../constants/appConstants";
import ReportService from "../services/ReportService";
import { getLocalData, makeId, removeLocalData, updateLocalData } from "./utils";
import { AppError } from "../interfaces/frontend";
import { AppState } from "../store/store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useAppDispatch } from "./hooks";
import { AddressFilter } from "../interfaces/models";
import { useSelector } from "react-redux";
import { NIL } from "uuid";

const switchPreviewMode = () => (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: Common.SWITCH_PDF_MODE });
  dispatch({ type: Report.NEED_UPDATE });
};

const getReportList =
  (
    filter: string,
    skip: number,
    take: number,
    noHistory?: boolean,
    addressFilter?: AddressFilter,
    allStates?: boolean,
    personalOnly?: boolean
  ) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({ type: Common.GET_LIST });
    try {
      const result = await ReportService.getReportList(
        filter,
        skip,
        take,
        noHistory,
        addressFilter,
        allStates,
        personalOnly
      );
      dispatch({ type: Common.CLEAR_REPORT_LIST });
      dispatch({ type: Common.GET_REPORT_LIST_SUCCEEDED, payload: result });
      return result;
    } catch (error: unknown) {
      dispatch({
        type: Common.GOT_ERROR,
        payload: error,
        error: true,
      });
    }
  };

const clearReportList = () => (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: Common.CLEAR_REPORT_LIST });
};

const getGroupedReportList =
  (filter: string, skip: number, take: number, noHistory?: boolean) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({ type: Common.GET_LIST });
    try {
      const result = await ReportService.getGroupedReportList(filter, skip, take);
      dispatch({
        type: Common.GET_GROUPED_REPORT_LIST_SUCCEEDED,
        payload: result,
      });
    } catch (error: unknown) {
      dispatch({
        type: Common.GOT_ERROR,
        payload: error,
        error: true,
      });
    }
  };

const clearError = () => (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: Common.CLEAR_ERROR });
};

const closePdfView = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
  dispatch({ type: Common.SWITCH_PDF_MODE, payload: false });
  await removeLocalData(LocalStorageItems.Common);
};

const openPdfView = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
  const state = getState().report;
  if (!state.data?.houseId || state.data.houseId === NIL) {
    handleError({ title: "Indtast adresse" }, dispatch);
    return;
  }

  if (!state.parameters.length) {
    handleError({ title: "Rapporten er tom" }, dispatch);
    return;
  }
  dispatch({ type: Common.SWITCH_PDF_MODE, payload: true });
  const { ...common } = getState().common;

  //do not save pdfView status
  const commonState = { ...common, pdfView: false };
  await updateLocalData(LocalStorageItems.Common, commonState);
};

export const handleError = (
  error: AppError | string,
  dispatch: ThunkDispatch<AppState, any, AnyAction>,
  action?: any
) => {
  if (typeof error === "string") {
    dispatch({
      type: Common.GOT_ERROR,
      payload: { title: "Fejl", message: error },
    });
    return;
  }
  if (error.status) {
    switch (error.status) {
      case 401:
        dispatch({ type: User.CLEAR_USER_DATA });
        //TODO: handle promise or change to async
        removeLocalData(LocalStorageItems.User).then(() => {
          if (action) {
            dispatch(action);
          }
        });
        return;
      default:
        dispatch({
          type: Common.GOT_ERROR,
          payload: { message: error.statusText },
        });
        if (action) {
          dispatch(action);
        }
        return;
    }
  }

  if (error.message === "Failed to fetch") {
    error.message = "Kunne ikke oprette forbindelse til serveren";
  }
  dispatch({ type: Common.GOT_ERROR, payload: error });
  if (action) {
    dispatch(action);
  }
};

const setup = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    let deviceId = await getLocalData(LocalStorageItems.DeviceId);
    if (!deviceId) {
      deviceId = makeId(10);
      await updateLocalData(LocalStorageItems.DeviceId, deviceId);
    }
    dispatch({ type: Common.SETUP, payload: deviceId });
  } catch (error: any) {
    handleError(error as string | AppError, dispatch, {
      type: Common.SETUP_FAILED,
    });
  }
};

// const applyFilter = (filter: string, ) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
//   dispatch({ type: Common.APPLY_FILTER });
//   try {
//     let result = await ReportService.getReportList(filter);
//     dispatch({ type: Common.APPLY_FILTER_SUCCEEDED, payload: result });
//   } catch (error: any) {
//     handleError(error as string | AppError, dispatch, {
//       type: Common.APPLY_FILTER_FAILED,
//     });
//   }
// };

export const useCommonState = () => useSelector((state: AppState) => state.common);

export const useCommonActions = () => {
  const dispatch = useAppDispatch();

  return {
    switchPreviewMode: () => dispatch(switchPreviewMode()),
    getReportList: (
      filter: string,
      skip: number,
      take: number,
      noHistory?: boolean,
      addressFilter?: AddressFilter,
      allStates?: boolean,
      personalOnly?: boolean
    ) => dispatch(getReportList(filter, skip, take, noHistory, addressFilter, allStates, personalOnly)),
    getGroupedReportList: (filter: string, skip: number, take: number, noHistory?: boolean) =>
      dispatch(getGroupedReportList(filter, skip, take, noHistory)),
    // applyFilter: (filter: string) => dispatch(applyFilter(filter)),
    openPdfView: () => dispatch(openPdfView()),
    closePdfView: () => dispatch(closePdfView()),
    clearError: () => dispatch(clearError()),
    clearReportList: () => dispatch(clearReportList()),
    setup: () => dispatch(setup()),
  };
};

export const useCommon = (): [ReturnType<typeof useCommonState>, ReturnType<typeof useCommonActions>] => {
  const state = useCommonState();
  const actions = useCommonActions();
  return [state, actions];
};
