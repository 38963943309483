import { CraftsmanType } from "../interfaces/models";

export const drawerWidth = window.screen.width * 0.33;
export const EmailRegExp = /^[A-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const PhoneRegexp =
  // eslint-disable-next-line no-useless-escape
  /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d*)\)?)[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?)+)(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;

  export const PasswordRegExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;

export enum LocalStorageItems {
  User = "user",
  Report = "report",
  Template = "template",
  Common = "Common",
  DeviceId = "deviceId",
}

export const CraftsmanTypes: { [idx: string | number]: string } = {
  [CraftsmanType.None]: "Ingen",
  [CraftsmanType.Electrician]: "Elektriker",
  [CraftsmanType.Glazier]: "Glarmester",
  [CraftsmanType.Painter]: "Maler",
  [CraftsmanType.Builder]: "Murer",
  [CraftsmanType.Carpenter]: "Tømrer",
  [CraftsmanType.Woodworker]: "Snedker",
  [CraftsmanType.Heating]: "VVS",
  [CraftsmanType.Blacksmith]: "Smed",
  [CraftsmanType.Sewerage]: "Kloakmester",
  [CraftsmanType.LandscapeGardener]: "Anlægsgartner",
  [CraftsmanType.Demolition]: "Nedrivning",
  [CraftsmanType.Plumber]: "Blikkenslager",
  [CraftsmanType.GroundAndBeton]: "Jord og Beton",
  [CraftsmanType.Architect]: "Arkitekt",
  [CraftsmanType.Engineer]: "Ingeniør",
  [CraftsmanType.Scaffolding]: "Stillads",
  [CraftsmanType.ConstructionConsultant]: "Byggesagkyndig",
  [CraftsmanType.Other]: "Andet",
  [CraftsmanType.HouseOwner]: "Husejer",
};

export const TimeEstimate = [
  { id: 0, value: "Ingen" },
  { id: 1, value: "Nu" },
  { id: 2, value: "1-3 år" },
  { id: 3, value: "3-5 år" },
];

export const getPropertyTypeDisplayString = (propertyType: string | undefined) => {
  switch (propertyType?.toLowerCase()) {
    case "cooperative":
      return "Andelsbolig";
    case "villaapartment":
    case "apartment":
      return "Lejlighed";
    case "countryhouse":
      return "Landejendom";
    case "fullyearground":
      return "Helårsgrund";
    case "holidayground":
    case "holidayhouse":
      return "Fritidshus";
    case "townhouse":
      return "Rækkehus";
    case "villa":
      return "Villa";
    case "summerhouse":
      return "Sommerhus";
    case "rented":
      return "Lejet";
    case "holidayapartment":
      return "Ferielejlighed";
    case "commercialwholesale":
      return "Engroshandel";
    case "commercialwarehouse":
      return "Enhed til lager";
    case "commercialoffice":
      return "Enhed til kontor";
    case "commercialshoppingcenter":
      return "Butikscenter";
    case "commercialgasstation":
      return "Tankstation";
    case "commercialservice":
      return "Detailhandel";
    default:
      return "Anden boligtype";
  }
};
