import React from "react";
import HouseIcon from "@mui/icons-material/House";
import StarIcon from "@mui/icons-material/Star";
import BuildIcon from "@mui/icons-material/Build";
import SettingsIcon from "@mui/icons-material/Settings";
import SummarizeIcon from '@mui/icons-material/Summarize';
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import TocIcon from "@mui/icons-material/Toc";
import AndroidIcon from "@mui/icons-material/Android";
import AppsIcon from "@mui/icons-material/Apps";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PeopleIcon from "@mui/icons-material/People";
import { UserRole } from "../../interfaces/models";
import { Dictionary } from "../../interfaces/frontend";
import { Badges } from "../../common/hooks";

export type MenuBlockType = {
  id: number;
  title: string;
  icon: React.ReactNode;
  text: string;
  path: string;
  badgeName?: Badges;
  bgColor?: string;
};

const MenuBlocks: Dictionary<MenuBlockType[]> = {
  login: [
    { id: 1, path: "/inspector/login", title: "", icon: <FindInPageIcon color="primary" />, text: "Bygge inspektører" },
    { id: 2, path: "/craftsman/login", title: "", icon: <HomeWorkIcon color="primary" />, text: "Handy Tool" },
    // {
    //   id: 3,
    //   path: "/achitect/login",
    //   title: "",
    //   icon: <ApartmentIcon color="primary" />,
    //   text: "Arkitekter og ingeniører",
    // },
    {
      id: 4,
      path: process.env.REACT_APP_CLIMATE_PLAN_STATS_URL || "",
      title: "",
      icon: <LocationCityIcon color="primary" />,
      text: "Kommuner",
    },
    {
      id: 5,
      path: process.env.REACT_APP_MYHOUSE_URL + "/partner/login",
      title: "",
      icon: <TocIcon color="primary" />,
      text: "Content og data partnere",
    },
    {
      id: 6,
      path: process.env.REACT_APP_MYHOUSE_URL + "/botpartner/login",
      title: "",
      icon: <AndroidIcon color="primary" />,
      text: "BOT partnere",
    },
    {
      id: 7,
      path: process.env.REACT_APP_MYHOUSE_URL + "/exhibition/login",
      title: "",
      icon: <AppsIcon color="primary" />,
      text: "Messer",
    },
    // {
    //   id: 8,
    //   path: process.env.REACT_APP_MYHOUSE_URL + "/login",
    //   title: "",
    //   icon: <HiOutlineCube style={{ fontSize: 24 }} color="primary" />,
    //   text: "3D inde og ude",
    // },
  ],
  [UserRole.Inspector]: [
    {
      id: 1,
      title: "Eftersyn",
      icon: <BuildIcon color="primary" />,
      text: "Her laver du dine rapporter.",
      path: "/inspector/report/create",
      bgColor: "#00ff1d1f",
    },
    {
      id: 2,
      title: "Ikke godkendt rapporter",
      icon: <HouseIcon color="primary" />,
      text: "Her finder du en oversigt over dine ikke godkendt rapporter.",
      path: "/inspector/report/edit",
      bgColor: "#00ff1d1f",
    },
    {
      id: 3,
      title: "Mine kunder og rapporter",
      icon: <HouseIcon color="primary" />,
      text: "Her finder du en oversigt over dine kunder, samt se log over hvad du har lavet for dem.",
      path: "/inspector/report/view",
      bgColor: "#00ff1d1f",
    },
    {
      id: 4,
      title: "Rating & tilfredshed",
      icon: <StarIcon color="primary" />,
      text: "Her finder du tilbagemeldinger fra dine kunder.",
      path: "/inspector/rating",
      bgColor: "#00ff1d1f",
    },
    {
      id: 5,
      title: "Stamdata",
      icon: <SettingsIcon color="primary" />,
      text: "Her kan du redigere dine stamdata, såsom cvr, tlf. nummer og time priser",
      path: "/inspector/profile",
      bgColor: "#ffff0038",
    },
    {
      id: 6,
      title: "Skabeloner redaktør",
      icon: <SummarizeIcon color="primary" />,
      text: "Her kan du redigere dine rapportskabeloner",
      path: "/inspector/templates",
      bgColor: "#ffff0038",
    },
  ],
  [UserRole.Craftsman]: [
    {
      id: 1,
      title: "Start ny opgave",
      icon: <AssignmentIcon color="primary" />,
      text: "Her starter du en ny opgave",
      path: "/craftsman/tasklist/create/choose",
    },
    {
      id: 2,
      title: "Igangværende opgaver",
      icon: <BuildIcon color="primary" />,
      text: "Her holder du styr på igangværende opgaver",
      path: "/craftsman/tasklist",
      badgeName: Badges.Task,
    },
    // {
    //   id: 3,
    //   title: "Færdige opgaver",
    //   icon: <AssignmentTurnedInIcon color="primary" />,
    //   text: "Her finder du de kunder hvor alle opgaverne er  færdigmeldt",
    //   path: "/craftsman/archive",
    // },
    // {
    //   id: 4,
    //   title: "Forespørgsler",
    //   icon: <HouseIcon color="primary" />,
    //   text: "Her finder du forespørgsler fra boligejere, byggeinspektører, kolleager m.fl.",
    //   path: "/craftsman/offers",
    //   badgeName: Badges.Offer,
    // },
    // {
    //   id: 5,
    //   title: "Mine faste kunder og tilfredshed",
    //   icon: <StarIcon color="primary" />,
    //   text: "Her finder du dine kunder du er fast tilknyttet",
    //   path: "/craftsman/rating",
    // },
    {
      id: 6,
      title: "Stamdata",
      icon: <SettingsIcon color="primary" />,
      text: "Her kan du redigere dine stamdata, såsom cvr, tlf. nummer og time priser",
      path: "/craftsman/profile",
    },
    {
      id: 7,
      title: "Mine faste kunder",
      icon: <PeopleIcon color="primary" />,
      text: "Kunder der har accepteret dig som deres faste håndværker",
      path: "/craftsman/customers",
    },
  ],
};

export default MenuBlocks;
