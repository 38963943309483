import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { UserRole } from "../interfaces/models";
import ApiService from "../services/ApiService";
import { AppState } from "../store/store";

export const useDebouncedValue = (value: any, delay: number) => {
  const [debounced, setDebounced] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounced(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debounced;
};

export const useUser = () => {
  const user = useSelector((state: AppState) => state.user);
  return { ...user, isAdmin: user.role === UserRole.Administrator || user.role === UserRole.Root };
};

export enum Badges {
  Default = "",
  Offer = "offer",
  Task = "task",
}

export const useBadges = (badgeName: Badges) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    async function fetchData() {
      if (badgeName !== Badges.Default) {
        const val = await ApiService.get<number>(`/api/${badgeName}/badge`);

        if (val) {
          setValue(val);
        }
      }
    }
    fetchData();
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return value;
};

export const useAppDispatch = () => useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
