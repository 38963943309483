import React from "react";
import StyledDialog, { StyledDialogActions, StyledDialogContent } from "../../shared/StyledDialog";
import { DialogContentText, DialogTitle, Button, useTheme, useMediaQuery } from "@mui/material";

type ShareDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleShare: () => void;
  handleDownload: () => void;
};

const ShareDialog = (props: ShareDialogProps) => {
  const { open, handleClose, handleShare, handleDownload } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <StyledDialog fullScreen={fullScreen} maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle align="center" variant="h5">
        Del rapporten
      </DialogTitle>

      <StyledDialogContent>
        <DialogContentText variant="subtitle1">Indlæs eller del rapporten</DialogContentText>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="contained" onClick={handleDownload} color="primary">
          Indlæs
        </Button>

        <Button variant="contained" onClick={handleShare} color="primary">
          Del
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ShareDialog;
