import React from "react";
import { View, Text } from "@react-pdf/renderer";
import styles from "../pdfDocumentStyles";
import { InspectorReportParameter, TimeToFinish } from "../../../../../interfaces/models";
import { CraftsmanTypes } from "../../../../../constants/appConstants";

type SummaryItem = {
  competencies: (number | string)[] | null;
  [TimeToFinish.Now]: number;
  [TimeToFinish.OneToThreeYears]: number;
  [TimeToFinish.ThreeToFiveYears]: number;
  orderedPrice: number;
  id: string;
};

type Summary = {
  [key: string]: SummaryItem;
};

type PdfDocReportBudgetWorkersProps = {
  parameters: InspectorReportParameter[];
};

const PdfDocReportBudgetPerWorkers = (props: PdfDocReportBudgetWorkersProps) => {
  const calculateSummary = () => {
    const sum: Summary = {
      Total: {
        competencies: null,
        [TimeToFinish.Now]: 0,
        [TimeToFinish.OneToThreeYears]: 0,
        [TimeToFinish.ThreeToFiveYears]: 0,
        orderedPrice: 0,
        id: "total",
      },
    };

    props.parameters
      .filter((x) => !x.hidden && x.craftsmen?.length)
      .forEach((p) => {
        p.craftsmen.forEach((c) => {
          const key = c.toString();
          //code below is commented due to the requirement of https://dev.azure.com/cdmua/Klimaplan-myHouse/_boards/board/t/Klimaplan-myHouse%20Team/Stories/?workitem=1403
          //the sum is caclulated wrong because the combined craftsman tasks aren't split
          //const key = p.craftsmen.sort((a, b) => (a > b ? 1 : -1)).reduce((a, c) => a + c, "");

          if (!Object.hasOwn(sum, key)) {
            sum[key] = {
              competencies: p.craftsmen,
              [TimeToFinish.Now]: 0,
              [TimeToFinish.OneToThreeYears]: 0,
              [TimeToFinish.ThreeToFiveYears]: 0,
              orderedPrice: 0,
              id: key,
            };
          }

          switch (p.timeEstimate) {
            case TimeToFinish.Now:
              sum[key][TimeToFinish.Now] += p.priceEstimate;
              sum["Total"][TimeToFinish.Now] += p.priceEstimate;
              break;
            case TimeToFinish.OneToThreeYears:
              sum[key][TimeToFinish.OneToThreeYears] += p.priceEstimate;
              sum["Total"][TimeToFinish.OneToThreeYears] += p.priceEstimate;
              break;
            case TimeToFinish.ThreeToFiveYears:
              sum[key][TimeToFinish.ThreeToFiveYears] += p.priceEstimate;
              sum["Total"][TimeToFinish.ThreeToFiveYears] += p.priceEstimate;
              break;
          }
        });
      });

    return sum;
  };

  const summary = calculateSummary();

  return (
    <View break>
      <Text style={{ ...styles.pageTextTitleBold, ...styles.fontFamily }}>
        Opsummering budget fordelt på håndværkere:
      </Text>
      <View>
        {/* PAGE BUDGET--TITLE PART */}
        <View style={{ ...styles.row, ...styles.backgroundGrey }}>
          <View
            style={{
              flex: 1,
              flexBasis: "20%",
            }}
          >
            <View style={styles.padding}>
              <Text style={{ ...styles.pageText, ...styles.boldTextOnly, ...styles.fontFamily }}>Håndværkere</Text>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
            }}
          >
            <View style={styles.padding}>
              <Text style={{ ...styles.pageText, ...styles.boldTextOnly, ...styles.fontFamily }}>Nu</Text>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
            }}
          >
            <View style={styles.padding}>
              <Text style={{ ...styles.pageText, ...styles.boldTextOnly, ...styles.fontFamily }}>1 til 3 år</Text>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
            }}
          >
            <View style={styles.padding}>
              <Text style={{ ...styles.pageText, ...styles.boldTextOnly, ...styles.fontFamily }}>3 to 5 år</Text>
            </View>
          </View>
        </View>

        {Object.values(summary)
          .filter((x) => x.competencies)
          .sort((a, b) => {
            if (CraftsmanTypes[a.id] === "Andet") return 1;
            return CraftsmanTypes[a.id] > CraftsmanTypes[b.id] ? 1 : -1;
          })
          .map((x) => (
            <SummaryItemLayout key={x.id} {...x} />
          ))}

        <View style={{ ...styles.borderTop, ...styles.boldTextOnly }}>
          <SummaryItemLayout {...summary["Total"]} competencies={["I alt"]} />
        </View>
      </View>
    </View>
  );
};

export default PdfDocReportBudgetPerWorkers;

const SummaryItemLayout = (props: SummaryItem) => {
  return (
    <View style={{ ...styles.row, ...styles.padding }}>
      <View
        style={{
          flex: 1,
          flexBasis: "20%",
        }}
      >
        <Text style={{ ...styles.pageText, ...styles.fontFamily }}>{CraftsmanTypes[props.id]}</Text>
      </View>

      <View
        style={{
          flex: 1,
          alignItems: "flex-end",
        }}
      >
        <Text style={{ ...styles.pageText }}>
          {props[TimeToFinish.Now].toLocaleString("da-DK", {
            currency: "DKK",
            style: "currency",
          })}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          alignItems: "flex-end",
        }}
      >
        <Text style={{ ...styles.pageText }}>
          {props[TimeToFinish.OneToThreeYears].toLocaleString("da-DK", {
            currency: "DKK",
            style: "currency",
          })}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          alignItems: "flex-end",
        }}
      >
        <Text style={{ ...styles.pageText }}>
          {props[TimeToFinish.ThreeToFiveYears].toLocaleString("da-DK", {
            currency: "DKK",
            style: "currency",
          })}
        </Text>
      </View>
    </View>
  );
};
