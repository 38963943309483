import { SearchResult, TemplateModel } from "../interfaces/models";
import ApiService from "./ApiService";

const TemplateService = {
    getTemplates: async () => {
      return await ApiService.get<SearchResult<TemplateModel>>("/api/template");
    },
  
    getDefaultTemplate: async () => {
      return await ApiService.get<TemplateModel>("/api/template/default");
    },
  
    setDefaultTemplate: async (templateId: string) => {
      return await ApiService.post(`/api/template/default/${templateId}`);
    },
  
    getTemplate: async (templateId: string) => {
      return await ApiService.get<TemplateModel>(`/api/template/${templateId}`);
    },
  
    uploadTemplate: async (template: TemplateModel) => {
      return await ApiService.post<string>("/api/template", template);
    },

    removeTemplate: async (templateId: string) => {
      return await ApiService.delete<string>(`/api/template/${templateId}`);
    },
  };

 export default TemplateService;