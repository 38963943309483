import { Grid, Paper, useMediaQuery, Drawer, Typography, Tooltip, IconButton, TextField, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import AppToolbar from "../shared/AppToolbar";
import ReportLayout from "./components/ReportLayout";
import { useReportActions, useReportState } from "./reportActions";
import GroupedItemsList from "./components/GroupedItemsList";
import ReportSkeleton from "./components/ReportSkeleton";
import { useSelector } from "react-redux";
import { AppState } from "../store/store";
import { useTheme } from "@mui/material";
import { ChangeContext } from "./CreateReportPage";
import PdfModal, { generatePdf } from "./modals/PdfModal";
import { useCommonState } from "../common/commonActions";
import { useParams } from "react-router-dom";
import { NIL } from "uuid";
import NotificationBar from "../shared/Notification";
import { useUser } from "../common/hooks";
import ShareDialog from "./dialogs/ShareDialog";
import LoadingOverlay from "../shared/LoadingOverlay";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },
  drawerPaper: {
    paddingTop: 45,
    width: 240,
    alignItems: "flex-start",
  },
  paper: {
    position: "fixed",
    height: "100vh",
    width: 250,
    left: 0,
    top: 45,
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    paddingBottom: 50,
  },
  container: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 250,
    },
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    padding: 30,
  },
}));

const ReportListPage = () => {
  const classes = useStyles();
  const report = useReportState();
  const actions = useReportActions();
  const common = useCommonState();
  const groupedReportList = useSelector((state: AppState) => state.common.groupedReportList);
  const [openMenu, setOpenMenu] = useState(false);

  const [filter, setFilter] = useState("");
  const [notification, setNotification] = useState<{
    notificationShown: boolean;
    notificationText: string;
    notificationType: "success" | "error";
  }>({ notificationShown: false, notificationText: "", notificationType: "success" });
  const [loading, setLoading] = React.useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const pdfFile = React.useRef<Blob | null>(null);
  const user = useUser();

  const { id } = useParams();

  const toggleDrawer = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    const getGroupedReportListAsync = async () => {
      try {
        await actions.getGroupedReportList("", 0, 10);
        if (id && id !== NIL) {
          await actions.getReport(id);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    getGroupedReportListAsync();

    return () => {
      actions.clearReport();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const showPdf = () => {
    actions.updateMaintenanceInfo().finally(() => {
      actions.openPdfView();
    });
  };

  const closePdf = () => {
    actions.closePdfView();
  };

  const handleNotificationClose = () => {
    setNotification({ notificationShown: false, notificationText: "", notificationType: "success" });
  };

  const openShareDialog = async () => {
    try {
      setLoading(true);
      if (!report.approved) {
        setNotification({
          notificationShown: true,
          notificationText: "Report is not approved yet. You can't share it.",
          notificationType: "error",
        });
        return;
      }
      if (!report.pdfLink) {
        try {
          const pdf = await generatePdf(report, user);
          if (!pdf) {
            setNotification({
              notificationShown: true,
              notificationText: "Failed to generate PDF.",
              notificationType: "error",
            });
            return;
          }

          await actions.saveDoc(report.id, pdf);
          await actions.getReport(report.id);
        } catch (error) {
          setNotification({
            notificationShown: true,
            notificationText: "Failed to upload PDF.",
            notificationType: "error",
          });
          return;
        }
      }

      const response = await fetch(report.pdfLink!);
      if (!response.ok) {
        setNotification({
          notificationShown: true,
          notificationText: "PDF failed to generate.",
          notificationType: "error",
        });
        return;
      }
      const blob = await response.blob();
      pdfFile.current = blob;

      setShowShareDialog(true);
    } finally {
      setLoading(false);
    }
  };

  const handleShare = () => {
    setShowShareDialog(false);

    if (pdfFile.current) {
      const shareData: ShareData = {
        files: [
          new File([pdfFile.current], report.data.address + ", " + report.data.city + ".pdf", {
            type: "application/pdf",
          }),
        ],
      };
      if (navigator.canShare(shareData)) {
        navigator.share(shareData).catch((error) => {
          setNotification({
            notificationShown: true,
            notificationText: "Failed to share PDF. Try to download it instead.",
            notificationType: "error",
          });
        });
      }
    }
  };

  const handleDownload = () => {
    setShowShareDialog(false);
    if (pdfFile.current) {
      const url = window.URL.createObjectURL(pdfFile.current);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", report.data.address + ", " + report.data.city + ".pdf"); // or any other filename you want
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const applyFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilter(event.target.value);
    actions.getGroupedReportList(event.target.value, 0, 10);
  };

  const renderSidebarContent = () => {
    return (
      <>
        <Grid item p={1}>
          <TextField
            variant="outlined"
            onChange={applyFilter}
            value={filter}
            fullWidth
            placeholder="Indtast addresse"
          />
        </Grid>
        <GroupedItemsList
          title="Godkendt rapporter"
          list={groupedReportList}
          getItem={actions.getReport}
          getList={actions.getGroupedReportList}
        />
      </>
    );
  };

  return (
    <>
      <AppToolbar
        loading={loading}
        handleDrawerToggle={!isMd ? () => toggleDrawer() : undefined}
        buttons={
          <>
            {report.id !== NIL && (
              // <Tooltip arrow title="Se PDF">
              //   <IconButton color="inherit" onClick={showPdf} size="large">
              //     <Typography variant="body1">PDF</Typography>
              //   </IconButton>
              // </Tooltip>
              <Tooltip arrow title="Del PDF">
                <Button color="inherit" onClick={openShareDialog}>
                  <Typography variant="body1">PDF</Typography>
                </Button>
              </Tooltip>
            )}
          </>
        }
      ></AppToolbar>
      {!isMd && (
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor={"left"}
          open={openMenu}
          onClose={() => setOpenMenu(false)}
        >
          {renderSidebarContent()}
        </Drawer>
      )}
      <Grid container>
        {isMd && (
          <Grid item>
            <Paper className={classes.paper} variant="outlined" square>
              {renderSidebarContent()}
            </Paper>
          </Grid>
        )}

        {!report.loading ? (
          <Grid item container justifyContent="center" className={classes.container}>
            <Grid item container xs={12} md={11} lg={9} xl={8} direction="column">
              <Grid item>
                {report.id === NIL ? (
                  <Typography>Vælg en rapport fra listen til venstre</Typography>
                ) : (
                  <ChangeContext.Provider value={{ current: 0 }}>
                    <ReportLayout
                      sectionRefs={{}}
                      changedTabIndexCallback={() => {
                        /**/
                      }}
                      showTitle
                      isReadOnly={report.approved}
                    />
                  </ChangeContext.Provider>
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <ReportSkeleton />
        )}
      </Grid>
      {/* {common.pdfView && (
        <PdfModal
          shown={common.pdfView}
          needApprove={false}
          handleClose={closePdf}
          handleApprove={() => Promise.resolve()}
        />
      )} */}
      <NotificationBar
        open={notification.notificationShown}
        variant={notification.notificationType}
        handleClose={handleNotificationClose}
        message={notification.notificationText}
      />
      <ShareDialog
        open={showShareDialog}
        handleClose={() => setShowShareDialog(false)}
        handleShare={handleShare}
        handleDownload={handleDownload}
      />
      {/* <LoadingOverlay loading={loading} backgroundColor={"rgba(255,255,255, 0.5)"} /> */}
    </>
  );
};

export default React.memo(ReportListPage);
