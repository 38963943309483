import React from "react";
import { View, Image, Text } from "@react-pdf/renderer";
import styles from "../pdfDocumentStyles";
import { InspectorReportObject, InspectorReportParameter, TemplateModel } from "../../../../../interfaces/models";
import PdfDocReportSection from "./PdfDocReportSection";

const PdfDocReportObject = (
  props: InspectorReportObject & {
    parameters: InspectorReportParameter[];
    template: TemplateModel;
  }
) => {
  const buildingName = props.label + ". " + props.name;
  
  return (
    <>
      <View style={{ ...styles.column }} break>
        <Text style={{ ...styles.title }}>{buildingName}</Text>
        {props.pictures.length > 0 || props.description ? (
          <View style={{ ...styles.row }}>
            <View style={{ ...styles.padding, ...styles.half }}>
              <Text style={{ ...styles.pageText, ...styles.textItalic }}>{props.description}</Text>
            </View>
            <View style={{ ...styles.padding, ...styles.half, ...styles.reportImage }}>
              {props.pictures.length ? (
                <Image
                  style={{
                    transform: props.pictures[0].orientation || "",
                    objectFit: "cover",
                    padding: 2,
                    minWidth: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    minHeight: "100%",
                  }}
                  source={props.pictures[0].url ? props.pictures[0].url : props.pictures[0].data}
                  cache
                />
              ) : null}
            </View>
          </View>
        ) : null}
      </View>
      {props.template?.sections?.length
        ? props.template.sections
            .filter((x) => !x.hidden)
            .map((x, index) => {
              return (
                <PdfDocReportSection
                  makeBreak={index === 0 ? props.description !== "" || props.pictures.length > 0 : true}
                  key={props.id + x.id}
                  {...x}
                  parameters={props.parameters.filter(
                    (p) => !p.hidden
                    //&& x.items.some((i) => i.id === p.itemId)
                  )}
                  options={props.template.options}
                />
              );
            })
        : null}
    </>
  );
};

export default PdfDocReportObject;
