import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";

type DialogResult = {
  templateId: string;
  accepted: boolean;
};

const initialState = { templateId: "", accepted: false };

const NewReportDialog = (props: { open: boolean; handleClose: (dialogResult: DialogResult) => void }) => {
  const templates = useSelector((state: AppState) => state.common.templateList);

  const { handleClose } = props;

  const [dialogResult, setDialogResult] = useState(initialState);
  const [close, setClose] = useState(false);

  useEffect(() => {
    if (close) {
      handleClose(dialogResult);
    }
    return () => {
      setClose(false);
    };
  }, [close, handleClose, dialogResult]);

  const closeDialog = (accepted: boolean) => {
    setDialogResult({ ...dialogResult, accepted });
    setClose(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setDialogResult({ ...dialogResult, templateId: value });
  };

  return (
    <div>
      <Dialog open={props.open} onClose={() => props.handleClose(dialogResult)} aria-labelledby="form-dialog-title">
        <DialogTitle>{"Ønsker du at starte på en ny rapport?"}</DialogTitle>
        <DialogContent>
          <FormLabel component="legend">Rapporttype</FormLabel>

          <RadioGroup name="reporttype" value={dialogResult.templateId} onChange={handleChange}>
            {!!templates &&
              !!templates.length &&
              templates.map((x) => (
                <FormControlLabel key={x.id} value={x.id} control={<Radio color="primary" />} label={x.name || ""} />
              ))}
            {!templates && <CircularProgress />}
          </RadioGroup>
          <Typography>Husk at gemme eventuelle ændringer inden.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog(false)} color="primary">
            {"Nej"}
          </Button>
          <Button onClick={() => closeDialog(true)} color="primary" disabled={!dialogResult.templateId}>
            {"Ja"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(NewReportDialog);
