import React from "react";
import { View, Image as ReactPdfImage, Text } from "@react-pdf/renderer";
import styles from "../pdfDocumentStyles";
import { ParameterDescription } from "../../../../../interfaces/models";

const PdfDocReportDescription = (props: ParameterDescription) => {
  return (
    <View style={{ ...styles.row, ...styles.rowSpace, marginTop: 20 }}>
      {props.description ? (
        <View style={{ ...styles.half }}>
          <Text style={{ ...styles.pageText, ...styles.paddingLeft, ...styles.underlined }}>Problemstillingen:</Text>
          <Text style={{ ...styles.pageText, ...styles.padding }}>{props.description}</Text>
        </View>
      ) : null}
      {props.pictures?.length && ( props.pictures[0].url || props.pictures[0].data) ? ( //it need for prevent of crashing making of report if picture has no data  or url
        <View break style={{ ...styles.half, ...styles.reportImage }}>
          <ReactPdfImage
            style={{
              transform: props.pictures[0].orientation,
              objectFit: "cover",
              padding: 2,
              minWidth: "100%",
              maxWidth: "100%",
              maxHeight: "100%",
              minHeight: "100%",
            }}
            source={props.pictures[0].url ? props.pictures[0].url : props.pictures[0].data}
            cache
          />
        </View>
      ) : null}
    </View>
  );

};

export default PdfDocReportDescription;
