import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../common/hooks";

export const DeafultRedirect = (props: { default: string }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname !== props.default) {
      navigate(props.default, { replace: true });
    }

    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return null;
};

export const ProtectedRoutes = (props: { redirectTo: string }) => {
  const user = useUser();
  return user.authorized ? <Outlet /> : <Navigate to={props.redirectTo} replace />;
};
