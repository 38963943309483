import React from "react";
import { View, Text } from "@react-pdf/renderer";
import styles from "../pdfDocumentStyles";
import { CraftsmanType, InspectorReportParameter } from "../../../../../interfaces/models";
import PdfDocReportDescription from "./PdfDocReportDescription";
import { CraftsmanTypes, TimeEstimate } from "../../../../../constants/appConstants";

const PdfDocReportItem = (props: InspectorReportParameter & { needBreak?: boolean }) => {
  return (
    <>
      <View break={props.needBreak} style={{ ...styles.row, ...styles.rowSpace }}>
        <Text style={{ ...styles.subTitle, ...styles.padding, width: "100%" }}>{props.name}</Text>
      </View>
      {props.descriptions.length
        ? props.descriptions.map((item) => <PdfDocReportDescription key={item.id} {...item} />)
        : null}
      <View style={{ ...styles.row, ...styles.backgroundLightGrey }}>
        <View style={styles.tableColumn}>
          <Text style={{ ...styles.pageText, ...styles.paddingLeft }}>Håndværkere</Text>
        </View>
        <View style={{ ...styles.tableColumn, alignItems: "center" }}>
          <Text style={{ ...styles.pageText, ...styles.paddingLeft }}>Udbedring</Text>
        </View>
        <View style={{ ...styles.tableColumn, alignItems: "flex-end" }}>
          <Text style={{ ...styles.pageText, ...styles.paddingLeft }}>Pris overslag inkl. moms</Text>
        </View>
      </View>

      <View style={{ ...styles.row, ...styles.padding }}>
        <View style={styles.tableColumn}>
          {props.craftsmen.length ? (
            props.craftsmen.map((x) => (
              <Text key={"craftsman" + x} style={{ ...styles.pageText, ...styles.paddingLeft }}>
                {CraftsmanTypes[x]}
              </Text>
            ))
          ) : (
            <Text style={{ ...styles.pageText, ...styles.paddingLeft }}>{CraftsmanTypes[CraftsmanType.None]}</Text>
          )}
        </View>
        <View style={{ ...styles.tableColumn, alignItems: "center" }}>
          <Text style={{ ...styles.pageText, ...styles.paddingLeft }} wrap={false}>
            {TimeEstimate.find((x) => x.id === props.timeEstimate)?.value || TimeEstimate[0].value}
          </Text>
        </View>
        <View style={{ ...styles.tableColumn, alignItems: "flex-end" }}>
          <Text style={{ ...styles.pageText, ...styles.paddingLeft }} wrap={false}>
            {(props.priceEstimate ? props.priceEstimate : 0).toLocaleString("da-DK", {
              style: "currency",
              currency: "DKK",
            })}
          </Text>
        </View>
      </View>

      <Text style={{ ...styles.pageText, ...styles.paddingLeft }}>Note/uddybende kommentarer:</Text>
      <Text style={{ ...styles.pageText, ...styles.padding }}>{props.note}</Text>
    </>
  );
};

export default PdfDocReportItem;
