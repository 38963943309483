import { AnyAction } from "redux";
import { Admin, Common, User } from "../constants/actionTypes";
import { Solution, Vendor } from "../interfaces/models";

export interface SolutionState {
  solutions: Solution[];
  vendors: Vendor[];
  count: number;
  loading: boolean;
  current: Solution | undefined;
}

const initialState: SolutionState = {
  solutions: [],
  count: 0,
  loading: false,
  current: undefined,
  vendors: [],
};

const soutionReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case Admin.GET_SOLUTIONS:
    case Admin.GET_SOLUTION:
    case Admin.ADD_SOLUTION:
    case Admin.REMOVE_SOLUTION:
    case Admin.UPDATE_SOLUTION:
    case Admin.GET_VENDORS:
    case Admin.GET_VENDOR:
    case Admin.ADD_VENDOR:
    case Admin.REMOVE_VENDOR:
    case Admin.UPDATE_VENDOR:
      return { ...state, loading: true };
    case Admin.GET_SOLUTIONS_SUCCEEDED:
      return {
        ...state,
        solutions: action.payload ? [...action.payload.results] : [],
        count: action.payload.count,
        loading: false,
      };
    case Admin.GET_SOLUTION_SUCCEEDED:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case Admin.ADD_SOLUTION_SUCCEEDED:
      return {
        ...state,
        solutions: [...state.solutions, action.payload],
        loading: false,
        current: undefined,
      };

    case Admin.UPDATE_SOLUTION_SUCCEEDED:
      return {
        ...state,
        solutions: [...state.solutions.filter(x => x.id !== action.payload.id), action.payload],
        current: undefined,
        loading: false,
      };

    case Admin.REMOVE_SOLUTION_SUCCEEDED:
      return {
        ...state,
        solutions: state.solutions.filter((item) => item.id !== action.payload),
        loading: false,
        current: undefined,
      };
    case Admin.GET_VENDORS_SUCCEEDED:
      return {
        ...state,
        vendors: action.payload ? [...action.payload.results] : [],
        count: action.payload.count,
        loading: false,
      };
    case Admin.ADD_VENDOR_SUCCEEDED:
      return {
        ...state,
        vendors: [...state.vendors, action.payload],
        loading: false,
      };
    case Admin.CLEAR_CURRENT_SOLUTION: {
      return { ...state, current: undefined };
    }

    case User.LOGOUT:
    case User.CLEAR_USER_DATA:
      return {
        ...initialState,
      };

    case Common.GOT_ERROR:
      return { ...state, error: action.payload, loading: false, current: undefined };
    default:
      return state;
  }
};

export default soutionReducer;
