import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "Roboto",
  fonts: [
    { src: require("../../../../assets/fonts/Roboto-Regular.ttf") }, // font-style: normal, font-weight: normal
    { src: require("../../../../assets/fonts/Roboto-Bold.ttf"), fontWeight: "bold" },
    { src: require("../../../../assets/fonts/Roboto-Medium.ttf"), fontWeight: 500 },
    { src: require("../../../../assets/fonts/Roboto-Italic.ttf"), fontStyle: "italic"},
  ],
});

const styles = StyleSheet.create({
  page: {
    paddingBottom: 120,
    paddingTop: 20,
    borderTop: "10px solid grey",
    borderLeft: "10px solid grey",
    borderRight: "10px solid grey"
  },

  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 30,
    paddingTop: 50,
    paddingBottom: 30,
    paddingRight: 30
  },
  imageWide: {
    maxHeight: "250px",
    alignSelf: "center",
    objectFit: "contain",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    // width: "100%",
  },
  lineShift: {
    marginTop: 10,
  },
  borderTop: {
    width: "100%",
    borderTop: "1px solid grey",
    paddingTop: 5,
    marginTop: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    paddingTop: 10,
    paddingBottom: 5,
    marginBottom: 5,
  },

  secondaryTitle: {
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: 1.5,
  },
  subTitle: {
    fontSize: 12,
    fontWeight: "bold",
    lineHeight: 1.5,
    textDecoration: "underline"
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  rowSpace: {
    justifyContent: "space-between",
  },
  boldText: {
    fontWeight: "bold",
    fontSize: 10,
  },
  marginBottom: {
    marginBottom: 30,
  },
  headerTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingBottom: 3,
    marginBottom: 20,
  },
  imageHeader: {
    height: 40,
    marginBottom: 20,
  },
  half: {
    width: "50%"
  },
  footerWrapper: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  backgroundGrey: {
    backgroundColor: "#efefef",
    padding: "8px 5px 5px 5px"
  },
  backgroundLightGrey: {
    backgroundColor: "#f2f2f2",
    padding: "8px 5px 5px 5px", 
  },
  padding: {
    padding: 5
  },
  paddingLeft: {
    paddingLeft: 5
  },
  footer: {
    flexDirection: "row",
    paddingLeft: 10,
    paddingRight: 10,
    display: "flex",
    marginBottom: 15,
  },

  footerPageNumber: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingBottom: 55,
    paddingRight: 20,

    textAlign: "right",
    fontSize: 10,
  },

  footerImageWrapper: {
    width: "100%",
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    bottom: 0,
    left: 0,
    right: 0,
  },
  footerImage: {
    width: "100%",
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    height: 65,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },


  footerText: {
    fontSize: 7,
    lineHeight: 1.2,
    paddingTop: 0,
    fontWeight: 100,
    paddingBottom: 0,
    width: "100%",
    alignContent: "flex-start",
  },
  footerTextBold: {
    fontSize: 9,
    fontWeight: "bold",
    paddingTop: 7,
    paddingBottom: 3
  },
  tableColumn: {
    flex: 1,
    flexBasis: "30%",
    display: "flex",
    flexDirection: "column"
  },
  reportImage: {
    overflow: "hidden",
    width: 250,
    height: 250,
    display: "flex"
  },
  pageTextTitleBold: {
    fontSize: 14,
    fontWeight: "bold",
    marginTop: 25,
    marginBottom: 12,
  },
  underlined: {
    textDecoration: "underline"
  },
  pageText: {
    fontSize: 11,
    lineHeight: 1.3,
    marginTop: 2,
    marginBottom: 2,
  },

  pageTextLastLine: {
    fontSize: 11,
    marginTop: 2,
    marginBottom: 10,
  },

  pageTextFirstLineBold: {
    fontSize: 11,
    fontWeight: "bold",
    marginTop: 12,
    marginBottom: 2,
  },

  textItalic: {
    fontStyle: "italic",
  },

  // rowTextWrapper: {
  //   flexDirection: "row",
  //   display: "flex",
  // },

  textCenter: {
    alignItems: "center",
    justifyContent: "center",
  },

  fontFamily: {
    fontFamily: "Roboto",
  },

  boldTextOnly: {
    fontWeight: "bold",
  },
});

export default styles;
