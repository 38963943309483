import { DeleteForever, Edit, MoreVert, VisibilityOff } from "@mui/icons-material";
import { Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";

const ReportItemMenu = (props: { hidden: boolean; toggleHidden: () => void; remove: () => void, showEdit: () => void; }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const closeMenu = (action: () => any) => {
    setAnchorEl(null);
    action();
  };

  return (
    <>
      <Grid item>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <MoreVert />
        </IconButton>
      </Grid>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={() => closeMenu(props.showEdit)}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>Rediger titel</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => closeMenu(props.toggleHidden)}>
          <ListItemIcon>
            <VisibilityOff />
          </ListItemIcon>
          <ListItemText>Ikke relevant</ListItemText>
        </MenuItem>
        <MenuItem divider />
        <MenuItem onClick={() => closeMenu(props.remove)}>
          <ListItemIcon>
            <DeleteForever />
          </ListItemIcon>
          <ListItemText>Slet</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ReportItemMenu;
