import React from "react";
import { View, Text } from "@react-pdf/renderer";
import styles from "../pdfDocumentStyles";

const PdfDocPostReportText = () => {
  return (
    <View>
    <Text style={styles.pageTextTitleBold}>Forbehold</Text>

    <View>
      <Text style={{ ...styles.pageText, ...styles.textItalic }}>
        Der gøres opmærksom på, at vores besigtigelse alene omhandler stikprøvekontroller på
        besigtigelsestidspunktet, og der ifm. gennemgangen af ejendommen afsat op til 2 timer, hvorfor vi ikke
        nødvendigvis har gennemgået eller kontrolleret alt. På samme måde er nærværende notat ikke udtømmende
        for emner som der er blevet talt om ved den mundtlige rådgivning, og må derfor ikke betragtes som et
        referat.
      </Text>

      <Text style={styles.pageTextFirstLineBold}>Prisforbehold </Text>
      <Text style={styles.pageText}>
        Fejl og mangler er, (hvis nævnt) prissat og vurderet ud fra bedste evne, og er et erfaringsmæssigt skøn
        vurderet uden prisindhentning fra håndværkere, hvorfor der tages forbehold for denne vurdering.
      </Text>

      <Text style={styles.pageTextFirstLineBold}>Indboforbehold</Text>
      <Text style={styles.pageText}>
        Der tages forbehold for skjulte fejl og skader pga. utilgængelige rum eller områder. Herunder værende
        områder som har været dækket med tæpper, møbler, andet indbo, beplantning, mv.
      </Text>

      <Text style={styles.pageTextFirstLineBold}>Juridiske forbehold</Text>
      <Text style={styles.pageText}>
        Byggesagkyndig.nu yder udelukkende byggeteknisk rådgivning. Juridiske forsikringsmæssige forhold, skal
        derfor drøftes og vurderes af egen advokat.
      </Text>

      <Text style={styles.pageTextFirstLineBold}>Billede forbehold</Text>
      <Text style={styles.pageText}>
        De i notatet brugte billeder og målinger er udført dags dato. Ved brug af bygherres eller arkivfoto, vil
        dette fremgå af teksten ved foto.
      </Text>

      <Text style={styles.pageTextFirstLineBold}>Skrive- og tastefejl</Text>
      <Text style={styles.pageTextLastLine}>
        Nærværende notat er skrevet på stedet på en iPad. Der tages forbehold for mindre stave- eller tastefejl
        samt for underlige eller sjov pga. autokorrektur mv.
      </Text>
    </View>
    </View>
  );
};

export default PdfDocPostReportText;
