import React from "react";
import { View, Text } from "@react-pdf/renderer";
import styles from "../pdfDocumentStyles";
import { InspectorReportParameter } from "../../../../../interfaces/models";
import PdfDocReportItem from "./PdfDocReportItem";

const PdfDocReportItemGroup = (props: InspectorReportParameter & { needBreak: boolean }) => {
   return (
    <>
        <View style={{ ...styles.row, ...styles.rowSpace, ...styles.backgroundGrey }}>
          <Text style={{ ...styles.secondaryTitle }}>
            {props.label} {props.name}
          </Text>
        </View>
      {props.parameters?.length
        ? props.parameters.map((item: InspectorReportParameter) => <PdfDocReportItem key={item.id} {...item} />)
        : null}
    </>
  );
};

export default PdfDocReportItemGroup;
