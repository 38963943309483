import React from "react";
import AppToolbar from "../shared/AppToolbar";
import UserProfile from "../user/UserProfile";

const InspectorProfilePage = () => {
  return (
    <>
      <AppToolbar />
      <UserProfile />
    </>
  );
};

export default InspectorProfilePage;
