import React from "react";
import Skeleton from '@mui/material/Skeleton';
import { Grid, useTheme, useMediaQuery } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },
  drawerPaper: {
    width: 240,
    alignItems: "flex-start",
  },
  paper: {
    position: "fixed",
    height: "100vh",
    width: 250,
    left: 0,
    top: 45,
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    paddingBottom: 50,
  },
  container: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 250,
    },
    padding: 30,
  },
  rectSkeleton: {
    marginBottom: 30 
  }
}));

const ReportSkeleton = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <Grid item container justifyContent="center" className={classes.container}>
      <Grid item container xs={12} md={11} lg={9} xl={8} direction="column">
          <Skeleton animation="wave" variant="rectangular" width={300} height={30} className={classes.rectSkeleton} />
        <Grid item container justifyContent="space-between">
          <Grid item md={4}>
            <Skeleton animation="wave" variant="rectangular" width={300} height={30} className={classes.rectSkeleton} />
            <Skeleton animation="wave" variant="rectangular" width={300} height={30} className={classes.rectSkeleton}/>
          <Skeleton animation="wave" variant="rectangular" width={300} height={30} className={classes.rectSkeleton} />        
          <Skeleton animation="wave" variant="rectangular" width={300} height={30} className={classes.rectSkeleton}/>      
          <Skeleton animation="wave" variant="rectangular" width={300} height={30} className={classes.rectSkeleton} />     
          <Skeleton animation="wave" variant="rectangular" width={300} height={30} className={classes.rectSkeleton} />     
          <Skeleton animation="wave" variant="rectangular" width={300} height={30} className={classes.rectSkeleton} />
          </Grid>
          <Grid item md={6}>
            <Skeleton animation="wave" variant="rectangular" width={300} height={300} className={classes.rectSkeleton} />
          </Grid>
        </Grid>
        <Grid item>
          <Grid item xs={12} container>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rectangular" width={300} height={30} className={classes.rectSkeleton} />
                <Skeleton animation="wave" variant="rectangular" width={"100%"} height={30} className={classes.rectSkeleton} />
              </Grid>
              {!isXs &&(
              <Grid container justifyContent="space-between">
              <Grid item xs={3}>
                <Skeleton animation="wave" variant="rectangular" width={150} height={30} className={classes.rectSkeleton} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton animation="wave" variant="rectangular" width={150} height={30} className={classes.rectSkeleton} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton animation="wave" variant="rectangular" width={150} height={30} className={classes.rectSkeleton} />
              </Grid>
              </Grid>  
              )}
            
            </Grid>
            <Grid item container>
              <Skeleton animation="wave" variant="rectangular" width={"100%"} height={30} className={classes.rectSkeleton} />
              <Skeleton animation="wave" variant="rectangular" width={"100%"} height={30} className={classes.rectSkeleton} />  
             <Skeleton animation="wave" variant="rectangular" width={315} height={30} className={classes.rectSkeleton} />
              <Skeleton animation="wave" variant="rectangular" width={"100%"} height={30} className={classes.rectSkeleton} />
              <Skeleton animation="wave" variant="rectangular" width={"100%"} height={30} className={classes.rectSkeleton} />
              <Skeleton animation="wave" variant="rectangular" width={"100%"} height={30} className={classes.rectSkeleton} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportSkeleton;
