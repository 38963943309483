//import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import withStyles from '@mui/styles/withStyles';




const StyledDialog = withStyles({
    paper: {
      padding: 30,
      justifyContent: "center",
      alignItems: "center"
    },
  })(Dialog);
  
  export const StyledDialogContent = withStyles({
   root: {
     flex: "inherit"
    },
  })(DialogContent);
  
  export const StyledDialogActions = withStyles({
    root: {
      justifyContent: "center",
    },
  })(DialogActions);

  export default StyledDialog