import { Container, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppToolbar from "../shared/AppToolbar";
import { useNavigate } from "react-router-dom";
import Reports from "../admin/sections/reports/Reports";
import { useCommonState } from "../common/commonActions";

const StyledContainer = styled(Container)({
  paddingTop: 80,
  paddingBottom: 80,
});

const InspectorAdminPage = () => {
  const navigate = useNavigate();
  const [viewState, setViewState] = useState<"list" | "report">("list");

  useEffect(() => {
    setViewState("list");
    return () => {
      //
    };
  }, []);

  const goBack = () => {
    if (viewState == "report") {
      setViewState("list");
    } else {
      navigate("/");
    }
  };

  const onShowReport = () => {
    setViewState("report");
  };

  const commonState = useCommonState();

  return (
    <>
      <AppToolbar title={viewState === "report" ? "Tilbage til rapportliste" : "Rapprotliste"} goBack={goBack} loading={commonState.loading}/>
      <StyledContainer>
        <Reports noBackButton viewState={viewState} onShowReport={onShowReport} />
      </StyledContainer>
    </>
  );
};

export default InspectorAdminPage;
