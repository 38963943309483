import { Button, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useReportActions, useReportState } from "../reportActions";
import ReportHeader from "./ReportHeader";
import ReportObject from "./ReportObject";
import ReportWishList from "./ReportWishList";
import Barometer from "./Barometer";
import { NIL } from "uuid";
import { calculateDegrees } from "../../common/utils";
import TooltipForDisabled from "../../shared/Tooltips";

const useStyles = makeStyles(() => ({
  section: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  button: {
    minWidth: 102,
  },
  approved: {
    color: "green",
    flex: 1,
  },
  notApproved: {
    color: "red",
    flex: 1,
  },
}));

type ReportLayoutProps = {
  showTitle: boolean;
  sectionRefs: { [key: string]: { [key: string]: HTMLDivElement | null } };
  openNewObjectDialog?: () => void;
  openEditDialog?: () => void;
  changedTabIndexCallback: (tabIndex: number) => void;
  isReadOnly?: boolean;
};

const ReportLayout = (props: ReportLayoutProps) => {
  const classes = useStyles();
  const report = useReportState();
  const reportActions = useReportActions();

  const [tabIndexValue, setTabIndexValue] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<unknown>, value: any) => {
    setTabIndexValue(value);
    props.changedTabIndexCallback(value);
  };

  useEffect(() => {
    setTabIndexValue(0);
    props.changedTabIndexCallback(0);
    if (report.id !== NIL) reportActions.updateEvaluation();
    return () => {
      setTabIndexValue(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.id, report.objects.length]);

  return (
    <Grid item xs={12} container>
      {props.showTitle && (
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <Typography variant="h3">
              {report.data &&
                report.data.address &&
                `${report.data.address}, ${report.data.postcode} ${report.data.city}`}
            </Typography>
          </Grid>
          {props.isReadOnly ? (
            <Grid item>
              <Typography className={classes.approved}>GODKENDT</Typography>
            </Grid>
          ) : (
            <Grid item>
              <Typography className={classes.notApproved}>IKKE GODKENDT</Typography>
            </Grid>
          )}
        </Grid>
      )}
      {report.data && (
        <ReportHeader
          reportId={report.id}
          {...report.data}
          allowUpdate={!props.isReadOnly}
          changeReportInfo={reportActions.changeReportInfo}
          updateHousePic={reportActions.updateHousePic}
          customerLink={report.customerLink}
        />
      )}
      <Grid container justifyContent="flex-end" alignItems="flex-end">
        <Grid item>
          <Typography variant="h6">Den byggesagkyndiges vurdering af ejendommes generelle stand</Typography>
          <Barometer rotation={report.evaluation === 0 ? null : calculateDegrees(report.evaluation)} size="small" />
          {report.evaluation > 0 && (
            <Grid item container spacing={1} justifyContent={"center"}>
              <Grid item>
                <Typography>Gennemsnits værdi</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ fontWeight: 600 }}>{report.evaluation.toFixed(1)}</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} className={classes.section}>
          <Typography variant="h4">Registering af bygningens tilstand</Typography>
        </Grid>
        <Grid container item xs={12} spacing={1} className={classes.section}>
          <Grid item xs={12} md={9}>
            <Tabs
              value={tabIndexValue}
              onChange={handleTabChange}
              scrollButtons="auto"
              variant="scrollable"
              indicatorColor="primary"
            >
              {report.objects?.length &&
                report.objects
                  .sort((a, b) => (a.label > b.label ? 1 : -1))
                  .map((item) => {
                    return <Tab key={item.id} label={item.label + ". " + item.name} />;
                  })}
            </Tabs>
          </Grid>
          <Grid item container justifyContent="flex-end" spacing={2} alignItems="center">
            {props.openNewObjectDialog && (
              <Grid item>
                <TooltipForDisabled title="Godkendt rapport kan ikke ændres">
                  <Button
                    className={classes.button}
                    variant="outlined"
                    color="secondary"
                    onClick={props.openNewObjectDialog}
                    disabled={props.isReadOnly}
                  >
                    Tilføj bygning
                  </Button>
                </TooltipForDisabled>
              </Grid>
            )}
            {props.openEditDialog && (
              <Grid item>
                <TooltipForDisabled title="Godkendt rapport kan ikke ændres">
                  <Button
                    className={classes.button}
                    variant="outlined"
                    color="secondary"
                    onClick={props.openEditDialog}
                    disabled={props.isReadOnly}
                  >
                    Rediger
                  </Button>
                </TooltipForDisabled>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Typography paragraph>Bygning</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography paragraph>Karakter</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography paragraph>Registering og note</Typography>
        </Grid>
      </Grid>
      {!!report.objects.length && (
        <ReportObject
          showInfo={tabIndexValue === 0}
          {...report.objects[tabIndexValue]}
          refs={props.sectionRefs}
          isReadOnly={props.isReadOnly}
        />
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {report.objects?.length && tabIndexValue === 0 ? <ReportWishList isReadOnly={!!props.isReadOnly} /> : null}
    </Grid>
  );
};

export default ReportLayout;
