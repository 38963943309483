import { FeatureMetadata } from "../interfaces/models";

const DawaService = {
  getHouseData: async (data: FeatureMetadata) => {
    const addressInfo = await getDawaAddress(data.address + ", " + data.postcode + " " + data.city);
    if (addressInfo) {
      data.latitude = addressInfo.adgangsadresse.adgangspunkt.koordinater[1];
      data.longitude = addressInfo.adgangsadresse.adgangspunkt.koordinater[0];
      data.municipality = addressInfo.adgangsadresse.kommune.kode + "/" + addressInfo.adgangsadresse.esrejendomsnr;
      data.cadastral = addressInfo.adgangsadresse.matrikelnr + "/" + addressInfo.adgangsadresse.ejerlav.navn;
      data.picture = `${process.env.REACT_APP_IMAGE_SERVICE_URL}/${data.houseId}/single/640/315`;
    }
    return data;
  },

  getDawaCityByPostCode: async (postCode: number) => {
    const response = await fetch(process.env.REACT_APP_DAWA_API + "/postnumre?nr=" + encodeURIComponent(postCode));
    if (response.status === 200) {
      const payload = await response.json();
      if (payload.length) {
        return payload[0].navn;
      }
    }
    return null;
  },

  getDawaMunicipality: async (value: string) => {
    const response = await fetch(process.env.REACT_APP_DAWA_API + "/kommuner/autocomplete?q=" + encodeURIComponent(value));
    if (response.status === 200) {
      const payload = await response.json();
      if (payload.length) {
        return payload;
      }
    }
    return null;
  },

  getDawaMunicipalities: async (values: string | any[]) => {
    const results: any[]= [];
    for (let i = 0; i < values.length; i++) {
      const val = await DawaService.getDawaMunicipality(values[i]);
      if (val && val.length) {
        results.push(val[0]);
      }
    }
    return results;
  },
};

export default DawaService;

const getDawaAddress = async (addressString: string) => {
  const response = await fetch(process.env.REACT_APP_DAWA_API + "/adresser?q=" + encodeURIComponent(addressString));
  if (response.status === 200) {
    const payload = await response.json();
    if (payload.length) {
      return payload[0];
    }
  }
  return null;
};
