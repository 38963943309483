import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Collapse,
  useMediaQuery,
  useTheme,
  IconButton,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import ReportItem from "./ReportItem";
import makeStyles from "@mui/styles/makeStyles";
import { v4 as uuidv4 } from "uuid";
import { ReportSectionType } from "./ReportObject";
import { TemplateItemType, TimeToFinish } from "../../interfaces/models";
import { useReportActions, useReportState } from "../reportActions";
import ReportItemGroup from "./ReportItemGroup";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ChangeContext } from "../CreateReportPage";
import HiddenItemsMenu from "./HiddenItemsMenu";
import { ErrorStyledTooltip } from "../../shared/Tooltips";

const useStyles = makeStyles({
  openButton: {
    height: 32,
  },
  mobileOpen: {
    flexWrap: "nowrap",
  },
});

interface ReportSectionProps extends ReportSectionType {
  objectId: string;
  evaluation: number;
  isReadOnly?: boolean;
}

const ReportSection = React.forwardRef<HTMLDivElement, ReportSectionProps>((props: ReportSectionProps, ref) => {
  const report = useReportState();
  const actions = useReportActions();
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [addItemShown, setAddItemShown] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [open, setOpen] = useState(false);
  const changeCtx = useContext(ChangeContext);
  const [evaluation, setEvaluation] = useState(props.evaluation || 0);

  useEffect(() => {
    if (report.collapseAll) setOpen(false);
    return () => {
      //
    };
  }, [report.collapseAll]);

  const addItem = () => {
    changeCtx.current++;

    const itemId = uuidv4();
    const label = props.label + "." + (props.items && props.items.filter((x) => !x.groupId).length + 1);
    actions.addNewTemplateItem(props.objectId, {
      id: itemId,
      parentId: props.id,
      name: newItemName,
      label: label,
      options: [],
      type: 0,
      autoHideItems: false,
      groupId: "",
    });
    actions.addNewParameter({
      hidden: false,
      id: uuidv4(),
      itemId: itemId,
      label: label,
      objectId: props.objectId,
      name: newItemName,
      timeEstimate: TimeToFinish.None,
      priceEstimate: 0,
      value: "",
      description: "",
      craftsmen: [],
      note: "",
      pictures: [],
      descriptions: [
        {
          id: uuidv4(),
          description: "",
          pictures: [],
        },
      ],
      groupItemId: "",
    });
    setNewItemName("");
    setAddItemShown(false);
  };

  const cancelAdditem = () => {
    setNewItemName("");
    setAddItemShown(false);
  };

  const toggleHidden = () => {
    actions.toggleHiddenSection(props.objectId, props.id);
    setEvaluation(0);
  };

  const evaluateSection = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
    event.preventDefault();
    event.stopPropagation();
    changeCtx.current++;
    setEvaluation(event.target.value as number);
    actions.evaluateSection(props.objectId, props.id, event.target.value as number);
  };

  const toggleOpen = () => {
    if (!open) {
      actions.setCollapseAll(false);
    }

    setOpen(!open);
  };

  const validateSection = () => {
    if (evaluation === 0) return false;
    const params = report.parameters.filter(
      (x) =>
        x.objectId === props.objectId &&
        props.items?.some((i) => i.id === x.itemId && !x.hidden && i.type !== TemplateItemType.Group)
    );

    for (const param of params) {
      const opts = props.items.filter((x) => x.id === param.itemId)[0]?.options;
      const options = opts?.length ? opts : report.template.options;
      if (options.some((x) => x.value === param.value && !x.worth)) continue;

      if (
        !param.value ||
        !param.craftsmen?.length
        //|| !param.priceEstimate || !param.timeEstimate
      ) {
        return false;
      }
    }
    return true;
  };

  return (
    <Grid container ref={ref}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        //onClick={() => setOpen(!open)}
      >
        <Grid item container alignItems={"center"} xs={6} sm={4} className={classes.mobileOpen}>
          {!!isXs && (
            <IconButton disabled={props.hidden} color="default" size="small" onClick={toggleOpen}>
              {!props.hidden ? (
                <>{!open ? <ExpandMoreIcon /> : <ExpandLessIcon />}</>
              ) : (
                <ExpandMoreIcon color="disabled" />
              )}
            </IconButton>
          )}
          <Typography variant={!isXs ? "h6" : "subtitle1"} sx={{ ml: isXs ? "-5px" : "0px" }}>
            {props.label}.&nbsp;{props.name}
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-end" alignItems="center" xs={6} sm={8}>
          {!props.hidden && (
            <>
              {!isXs && (
                <Typography sx={{ mr: 1 }} variant={"subtitle1"}>
                  Tilstand
                </Typography>
              )}
              <ErrorStyledTooltip title={report.approved ? "Godkendt rapport kan ikke ændres" : ""}>
                <Select
                  variant="standard"
                  sx={{ mr: isXs ? 0 : 1, minWidth: isXs ? 135 : 158, maxHeight: 32 }}
                  value={evaluation}
                  onChange={(e, child) => evaluateSection(e, child)}
                  disabled={props.hidden || props.isReadOnly}
                  error={evaluation === 0}
                >
                  <MenuItem value={0} disabled>
                    <Typography color={"#FF0000"}>Ikke angivet</Typography>
                  </MenuItem>
                  <MenuItem value={1}>1 - Perfekt</MenuItem>
                  <MenuItem value={2}>2 - God</MenuItem>
                  <MenuItem value={3}>3 - Middel</MenuItem>
                  <MenuItem value={4}>4 - Dårlig</MenuItem>
                  <MenuItem value={5}>5 - Meget dårlig</MenuItem>
                  <MenuItem value={6}>6 - Ringe stand</MenuItem>
                </Select>
              </ErrorStyledTooltip>
              {!isXs && (
                <Button
                  sx={{ height: 32, width: 120 }}
                  onClick={toggleOpen}
                  color={validateSection() ? "secondary" : "error"}
                  size="small"
                  variant="outlined"
                >
                  {!open ? "Åben" : "Fold sammen"}
                </Button>
              )}
            </>
          )}
          <Grid item sx={{ ml: isXs ? "-5px" : "0px" }}>
            {!props.isReadOnly ? (
              <HiddenItemsMenu
                items={report.parameters
                  .filter(
                    (x) =>
                      !x.groupItemId &&
                      x.objectId === props.objectId &&
                      props.items?.some((i) => i.id === x.itemId && x.hidden)
                  )
                  .sort((a, b) => (parseInt(a.label.split(".")[1]) > parseInt(b.label.split(".")[1]) ? 1 : -1))}
              >
                <MenuItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={props.hidden || false}
                        name="hidden"
                        onChange={toggleHidden}
                        disabled={props.isReadOnly}
                      />
                    }
                    label="Ikke relevant"
                  />
                </MenuItem>
              </HiddenItemsMenu>
            ) : (
              <>{props.hidden && <Typography>Ikke relevant</Typography>}</>
            )}
          </Grid>
        </Grid>
      </Grid>
      {!props.hidden && (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          sx={{
            width: "100%",
          }}
        >
          {report.parameters
            .filter(
              (x) =>
                !x.groupItemId &&
                x.objectId === props.objectId &&
                props.items?.some((i) => i.id === x.itemId && !x.hidden)
            )
            .sort((a, b) => (parseInt(a.label.split(".")[1]) > parseInt(b.label.split(".")[1]) ? 1 : -1))
            .map((x) => {
              const item = props.items.find((i) => i.id === x.itemId);
              if (item?.type === TemplateItemType.Group) {
                return <ReportItemGroup key={x.id + x.objectId} parameter={x} {...item} isReadOnly={props.isReadOnly}/>;
              }

              return (
                <ReportItem key={x.id + x.objectId} {...x} showAddItem={true} variant="frame" sectionId={props.id} isReadOnly={props.isReadOnly} />
              );
            })}

          {!props.isReadOnly && (
            <>
              {addItemShown ? (
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Indtast punktets titel"
                      value={newItemName}
                      onChange={(event) => setNewItemName(event.currentTarget.value)}
                      margin="dense"
                      multiline
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button color="secondary" variant="outlined" onClick={addItem} disabled={!newItemName}>
                      Tilføj
                    </Button>
                    <Button sx={{ margin: 1 }} color="secondary" variant="outlined" onClick={cancelAdditem}>
                      Nulstil
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      sx={{ marginBottom: 2 }}
                      color="secondary"
                      variant="outlined"
                      onClick={() => setAddItemShown(true)}
                    >
                      Tilføj nyt punkt
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Collapse>
      )}
    </Grid>

    // </Tooltip>
  );
});

export default ReportSection;
