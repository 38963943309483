import React from "react";
import { View, Text } from "@react-pdf/renderer";
import styles from "../pdfDocumentStyles";

const PdfDocStaticText = () => {
  return (
    <>
      <View break style={styles.padding}>
        <Text style={{ ...styles.pageTextTitleBold, ...styles.fontFamily }}>Om Byggesagkyndig.nu</Text>

        <View>
          <Text style={styles.pageTextLastLine}>Byggesagkyndig.nu er en 100 % uvildig rådgivningsvirksomhed.</Text>

          <Text style={styles.pageTextLastLine}>
            Vi har ingen samarbejder med hverken forsikring, håndværkere eller andre, og er derfor upartisk og uden
            indflydelse fra andre.
          </Text>

          <Text style={{ ...styles.pageTextLastLine, ...styles.textItalic, ...styles.fontFamily }}>
            Jeres garanti for en uvildig byggetekniske bedømmelse.
          </Text>
        </View>
      </View>
      <View style={styles.padding}>
        <Text style={{ ...styles.pageTextTitleBold, ...styles.fontFamily }}>Om besigtigelsen</Text>

        <View>
          <Text style={styles.pageTextLastLine}>
            Der kan ved besigtigelsen udover den visuelle gennemgang være foretaget fugtmålinger og/eller termografiske
            målinger.
          </Text>

          <Text style={styles.pageTextLastLine}>
            Målinger foretages som overflade målinger og der laves ikke destruktive indgreb ved besigtigelsen.
          </Text>
        </View>
      </View>
      <View style={styles.padding}>
        <Text style={{ ...styles.pageTextTitleBold, ...styles.fontFamily }}>Fugtmålinger</Text>

        <View>
          <Text style={styles.pageText}>PROTIMETER 2</Text>
          <Text style={styles.pageTextLastLine}>Fugtmålinger er udført med Protimeter 2</Text>

          <Text style={styles.pageText}>Måling af/på beton og murværk:</Text>
          <Text style={styles.pageText}>0 - 170 = normalt fugtniveau </Text>
          <Text style={styles.pageText}>170 - 200 = forhøjet fugtniveau</Text>
          <Text style={styles.pageText}>200 - 400 = meget forhøjet fugtniveau</Text>
          <Text style={styles.pageText}>400 - 600 = væsentligt forhøjet fugtniveau</Text>
          <Text style={styles.pageTextLastLine}>600 - 999 = kraftigt forhøjet fugtniveau, (vådt)</Text>
        </View>
        <View>
          <Text style={styles.pageText}>TRAMEX</Text>
          <Text style={styles.pageTextLastLine}>Fugtmålinger er også foretaget med TRAMEX overfladescanner.</Text>

          <Text style={styles.pageText}>Målinger indstilles til rette bygningsmateriale og læses således:</Text>
          <Text style={styles.pageText}>0 - 20 = tørt</Text>
          <Text style={styles.pageText}>20 - 40 = normalt fugtniveau</Text>
          <Text style={styles.pageText}>40 - 50 = lettere opfugtet</Text>
          <Text style={styles.pageText}>50 - 60 = forhøjet fugtniveau</Text>
          <Text style={styles.pageText}>70 - 90 = væsentligt forhøjet fugtniveau</Text>
          <Text style={styles.pageTextLastLine}>{">"} 90 = vådt</Text>
        </View>
        <View>
          <Text style={styles.pageText}>Målinger af træværk, indvendig:</Text>
          <Text style={styles.pageTextLastLine}>
            Måling af træfugt måles indstiksmålere, bestående af to nåle, hvor man måler modstanden imellem de to nåle.
          </Text>

          <Text style={styles.pageText}>Målinger læses således:</Text>

          <Text style={styles.pageText}>8 – 12 % = normalt fugtniveau</Text>
          <Text style={styles.pageText}>12 – 16 % = let forhøjet fugtniveau</Text>
          <Text style={styles.pageText}>16 – 20 % = forhøjet fugtniveau</Text>
          <Text style={styles.pageText}>20 – 25 % = meget forhøjet fugtniveau</Text>
          <Text style={styles.pageTextLastLine}> {">"} 25 % = kraftigt forhøjet fugtniveau</Text>
        </View>
        <View>
          <Text style={styles.pageTextLastLine}>Målinger af træværk, udvendigt og i tagrum:</Text>

          <Text style={styles.pageText}>
            Måling af træfugt i udvendigt træ, herunder spær på lofter mv. er yderst relativt og afhænger meget af
            årstiden, og ikke mindst det aktuelle vejr.
          </Text>
          <Text style={styles.pageTextLastLine}>
            Træ der sidder udsat, eksempelvis vindskeder og lign. må forventes at have en generel høj træfugtighed.
          </Text>

          <Text style={styles.pageText}>Målinger læses således:</Text>
          <Text style={styles.pageText}>8 – 12 % = normalt fugtniveau</Text>
          <Text style={styles.pageText}>12 – 16 % = let forhøjet fugtniveau</Text>
          <Text style={styles.pageText}>16 – 20 % = forhøjet fugtniveau</Text>
          <Text style={styles.pageText}>20 – 25 % = meget forhøjet fugtniveau</Text>
          <Text style={styles.pageText}>25 - 30 % = kraftigt forhøjet fugtniveau, (vådt)</Text>
        </View>
        <View>
          <Text style={styles.pageTextLastLine}>Besigtigelse omfatter følgende typisk følgende bygningsdele:</Text>
          <Text style={{ ...styles.pageTextFirstLineBold, ...styles.fontFamily }}>KLIMASKÆRM</Text>
          <Text style={styles.pageText}>TAGFLADE / TAGRENDER/NEDLØB</Text>
          <Text style={styles.pageTextLastLine}>(vurderes i muligt omfang fra terræn og/eller stige)</Text>

          <Text style={styles.pageTextLastLine}>
            INDDÆKNINGER OG/ELLER KVISTE (vurderes i muligt omfang fra terræn og/eller stige)
          </Text>

          <Text style={styles.pageText}>MURFLADER</Text>
          <Text style={styles.pageTextLastLine}>(vurderes i muligt omfang fra terræn og/eller stige)</Text>

          <Text style={styles.pageText}>SOKKEL</Text>
          <Text style={styles.pageTextLastLine}>(vurderes kun i synlige områder over terræn)</Text>

          <Text style={styles.pageText}>VINDUER/DØRE </Text>
          <Text style={styles.pageTextLastLine}>(vurderes i muligt omfang fra terræn og/eller stige)</Text>

          <Text style={styles.pageText}>UNDERTAG/UNDERSTRYGNING</Text>
          <Text style={styles.pageText}>(vurderes i muligt omfang fra loftsrum)</Text>
        </View>
        <View break>
          <Text style={{ ...styles.pageTextFirstLineBold, ...styles.fontFamily }}>OVERFLADER</Text>
          <Text style={styles.pageText}>
            INDVENDIGE OVERFLADER KÆLDER / KRYBEKÆLDER (Såfremt ejendommen har kælder/krybekælder) (ventilerede hulrum)
          </Text>
          <Text style={styles.pageText}>Lofter/Vægge/Gulve</Text>
          <Text style={styles.pageText}>
            (der udføres en kort/generel besigtigelse af synlige overflader / evt. krybekælder besigtiges i muligt
          </Text>
          <Text style={styles.pageText}>omfang fra adgangslem)</Text>
        </View>

        <View>
          <Text style={{ ...styles.pageTextFirstLineBold, ...styles.fontFamily }}>ISOLERINGSTYKKELSER </Text>
          <Text style={styles.pageText}>Besigtiges kun i muligt omfang (typisk på loft eller ved krybekælder)</Text>
        </View>
        <Text style={{ ...styles.pageTextTitleBold, ...styles.fontFamily }}>Om estimater</Text>

        <View>
          <Text style={styles.pageText}>
            Udbedring af skader / vedligehold / renovering er prissat og vurderet ud fra bedste evne.
          </Text>
          <Text style={{ ...styles.pageText, ...styles.lineShift }}>
            Estimater er et erfaringsmæssigt skøn vurderet uden forudgående prisindhentning fra entreprenør mm. Priser
            opgives som forbrugerpriser inkl. moms, arbejdsløn samt materialer
          </Text>
          <Text style={{ ...styles.pageText, ...styles.lineShift }}>
            Bygesagkyndig.nu tager derfor forbehold for oplyst priser, da dette kan variere alt efter udbud,
            efterspørgsel samt hvor længe der er gået efter rapporten er udarbejdet.
          </Text>
        </View>
      </View>
    </>
  );
};

export default PdfDocStaticText;
