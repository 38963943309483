import React, { useEffect, useState } from "react";
import LoadingOverlay from "../../LoadingOverlay";
import ErrorBoundary from "../../ErrorBoundary";
import { BlobProvider } from "@react-pdf/renderer";
import { useReportState } from "../../../pages/reportActions";
import PdfDocumentLayout from "./PdfDocumentLayout";
import { useUser } from "../../../common/hooks";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { ReportState } from "../../../pages/reportReducer";
import { blobToBase64, transformReport } from "../../../common/utils";
import PlaceholderHouse from "../../../assets/img/placeholderHouse.jpg";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export type PdfReport = Omit<
  ReportState,
  | "approved"
  | "init"
  | "loading"
  | "uploadProgress"
  | "customerReports"
  | "needSave"
  | "count"
  | "sync"
  | "autoSave"
  | "collapseAll"
  | "loadingPicture"
  | "allowedToEdit"
  | "createdBy"  
> & { firstName: string; lastName: string };

const PdfGenerator = () => {
  const reportState = useReportState();
  const user = useUser();
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(0);

  const [reportData, setreportData] = useState<PdfReport | undefined>();

  const checkImage = async () => {
    try {
      if (reportState.data.picture) {
        const response = await fetch(reportState.data.picture, {
          headers: { "Access-Control-Allow-Origin": window.location.origin },
        });
        // if (response.status === 200) {
        const blob = await response.blob();
        return await blobToBase64(blob);
        // }
      }
    } catch (error) {
      console.log(error);
    }
    return PlaceholderHouse;
  };

  useEffect(() => {
    if (reportData) return;

    const transform = async () => {
      const data = await transformReport(reportState, user);

      try {
        // const image = await checkImage();
        // data.data.picture = image;
      } catch (error) {
        console.log(error);
      }
      setreportData(data);
    };

    transform();

    return () => {
      //
    };
  }, []);

  const loadSuccess = ({ numPages }: { numPages: number }) => {
    setPages(numPages);
    setLoading(false);
  };
  return (
    <ErrorBoundary errorMessage="PDF generator fejl">
      {reportData && (
        <BlobProvider document={<PdfDocumentLayout report={reportData} />}>
          {({ blob, url, loading, error }) => {
            return (
              <Document file={url} onLoadSuccess={loadSuccess}>
                {Array.from(new Array(pages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            );
          }}
        </BlobProvider>
      )}
      <LoadingOverlay loading={loading} />
    </ErrorBoundary>
  );
};

export default React.memo(PdfGenerator);
