import { forwardRef } from "react";
import { NumericFormat, NumericFormatProps, PatternFormat, PatternFormatProps } from "react-number-format";
import { TextField, TextFieldProps } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

export const PhoneField = (props: TextFieldProps) => {
  const handleChange = (e: any) => {
    props?.onChange?.(e);
  };
  return (
    <MuiTelInput
      {...props}
      value={props.value as string}
      onChange={handleChange}
      variant={props.variant}
      inputRef={props.inputRef}
    />
  );
};

export const FormattedTextField = forwardRef<
  HTMLElement,
  PatternFormatProps & JSX.IntrinsicAttributes & TextFieldProps
>(function Formatted(props, ref) {
  return <PatternFormat {...props} format={props.format} customInput={TextField} {...ref} />;
});

export const NumericTextField = forwardRef<HTMLElement, NumericFormatProps & JSX.IntrinsicAttributes & TextFieldProps>(
  function Formatted(props, ref) {
    return <NumericFormat {...props} allowNegative={false} customInput={TextField} {...ref} />;
  }
);
