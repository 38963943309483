import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  IconButton,
  Button,
  useMediaQuery,
  useTheme,
  LinearProgress,
  Link,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import ElevationScroll from "./ElevationScroll";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import GeneralDialog from "./GeneralDialog";
import MenuIcon from "@mui/icons-material/Menu";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { ArrowBack } from "@mui/icons-material";
import { useUser } from "../common/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import MenuBlocks from "../pages/components/MenuBlocks";
import { useUserActions } from "../user/userActions";
import { CompanyRole } from "../interfaces/models";

const useStyles = makeStyles((theme) => ({
  userName: {
    whiteSpace: "nowrap",
  },
  header: {
    padding: "0 15px 5px",
    fontSize: 12,
    "@media (min-width: 600px)": {
      flexDirection: "row",
      fontSize: 14,
      padding: 10,
    },
  },
  mobileColumn: {
    flex: 1,
    alignItems: "baseline",
    "@media (min-width: 600px)": {
      flexDirection: "row",
      alignItems: "flex-start",
    },
  },
  menuBar: {
    flexWrap: "nowrap",
    flex: 5,
    "@media (min-width: 600px)": {
      flexDirection: "row",
    },
  },

  customTitleContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  toolBar: {
    flexDirection: "row",
  },
  menuButton: {
    color: "inherit",
    padding: "12px 20px",
  },
  logo: {
    padding: "7px 15px 2px",
    color: "inherit",
    fontSize: 10,
    letterSpacing: 5,
    cursor: "pointer",
    fontFamily: "'Montserrat', sans-serif",
    "@media (min-width: 600px)": {
      letterSpacing: 8,
      padding: "14px 0 12px 40px",
      fontSize: 12,
    },
  },
}));

type AppToolBarProps = {
  handleDrawerToggle?: (() => void) | false;
  goBack?: (() => void) | false;
  children?: React.ReactNode;
  title?: string;
  buttons?: React.ReactNode;
  light?: boolean;
  logo?: boolean;
  loading?: boolean;
};

const AppToolBar = (props: AppToolBarProps) => {
  const classes = useStyles();
  const { light, logo, loading } = props;
  const [openLogout, setOpenLogoutDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const [title, setTitle] = useState("");
  const user = useUser();
  const userActions = useUserActions();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const roleTitle = (user?.role && MenuBlocks[user.role]?.find((x) => x.path === location.pathname)?.title) || "";
    setTitle(props.title || roleTitle);
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.role, location.pathname, props.title]);

  const goToProfile = () => {
    setAnchorEl(null);
    navigate("/" + location.pathname.split("/")[1] + "/profile");
  };

  const openLogoutDialog = () => {
    setAnchorEl(null);
    setOpenLogoutDialog(true);
  };

  const logout = (accepted?: boolean) => {
    setOpenLogoutDialog(false);
    if (accepted) {
      userActions.logout();
    }
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const goBack = () => {
    if (window.history.length <= 2) {
      navigate("/overview");
    } else {
      navigate(-1);
    }
  };

  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div style={{ marginTop: 64 }}></div>

      <ElevationScroll>
        <StyledAppBar
          style={{ left: 0, width: "100%" }}
          color={light ? "default" : "secondary"}
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <StyledToolbar disableGutters>
            <Grid
              className={classes.toolBar}
              direction="column"
              justifyContent="space-between"
              container
              alignItems="flex-start"
            >
              <Grid direction="row" justifyContent="flex-start" item container alignItems="center" xs={4} sm={6}>
                {!!props.handleDrawerToggle && (
                  <IconButton color="inherit" aria-label="open drawer" onClick={props.handleDrawerToggle} size="large">
                    <MenuIcon />
                  </IconButton>
                )}
                {!logo && (
                  <IconButton onClick={props.goBack ? props.goBack : goBack} size="large">
                    <ArrowBack style={{ color: "white" }} />
                  </IconButton>
                )}
                {logo && (
                  <Link
                    className={classes.logo}
                    variant="body2"
                    href={process.env.REACT_APP_MYHOUSE_URL}
                    underline="none"
                    target="_blank"
                  >
                    myhouse
                  </Link>
                )}
                <div className={classes.customTitleContent}>{props.children}</div>
                {!props.children && (
                  <>
                    {(logo || (!isXs && !logo)) && (
                      <Typography className={classes.header} variant="body2">
                        {title ? title : "Service eftersyn"}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
              <Grid
                container
                item
                xs={8}
                sm={6}
                alignItems="center"
                justifyContent="flex-end"
                className={classes.menuBar}
              >
                {props.buttons && <>{props.buttons}</>}
                {user.authorized && (
                  <Button onClick={openMenu} className={classes.menuButton}>
                    {isXs ? (
                      <PersonOutlineIcon />
                    ) : (
                      <Typography variant="body2" color="inherit" className={classes.userName}>
                        {user ? user.firstName + " " + user.lastName : "Menu"}
                      </Typography>
                    )}

                    <ArrowDropDownIcon color="inherit" />
                  </Button>
                )}
              </Grid>
            </Grid>
          </StyledToolbar>
          {loading && <LinearProgress color="secondary" />}
        </StyledAppBar>
      </ElevationScroll>
      <GeneralDialog
        open={openLogout}
        dialogTitle={"Ønsker du at logge af?"}
        dialogText={"Husk at gemme eventuelle ændringer inden."}
        handleClose={logout}
      />
      {user.authorized && (
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 9999 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleMenuClose}>
                  <MenuList autoFocusItem={Boolean(anchorEl)}>
                    {!location.pathname.includes("/profile") && <MenuItem onClick={goToProfile}>Profil</MenuItem>}

                    {user.companyRole === CompanyRole.Manager && (
                      <MenuItem
                        onClick={() => {
                          navigate("/inspector/admin");
                        }}
                      >
                        Admin panel
                      </MenuItem>
                    )}
                    <Divider />
                    <MenuItem onClick={openLogoutDialog}>Log af</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};

export default AppToolBar;

const StyledAppBar = withStyles({
  colorDefault: {
    background: "#fafafa",
  },
})(AppBar);

const StyledToolbar = withStyles({
  regular: {
    minHeight: 0,
  },
})(Toolbar);
