import React from "react";
import { Typography, Paper, Badge, Grid } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import MenuBlocks, { MenuBlockType } from "./MenuBlocks";
import { Badges, useBadges } from "../../common/hooks";
import { useNavigate } from "react-router-dom";

const MenuBlock = (props: MenuBlockType) => {
  const navigate = useNavigate();

  const badgeValue = useBadges(props.badgeName || Badges.Default);

  const navigateTo = (path: string) => {
    if (path.startsWith("http")) {
      window.open(path);
      return;
    }
    navigate(path);
  };

  return (
    <Grid
      item
      container
      onClick={() => navigateTo(props.path)}
      sx={{
        width: "100%",
        cursor: "pointer",
        minHeight: 200,
        "@media (min-width: 600px)": {
          width: 250,
        },
      }}
    >
      <StyledBadge badgeContent={badgeValue || 0} color="primary">
        <Paper
          elevation={3}
          sx={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            height: "100%",
            padding: "20px",
            boxSizing: "border-box",
            width: "100%",
            backgroundColor: props.bgColor,
          }}
        >
          <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {props.title}
              </Typography>
            </Grid>
            <Grid item>{props.icon}</Grid>
            <Grid item>
              <Typography variant="body2">{props.text}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </StyledBadge>
    </Grid>
  );
};

const MenuBlockList = (props: { blockKey: string | number }) => {
  const blocks = MenuBlocks[props.blockKey];
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          width: "auto",
          display: "flex",
          flexWrap: "wrap",
          "@media (min-width: 600px)": {
            width: 520,
          },
        }}
      >
        {blocks.map((value) => (
          <MenuBlock key={value.path} {...value} />
        ))}
      </Grid>
    </>
  );
};

export default MenuBlockList;

const StyledBadge = withStyles({
  root: {
    width: "100%",
  },
})(Badge);
