import React from "react";
import { View, Image } from "@react-pdf/renderer";
import styles from "../pdfDocumentStyles";

const PdfDocHousePicture = (props: { picture: string }) => {
  return (
    <View style={{ ...styles.row }}>{props.picture && <Image style={styles.imageWide} src={props.picture} />}</View>
  );
};

export default PdfDocHousePicture;
