import { AdminResetPasswordCommand, Company, CompanyListItem, CraftsmanType, ManageTeamCommand, Rating, RatingValue, SearchResult, Team, UpdateUserCommand, User, UserRole } from "../interfaces/models";
import ApiService from "./ApiService";

const UserService = {
  login: async (userName: string, password: string) => {
    return await ApiService.post<User>("/api/account/login", { userName, password });
  },
  getUserList: async (userRole: UserRole, filter = "", skip = 0, take = 10,  sortField = "UserName", sortDescending = false) => {
    return await ApiService.get<SearchResult<User>>(
      `/api/admin/users?UserRole=${userRole}&filter=${filter}&skip=${skip}&take=${take}&orderby=${sortField}&descending=${sortDescending}`
    );
  },
  uploadUser: async (user: User) => {
    return await ApiService.post("/api/admin/users", user);
  },
  deleteUser: async (userId: string) => {
    return await ApiService.delete(`/api/admin/users/${userId}`);
  },
  updateUser: async (request: UpdateUserCommand) => {
    return await ApiService.put<User>("/api/admin/users", request);
  },
  lockUser: async (userId: string) => {
    return await ApiService.post(`/api/admin/users/lock/${userId}`);
  },
  resetPassword: async (user: AdminResetPasswordCommand) => {
    return await ApiService.post("/api/admin/users/resetpwd", user);
  },
  getProfile: async () => {
    return await ApiService.get<User>("/api/account/profile");
  },
  checkProfile: async () => {
    return await ApiService.post("/api/account/checkprofile");
  },
  updateProfile: async (user: UpdateUserCommand)  => {
    return await ApiService.post<User>("/api/account/profile", user);
  },
  changePassword: async (currentPassword: string, newPassword: string) => {
    return await ApiService.post("/api/account/changepwd", { currentPassword, newPassword });
  },
  setLocalUser: (user: User) => {
    localStorage.setItem("ServiceBook#user", JSON.stringify(user));
  },
  getRating: async () => {
    return await ApiService.get<Rating>("/api/rating");
  },
  getRatingValues: async (skip = 0, take = 0): Promise<SearchResult<RatingValue>> => {
    return await ApiService.get("/api/rating/values?skip=" + skip + "&take=" + take);
  },
  getTeam: async () => {
    return await ApiService.get<Team>("/api/team/");
  },
  manageTeam: async (payload: ManageTeamCommand) => {
    return await ApiService.post("/api/team/manage", payload);
  },
  getCompanies: async (
    query: string,
    type: CraftsmanType,
    sortField: string,
    sortDescending: boolean,
    excludeTeam?: boolean,
    skip = 0,
    take = 10,
    
  ) => {
    if(!type) {
      type = CraftsmanType.None;
    }
    return await ApiService.get<SearchResult<CompanyListItem>>(
      `/api/company?query=${query}&type=${type}&skip=${skip}&take=${take}&orderBy=${sortField}&desc=${sortDescending}&excludeTeam=${excludeTeam}`
    );
  },
  getOwnCompany: async () => {
    return await ApiService.get<Company>("/api/company/own");
  }
};

export default UserService;
