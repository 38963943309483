import { Box, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { ReactNode } from "react";
import { useReport } from "../pages/reportActions";

type PlacementType = TooltipProps["placement"];

type TooltipForDisabledProps = {
  title: string;
  children: ReactNode;
  placement?: PlacementType;
  showTooltip?: boolean;
};

const TooltipForDisabled: React.FC<TooltipForDisabledProps> = ({ title, children, placement }) => {
  const [report] = useReport();

  return report.approved ? (
    <ErrorStyledTooltip title={title || ""} placement={placement}>
      <Box component="span" sx={{ display: "inline-block", pointerEvents: "none" }}>
        <Box sx={{ pointerEvents: "auto" }}>{children}</Box>
      </Box>
    </ErrorStyledTooltip>
  ) : (
    <>{children}</>
  );
};

export const ErrorStyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));

export default TooltipForDisabled;
