import { useContext, useState } from "react";
import { useReport } from "../reportActions";
import { Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ChangeContext } from "../CreateReportPage";
import ReportWishListItem from "./ReportWishListItem";
import TooltipForDisabled from "../../shared/Tooltips";

const ReportWishList = (props:{isReadOnly:boolean}) => {
  const changeCtx = useContext(ChangeContext);
  const [report, actions] = useReport();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const [addItemShown, setAddItemShown] = useState(false);

  const addOrUpdateItem = (param: { id: string; label: string; priceEstimate: number }) => {
    if (!param.id && addItemShown) {
      setAddItemShown(false);
    }
    //add to the list
    changeCtx.current++;
    actions.addToWishList(param);
  };

  const removeItem = (id: string) => {
    if (!id && addItemShown) {
      setAddItemShown(false);
      return;
    }
    changeCtx.current++;
    actions.removeFromWishList(id);
  };

  return (
    <Grid container mt={2}>
      <Grid item xs={12}>
        <Typography variant={!isXs ? "h6" : "subtitle1"}>Ønskede forbedringer anslået</Typography>
        {report.wishList?.length ? (
          report.wishList.map((x) => (
            <Grid item key={x.id} container xs={12} spacing={2}>
              <ReportWishListItem
                addOrUpdate={addOrUpdateItem}
                remove={removeItem}
                item={x}
                disabled={props.isReadOnly}
              />
            </Grid>
          ))
        ) : (
          <Typography variant="subtitle1" my={2}>
            Ønskede forbedringsliste er tom
          </Typography>
        )}
      </Grid>
      {addItemShown ? (
        <Grid item container xs={12} spacing={2}>
          <ReportWishListItem
            addOrUpdate={addOrUpdateItem}
            remove={removeItem}
            disabled={false}
            item={{
              id: "",
              label: "",
              priceEstimate: 0,
            }}
            newItem
          />
        </Grid>
      ) : (
        <Grid item container xs={12} spacing={2} mt={1}>
          <Grid item xs={12}>
            <TooltipForDisabled title="Godkendt rapport kan ikke ændres">
              <Button
                sx={{ marginBottom: 2 }}
                color="secondary"
                variant="outlined"
                onClick={() => setAddItemShown(true)}
                disabled={props.isReadOnly}
              >
                Tilføj
              </Button>
            </TooltipForDisabled>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ReportWishList;
